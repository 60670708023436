<template>
  <div>
    <div
      class="modal"
      id="vehicleDetailModal"
      tabindex="-1"
      role="dialog"
    >
      <div
        v-if="isClickVehicleDetail"
        class="modal-dialog modal-xl modal-dialog-centered vhc-dtl-modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="vehicleDetailModal">
              Vehicle Detail
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click.prevent="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body vhc-dtl-modal-body" v-if="loader">
            <loader />
          </div>
          <div class="modal-body vhc-dtl-modal-body" v-else-if="detailList.length > 0">
            <detail-component v-for="(detail,index) in detailList" :key="index" v-bind:detailList="detail"></detail-component>
          </div>
          <div class="modal-body vhc-dtl-modal-body" v-else>
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { commonMixins } from "../../../../mixins/commonMixins";
  import EventBus from "../../../event-bus";
  import { HTTP } from "../../../../http-common";
  import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
  import DetailComponent from "./VehicleDetail/DetailComponent.vue";
  import Loader from "./Loader.vue";
  
  export default {
      name: "VehicleWorkSheet",
      components: {
          "no-access": NoAccessPage,
          "detail-component": DetailComponent,
          'loader': Loader,
      },
      mixins: [commonMixins],
      props: ["isClickVehicleDetail","vinNo"],
      data() {
          return {
              detailList: [],
              message: "Data Not Available For The Selected VIN",
              loader: false,
          };
      },
      methods: {
          closeModal() {
              EventBus.$emit("close-vehicle-detail", false);
          },
          getVehicleDetails() {
            this.loader = true;
            let config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
            };

            HTTP.get("/vin-details/"+this.vinNo, config)
              .then(response => {
                if (response.data.code === 200) {
                  this.loader = false;
                  this.detailList = response.data.content;
                }
              })
              .catch((err) => {
                this.loader = false;
                console.log("ERROR!!!", err);
              });
          }
      },
      watch: {
        isClickVehicleDetail(status) {
          if(status) {
            this.getVehicleDetails();
          }
        }
      }
  };
</script>

<style scoped>
  .vhc-dtl-modal-dialog {
  overflow-y: initial !important;
  }
  .vhc-dtl-modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .vhc-dtl-custom-row {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 14px;
  }
</style>
