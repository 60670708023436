<template>
  <div>
    <div
      class="modal"
      v-bind:style="{ zIndex: parentModalShowHide }"
      id="vehiclePurchaseModal"
      tabindex="-1"
      role="dialog"
    >
      <div
        v-if="isClickPurchaseOrder"
        class="modal-dialog modal-xl modal-po-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content vpo-modal-content">
          <div class="modal-header vpo-modal-row">
            <div id="vpo-modal-header-title">
              Vehicle Purchase Order
            </div>
            <div>
              <button id="vpo-close-btn" @click="closePurchaseOrderModal()">
                Close
              </button>
              <button id="vpo-save-btn" @click="savePurchaseOrder()" :disabled="this.showPOLoader">
                {{ saveUpdate }}
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="modal-row">
              <div class="modal-column">
                <div class="fm-group">
                  <label for="excedePO">Excede PO#</label>
                  <input type="text" class="po-input" v-model="exPurId" disabled />
                </div>
                <div class="fm-group">
                  <label for="branch">Branch <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <v-select
                    v-model="location"
                    name="locationId"
                    label="text"
                    :filterable="true"
                    :options="locationArr"
                    :clearable="false"
                    @input="branchError='',shipListByBranch(location.stateCode)"
                    class="custom-ci-auto-search vhc-select vhc-po-select-items"
                  />
                  <span class="po-required-alert" v-if="branchError !== ''">{{ branchError }}</span>
                </div>
                <div class="fm-group">
                  <label for="vendorId">Vendor ID <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <v-select
                    v-model="vendor"
                    name="vendorId"
                    label="vendor_id"
                    :filterable="true"
                    :options="vendorArr"
                    @input="vendorError='',populateVendorRelated()"
                    :clearable="false"
                    class="custom-ci-auto-search vhc-select vhc-po-select-items"
                  />
                  <span class="po-required-alert" v-if="vendorError !== ''">{{ vendorError }}</span>
                </div>
                <div class="fm-group">
                  <label for="vendorName">Vendor Name</label>
                  <input type="text" class="po-input" v-model="vendorName" />
                </div>
              </div>
            </div>

            <div class="modal-row">
              <div class="modal-column">
                <div class="fm-group">
                  <label for="excedePO">Ship Code <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <v-select
                    v-model="ship"
                    name="shipId"
                    label="des"
                    :filterable="true"
                    :options="currShipArr"
                    :clearable="false"
                    @input="shipError=''"
                    class="custom-ci-auto-search vhc-select vhc-po-select-items"
                  />
                  <span class="po-required-alert" v-if="shipError !== ''">{{ shipError }}</span>
                </div>
                <div class="fm-group">
                  <label for="excedePO">Terms <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <v-select
                    v-model="term"
                    name="termId"
                    label="des"
                    :filterable="true"
                    :options="termArr"
                    :clearable="false"
                    @input="termError=''"
                    class="custom-ci-auto-search vhc-select vhc-po-select-items"
                  />
                  <span class="po-required-alert" v-if="termError !== ''">{{ termError }}</span>
                </div>
                <div class="fm-group">
                  <label for="excedePO">Type <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <v-select
                    v-model="vechicleType"
                    name="type"
                    label="des_pur_typ"
                    :filterable="true"
                    :options="typeArr"
                    :clearable="false"
                    @input="vechicleTypeError=''"
                    class="custom-ci-auto-search vhc-select vhc-po-select-items"
                  />
                  <span class="po-required-alert" v-if="vechicleTypeError !== ''">{{ vechicleTypeError }}</span>
                </div>
                <div class="fm-group">
                  <label for="excedePO">Description</label>
                  <input type="text" class="po-input" v-model="description" />
                </div>
              </div>
            </div>

            <div class="modal-row">
              <div class="modal-column">
                <div class="fm-group">
                  <label for="excedePO">Stock# <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <input type="text" class="po-input" v-model="unit" />
                  <span class="po-required-alert" v-if="stockError !== ''">{{ stockError }}</span>
                </div>
                <div class="fm-group">
                  <label for="excedePO">VIN <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <input type="text" class="po-input" v-model="vin" />
                  <span class="po-required-alert" v-if="vinError !== ''">{{ vinError }}</span>
                </div>
                <div>
                  <input
                    type="checkbox"
                    v-model="isExcludePo"
                    class="po-checkbox"
                  /><span>Exclude PO from Taxable Amount</span>
                </div>
              </div>
            </div>

            <div class="modal-row">
              <div class="modal-column">
                <div class="fm-group">
                  <label for="excedePO">Order Date <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <date-picker
                    v-model="orderDate"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Select Order Date"
                    name="orderDate"
                    @input="orderDateError = ''"
                  />
                  <span class="po-required-alert" v-if="orderDateError !== ''">{{ orderDateError }}</span>
                </div>
                <div class="fm-group">
                  <label for="excedePO">Expected Date <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <date-picker
                    v-model="expectedDate"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Select Expected Date"
                    name="expectedDate"
                    @input="expectedDateError = ''"
                  />
                  <span class="po-required-alert" v-if="expectedDateError !== ''">{{ expectedDateError }}</span>
                </div>
                <div class="fm-group">
                  <label for="excedePO">Disc Date <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <date-picker
                    v-model="discDate"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Select Disc Date"
                    name="discDate"
                    @input="discDateError = ''"
                  />
                  <span class="po-required-alert" v-if="discDateError !== ''">{{ discDateError }}</span>
                </div>
                <div class="fm-group">
                  <label for="excedePO">Due Date <span class="po-required" title="REQUIRED FIELD">*</span></label>
                  <date-picker
                    v-model="dueDate"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    placeholder="Select Due Date"
                    name="dueDate"
                    @input="dueDateError = ''"
                  />
                  <span class="po-required-alert" v-if="dueDateError !== ''">{{ dueDateError }}</span>
                </div>
              </div>
            </div>

            <div class="modal-row">
              <div id="add-item">
                <button id="add-item-btn" @click="addItemModal()">
                  Add Item
                </button>
              </div>
            </div>

            <div class="modal-row">
              <table class="custom-table">
                <thead>
                  <td>DESCRIPTION</td>
                  <td>COST</td>
                  <td>PRICE</td>
                  <td>ADD TO PRICE</td>
                  <td>EXCL FROM FET</td>
                  <td>SHOW ON DEC</td>
                  <td>DO NOT ADD TO GP</td>
                  <td></td>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in itemList" :key="item.index">
                    <td>{{ item.description }}</td>
                    <td>{{ item.cost }}</td>
                    <td>{{ item.price }}</td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="item.is_add_to_total_price"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="item.is_fet_exempt"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="item.is_show_on_documents"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="item.is_do_not_add_to_gp"
                        disabled
                      />
                    </td>
                    <td>
                      <a
                        v-on:click="editItems(item, index)"
                        title="Edit Items"
                        href="javascript:void(0)"
                        class="mar-right-0"
                      >
                        <i aria-hidden="true" class="fa fa-edit"></i>
                      </a>
                      <a
                        v-on:click="deleteItems(item, index)"
                        title="Edit Items"
                        href="javascript:void(0)"
                        class="mar-right-0"
                      >
                        <i aria-hidden="true" class="fa fa-trash text-danger"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <common-loader refId="commonLoaderPO" ref="commonLoaderPO" />
    </div>
    <div v-show="isAddItem">
        <vehicle-purchase-item v-bind:isAddItem="isAddItem" v-bind:editPOItem="editPOItem" v-bind:parentIds="parentIds"></vehicle-purchase-item>
    </div>
  </div>
</template>

<script>
import EventBus from "../../../../event-bus";
import { HTTP } from "../../../../../http-common";
import { commonMixins } from "../../../../../mixins/commonMixins";
import datePicker from "vue-bootstrap-datetimepicker";
import AddItemModal from "./AddItemModal.vue";
import moment from "moment";
import CommonLoader from "@/components/partials/CommonLoader.vue";

var thisPO;
export default {
  name: "VehiclePurchaseOrder",
  props: [
    "isClickPurchaseOrder",
    "unitId",
    "vin",
    "defaultLocation",
    "assetId",
    "editAssetId", //deal_inventory_id
    "poEditData",
  ],
  mixins: [commonMixins],
  components: {
    datePicker,
    'vehicle-purchase-item': AddItemModal,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      location: "",
      locationId: Number,
      locationArr: [],
      ship: "",
      shipId: Number,
      shipArr: [],
      currShipArr: [],
      term: "",
      termId: Number,
      termArr: [],
      vendor: "",
      vendorId: Number,
      vendorArr: [],
      fieldName: "initial",
      vendorName: "",
      vechicleType: "",
      typeArr: [],
      description: "Vehicle Purchase",
      orderDate: "",
      expectedDate: "",
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom",
        },
      },
      isAddItem: false,
      parentModalShowHide: 1042,
      branchError: "",
      vendorError: "",
      shipError: "",
      termError: "",
      vechicleTypeError: "",
      stockError: "",
      vinError: "",
      orderDateError: "",
      expectedDateError: "",
      isExcludePo: false,
      itemList: [],
      populateVendor: true,
      editPOItem: [],
      isEditPO: false,
      unit: Number,
      isTempEdit: false,
      saveUpdate: "Save",
      parentIds: null,
      isList: false,
      tempDeleteIndex: null,
      isUpdate: false,
      exPurId: "",
      discDate: "",
      discDateError: "",
      dueDate: "",
      dueDateError: "",
      showPOLoader: false,
      currentIndex: 0,
      isApiCall: false,
    };
  },
  beforeMount() {
    thisPO = this;
  },
  methods: {
    closePurchaseOrderModal() {
      if(this.isUpdate && this.isTempEdit) {
        let data = this.poEditData;
        data.po_items = this.itemList;
        this.tempSaveUpdate(data);
      } else {
        EventBus.$emit("close-purchase-order", false, this.isList);
        this.clearData();
      }
    },
    loadDataCallback: function (callbackData) {
      if(!this.isApiCall) {
        $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
      }
      if (callbackData && callbackData.length <= 0) {
        return false;
      }
      switch (this.fieldName) {
        case "Location":
          this.locationArr = callbackData.Location;
          break;
        case "Ship":
          this.shipArr = callbackData.Ship;
          break;
        case "Term":
          this.termArr = callbackData.Term;
          break;
        case "Vendor":
          this.vendorArr = callbackData.Vendor;
          break;
        case "VehiclePurchaseOrderType":
          this.typeArr = callbackData.VehiclePurchaseOrderType;
          break;
        default:
          this.locationArr = callbackData.Location;
          this.shipArr = callbackData.Ship;
          this.termArr = callbackData.Term;
          this.vendorArr = callbackData.Vendor;
          this.typeArr = callbackData.VehiclePurchaseOrderType;
          this.$nextTick(() => {
            this.getStateCode();
          });
          break;
      }
    },
    populateVendorRelated() {
      this.termError = "";
      this.vechicleTypeError = "";
      if (this.populateVendor && this.vendor != "") {
        this.vendorId = this.vendor.vendor_id;
        this.vendorName = this.vendor.text;
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
          params: {
            vendor_id: this.vendorId,
          },
        };

        HTTP.get("vendor/term", config)
          .then((response) => {
            const data = response.data.content;
            this.term = {
              trm_id: data.trm_id,
              des: data.des,
            };
            this.vechicleType = {
              pur_typ_id: data.pur_typ_id,
              des_pur_typ: data.des_pur_typ,
            };
            return true;
          })
          .catch(function (err) {
            console.log("error=>", err);
          });
      }
    },
    clearData() {
      this.location = this.defaultLocation;
      this.locationId = Number;
      this.ship = "";
      this.shipId = Number;
      this.term = "";
      this.termId = Number;
      this.vendor = "";
      this.vendorId = Number;
      this.fieldName = "initial";
      this.vendorName = "";
      this.vechicleType = "";
      this.description = "Vehicle Purchase";
      this.orderDate = "";
      this.expectedDate = "";
      this.itemList= [];
      this.parentModalShowHide = 1042;
      this.populateVendor = true;
      this.editPOItem = [];
      this.isEditPO = false;
      this.unit = Number;
      this.isTempEdit = false;
      this.saveUpdate = "Save";
      this.tempDeleteIndex = null;
      this.isUpdate = false;
      this.isList = false;
      this.parentIds = null;
      this.branchError = "";
      this.vendorError = "";
      this.shipError = "";
      this.termError = "";
      this.vechicleTypeError = "";
      this.exPurId = "";
      this.discDate = "";
      this.dueDate = "";
      this.discDateError = "";
      this.dueDateError = "";
      this.stockError = "";
      this.orderDateError = "";
      this.expectedDateError = "";
      this.isApiCall = false;
      return true;
    },
    addItemModal: function () {
      this.parentModalShowHide = 1040;
      this.isAddItem = true;

      if(Object.keys(this.poEditData).length > 0 && Object.keys(this.poEditData).includes('purchase_order_id')) {
        this.parentIds = {
          purchase_order_id: this.poEditData.purchase_order_id,
          deal_inventory_id: this.editAssetId
        }
      }
      $("#vehiclePurchaseItemModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    savePurchaseOrder() {
      if(this.itemList.length == 0) {
        this.showSAlert(
          "Please Add Item First!",
          "info",
          false,
          2
        );
        return false;
      }
      if (this.checkPurchaseOrderForm()) {
        this.showPOLoader = true;
        $("#commonLoaderPO").find(".loading-container.lodingTable").show();
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        var data = {
          branch_id: this.location.value,
          vendor_id: this.vendor.vendor_id,
          vendor_name: this.vendorName,
          shp_code: this.ship.shp_id,
          terms: this.term.trm_id,
          vehicle_type: this.vechicleType.pur_typ_id,
          unit: this.unit,
          vin_no: this.vin,
          summary: this.description,
          order_date: this.orderDate,
          expected_date: this.expectedDate,
          is_exclude_po: this.isExcludePo,
          asset_id: this.assetId,
          deal_inventory_id: this.editAssetId,
          po_items: !this.isEditPO ? this.itemList : "",
          po_items_count: this.itemList.length,
          date_disc: this.discDate,
          date_due: this.dueDate,
          current_index: this.currentIndex,
        };
        if (this.editAssetId != null) {
          if (!this.isEditPO) {
            HTTP.post("store/purchase/order", data, config).then((response) => {
              EventBus.$emit(
                "purchase-item-list",
                response.data.content,
                "save"
              );
              this.showPOLoader = false;
              $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
            });
          } else {
            HTTP.put(
              "purchase-order/" + thisPO.poEditData.purchase_order_id,
              data,
              config
            ).then((response) => {
              EventBus.$emit(
                "purchase-item-list",
                response.data.content,
                "update"
              );
              this.showPOLoader = false;
              $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
            });
          }
        } else {
          this.tempSaveUpdate(data);
          this.showPOLoader = false;
          $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
        }
      }
    },
    checkPurchaseOrderForm() {
      var checked = true;
      if (this.location == "") {
        this.branchError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.branchError = "";
      }

      if (this.vendor == "") {
        this.vendorError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.vendorError = "";
      }

      if (this.ship == "") {
        this.shipError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.shipError = "";
      }

      if (this.term == "") {
        this.termError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.termError = "";
      }

      if (this.vechicleType == "") {
        this.vechicleTypeError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.vechicleTypeError = "";
      }

      if (this.unit == "") {
        this.stockError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.stockError = "";
      }

      if (this.vin == "") {
        this.vinError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.vinError = "";
      }

      if (this.orderDate == "") {
        this.orderDateError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.orderDateError = "";
      }

      if (this.expectedDate == "") {
        this.expectedDateError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.expectedDateError = "";
      }

      if (this.orderDate > this.expectedDate) {
        this.showSAlert(
          "Order Date should be less than the Expected Date",
          "info",
          false,
          2
        );
        checked = false;
      }

      if (this.discDate == "") {
        this.discDateError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.discDateError = "";
      }

      if (this.dueDate == "") {
        this.dueDateError = "This field is required!";
        if (checked) {
          checked = false;
        }
      } else {
        this.dueDateError = "";
      }

      return checked;
    },
    addItemObject(items) {
      this.itemList.push(items);
      if(items.parent_ids !== null) {
        this.isList = true;
      }
    },
    getEditData() {
      const id = this.poEditData.purchase_order_id;
      this.showPOLoader = true;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      HTTP.get("purchase-order/" + id + "/edit", config)
        .then((response) => {
          this.populateResponseData(response.data.content);
          setTimeout(() => {
            this.populateVendor = true;
          }, 500);
          this.showPOLoader = false;
          $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
          return true;
        })
        .catch((err) => {
          this.showPOLoader = false;
          $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
          this.catchHandler(err, function () {});
        });
    },
    populateResponseData(data) {
      this.description = data.summary;
      this.vendorName = data.vendor_name;
      this.orderDate = moment(data.order_date).format("MM/DD/YYYY");
      this.expectedDate = moment(data.expected_date).format("MM/DD/YYYY");
      this.discDate = moment(data.date_disc).format("MM/DD/YYYY");
      this.dueDate = moment(data.date_due).format("MM/DD/YYYY");
      this.isExcludePo = data.is_exclude_po;
      this.exPurId = data.ex_pur_id;
      const ship = this.shipArr.filter(
        (shp) => shp.shp_id == data.shp_code
      );
      this.ship = ship[0];
      const vendor = this.vendorArr.filter(
        (vendor) => vendor.vendor_id == data.vendor_id  //Better take vendor_id instead id(value) to be consider!!
      );
      if(vendor.length > 0) {
        this.vendor = vendor[0];
      } else {
        this.vendorArr.push(data.selected_vendor);
        this.vendor = data.selected_vendor;
      }
      this.vendorName = data.vendor_name;
      const term = this.termArr.filter(
        (term) => term.trm_id == data.terms
      );
      this.term = term[0];
      const vehicleType = this.typeArr.filter(
        (vhc) => vhc.pur_typ_id == data.vehicle_type
      );
      this.vechicleType = vehicleType[0];
      this.itemList = data.po_items;
      this.location = this.locationArr.find(
        (location) => location.value == data.branch_id
      );
      return true;
      /*Auto populated as per selected vin and unit
            this.vin = data.vin;
            this.unitId = data.vunit_id;
        */
    },

    /*##### MERGE THIS WITH FUNCTION populateResponseData later #####*/
    populateTempData(data) {
      this.vendor = data.vendor_id;
      this.description = data.summary;
      this.vendorName = data.vendor_name;
      this.orderDate = data.order_date;
      this.expectedDate = data.expected_date;
      this.discDate = data.date_disc;
      this.dueDate = data.date_due;
      this.isExcludePo = data.is_exclude_po;
      const ship = this.shipArr.filter(
        (shp) => shp.shp_id == data.shp_code
      );
      this.ship = ship[0];
      const vendor = this.vendorArr.filter(
        (vendor) => vendor.vendor_id == data.vendor_id  //Better take vendor_id instead id(value) to be consider!!
      );
      this.vendor = vendor[0];
      this.vendorName = data.vendor_name;
      const term = this.termArr.filter(
        (term) => term.trm_id == data.terms
      );
      this.term = term[0];
      const vehicleType = this.typeArr.filter(
        (vhc) => vhc.pur_typ_id == data.vehicle_type
      );
      this.vechicleType = vehicleType[0];
      this.itemList = data.po_items;
      this.location = this.locationArr.find(
        (location) => location.value == data.branch_id
      );
      this.currentIndex = data.current_index;
      return true;
    },
    editItems(data, index) {
      this.parentModalShowHide = 1040;
      this.isAddItem = true;
      this.editPOItem = data;
      localStorage.setItem("po_item_index", index);
      $("#vehiclePurchaseItemModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    updateItemObject(item) {
      const itemIndex = localStorage.getItem("po_item_index");
      this.itemList[itemIndex] = item;
      localStorage.removeItem("po_item_index");
      if(Object.keys(item).includes('id') && item.id !== null) {
        this.isList = true;
      }
    },
    loadPurchaseOrderData() {
      $("#commonLoaderPO").find(".loading-container.lodingTable").show();
      this.location = this.defaultLocation;
      this.loadAllData(
        ["Location", "Ship", "Term", "Vendor", "VehiclePurchaseOrderType"],
        false,
        this.loadDataCallback,
        "undefined",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "DealBooking"
      );
    },
    getStateCode() {
      let locId = this.defaultLocation.value;
      const currLocation = this.locationArr.find((location) => {
        return location.value == locId;
      });
      this.shipListByBranch(currLocation.stateCode,'mount');
    },
    shipListByBranch(stateCode,flag=null) {
      this.currShipArr = this.shipArr.filter((ship) => {
       return ship.shp_id.split('.')[0] == stateCode;
      });
      if(flag=="mount") {
        return true;
      }
      this.ship = "";
    },
    deleteItems(data,index) {
      this.$swal({
        title: '',
        text: 'Are you sure that you want to delete the Purchase Order Item!',
        type: 'info',
        confirmButtonText: 'OK',
        showCancelButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
          if (result.value) {
            if(Object.keys(data).includes('id') && data.id !== null) {
              $("#commonLoaderPO").find(".loading-container.lodingTable").show();
              const id = data.id;
              let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
                data : {
                  purchase_order_id: data.purchase_order_id,
                  // ex_itm_id: data.ex_itm_id
                }
              };
              HTTP.delete("vehicle-worksheet/addon-list/item/"+id, config).then((response) => {
                if(response.data.status === "success") {
                  this.itemList.splice(index, 1);
                  this.isList = true;
                  this.showSAlert(
                    response.data.message,
                    response.data.status,
                    false,
                    2
                  );
                  $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
                }
              })
              .catch((err) => {
                this.catchHandler(err, function () {});
                $("#commonLoaderPO").find(".loading-container.lodingTable").hide();
              });
            } else {
              this.itemList.splice(index, 1);
              this.tempDeleteIndex = this.poEditData.current_item_index[index];
              if(this.poEditData.current_item_index.length > 0) {
                this.poEditData.current_item_index.splice(index,1);
              }
              this.isUpdate = true;
            }
          }
      });
    },
    tempSaveUpdate(data) {
      let type = "tempSave";
      let indexList = null;
      if(this.isTempEdit) {
        type = "tempUpdate";
      }
      EventBus.$emit("purchase-item-list", data, type, indexList, this.tempDeleteIndex);
    }
  },
  watch: {
    isClickPurchaseOrder(status) {
      if (status) {
        this.clearData();
        this.unit = this.unitId;
        this.loadPurchaseOrderData();
        if (Object.keys(this.poEditData).length > 0) {
          this.saveUpdate = "Update"
          if(this.editAssetId !== null) {
            this.isApiCall = true;
            this.populateVendor = false;
            this.isEditPO = true;
            setTimeout(() => {
              this.getEditData();
            }, 1000);
          } else {
            this.isTempEdit = true;
            this.populateTempData(this.poEditData);
          }
        }
      }
    },
  },
};

EventBus.$on("close-purchase-item", function (status) {
  thisPO.parentModalShowHide = 1042;
  thisPO.isAddItem = false;
  thisPO.editPOItem = [],
  $("#vehiclePurchaseItemModal").modal("hide");
});

EventBus.$on("save-purchase-item", function (data, isUpdate, isList) {
  thisPO.parentModalShowHide = 1042;
  thisPO.isAddItem = false;
  $("#vehiclePurchaseItemModal").modal("hide");
  const count = thisPO.itemList.length;
  data['po_temp_index']= count;
  if (!isUpdate) {
    thisPO.addItemObject(data);
  } else {
    thisPO.editPOItem = [];
    thisPO.updateItemObject(data);
  }
  thisPO.isUpdate = isList;
});
</script>

<style scoped>
.vpo-modal-content {
  padding: 0 10px;
}

.vpo-modal-row {
  align-items: center;
}

.vpo-modal-row > div:first-child {
  display: flex;
  align-items: center;
}

#vpo-modal-header-title {
  color: #506b71;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.vpo-modal-row > div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

#vpo-close-btn {
  width: 80px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #dbe0e7;
}

#vpo-save-btn {
  width: 80px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  border: unset;
  background: #076eb0;

  /* text */
  color: #fff;
  text-align: center;
  font-family: SF UI Display, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
}

.modal-row {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 12px;
}

.modal-column {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr;
  grid-column-gap: 60px;
}

.fm-group {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.po-input {
  height: 32px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #cecece;
  width: 227px;
}

.po-checkbox {
  width: 16.457px;
  height: 18px;
  flex-shrink: 0;
}

#add-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#add-item-btn {
  width: 116px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  border: unset;
  background: #076eb0;

  color: #fff;
  text-align: center;
  font-family: SF UI Display, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.modal-row:nth-child(3) > .modal-column > div:nth-child(3) {
  grid-column: span 2;
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table > thead {
  height: 28px;
  border: 1px solid #cecece;
  background: #d6f0f1;

  /* text */
  color: #9c9c9c;
  text-align: center;
  font-family: SF UI Display, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 1px;
}

.custom-table > tbody {
  border: 1px solid #cecece;

  /* text */
  color: #161616;
  text-align: center;
  font-family: SF UI Display, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 1px;
}

.custom-table > tbody > tr {
  line-height: 30px;
}

.custom-table > tbody > tr > td > input {
  vertical-align: middle;
}

.po-required {
  color: #ff0000;
  font-weight: bolder;
}

.po-required-alert {
  color: #ff0000;
  font-weight: lighter;
}

/* .vhc-po-select-items .dropdown-toggle {
  width: 313px !important;
} */

/* .vhc-select .dropdown-toggle {
  width: 226px !important;
} */
</style>
