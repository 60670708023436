<template>
    <div>
        <template v-if="permissionNameArray.includes('can_list_opportunity_master')">
            <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
                <span class="color-cc9636">{{selectedRows.length}} rows selected <a class="color-098de1" v-on:click.prevent="clearSelection()">X</a></span>
                <button v-show="permissionNameArray.includes('can_delete_opportunity_master')" type="button" v-on:click.prevent="deleteOpportunity()" class="btn btn-primary waves-effect pull-right ml-2">Delete {{selectedRows.length}} Record(s)</button>
                
            </div>
            <div class="table-listing white-bg border-blue border-radius-top-right">
                <table class="table custom-table jsmartable">
                    <tbody>
                        <template v-if="opportunityArr && opportunityArr.length > 0">
                            <tr v-for="(opportunity, index) in opportunityArr" :key="index">
                                <td class="">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" v-model="selectedRows" :value="opportunity.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + opportunity.id" name="case[]">
                                        <label class="custom-control-label" :for="'customCheck' + opportunity.id"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="actions">
                                        <a v-show="permissionNameArray.includes('can_add_opportunity_master')" class="mar-right-0" title='Edit Opportunity'  v-on:click="editOpportunity(opportunity.id)" href="javascript:void(0)">
                                            <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                        </a>
                                        <a v-show="permissionNameArray.includes('can_delete_opportunity_master')"  class="mar-right-0" title='Delete Opportunity' v-on:click="deleteOpportunity(opportunity.id)"  href="javascript:void(0)">
                                            <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </td>
                                <!-- <td :class="rowClass.stock">{{opportunity.stock}}</td>
                                <td :class="rowClass.vin_no">{{opportunity.vinNo}}</td> -->
                                <!-- <td :class="rowClass.company">{{opportunity.company}}</td> -->
                                <!-- <td :class="rowClass.sale_type">{{opportunity.status_type_name}}</td>
                                <td :class="rowClass.funding_source">{{opportunity.funding_source_name}}</td>
                                <td :class="rowClass.funding_method">{{opportunity.funding_method_name}}</td>
                                <td :class="rowClass.down_payment">{{opportunity.down_payment}}</td>
                                <td :class="rowClass.deposite_date">{{opportunity.deposite_date}}</td>-->
                                <td :class="rowClass.reference_no">{{opportunity.reference_no}}</td>
                                <td :class="rowClass.status">{{opportunity.status}}</td>
                                <td :class="rowClass.type">{{opportunity.type}}</td>
                                <td :class="rowClass.salesman">{{opportunity.salesman}}</td>
                                <td :class="rowClass.decision_date">{{opportunity.decision_date}}</td>
                                <td :class="rowClass.created_by">{{opportunity.created_by}}</td>
                                <td :class="rowClass.created_at">{{opportunity.createdAt}}</td>
                                <td :class="rowClass.updated_by">{{opportunity.updated_by}}</td>
                                <td :class="rowClass.updated_at">{{opportunity.updatedAt}}</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td :colspan="headers.length + 1" class="text-alignment-center">
                                    {{allStaticMessages.data_not_available}}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <thead>
                        <tr>
                            <th class="">
                                <div class="custom-control custom-checkbox">
                                    <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                    <label class="custom-control-label" for="selectAllCheckBox"></label>
                                </div>
                            </th>
                            <template v-for="(data, headerIndex) in headers">
                                <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                            </template>
                            <template v-for="(data, headerIndex) in headers">
                                <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex"  v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                            </template>
                        </tr>
                    </thead>
                </table>
            </div>
            <!-- Pagination Start -->
            <div class="vgt-wrap__footer crm">
                <div class="row">
                    <div class="col-sm-12 col-md-2 PerPage">
                        <v-select title="Select Per Page"
                                  v-model="perPage"
                                  @input="changePerPage"
                                  name="customPerPage"
                                  id="customPerPage"
                                  label="text"
                                  :clearable="false"
                                  :searchable="false"
                                  :filterable="false"
                                  :options="paginationDropDownArr"
                                  placeholder="Select Per Page">
                        </v-select>
                    </div>
                    <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                        <p class="pageRowCount">
                            {{startEndRecord}} of {{totalRecords}}
                        </p>
                    </div>

                    <!-- v-if="totalRecords >= perPage" -->
                    <div class="col-sm-12 col-md-6 Pagination">
                        <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous" next-text="Next ›" :total-rows="totalRecords" v-model="currentPage" @input="changepage()" :per-page="perPageValue"></b-pagination>
                    </div>

                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                        <p class="pageJumpLabel">Page : </p>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                        <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage" id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    var filterColumn = "id";
    var filterType = "desc";
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import FilterComponent from "../../FilterComponent/FilterComponent";
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        name: 'ListAssetExpenseTab',
        mixins: [commonMixins],
        components: {
            'filter-component': FilterComponent,
            'no-access': NoAccessPage,
        },
        data() {
            return {
                assetId: 0,
                perPageValue: 25,
                perPage: 25,
                currentPage: 1,
                totalRecords: 0,
                paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
                opportunityArr: [],
                headers: [],
                filters: {
                },
                appliedHeaders: [],
                jumpToPage: 1,
                columnName: filterColumn,
                sortType: filterType,
                selectedRows: [],
                startEndRecord: "",
                selectAll: false,
            }
        },
        props: {
            permissionNameArray:"",
        },
        beforeMount() {
            self = this;
        },
        watch: {
            /*selectAll: function (selectAll) {
             self.clickHeaderCheckBox();
             },*/
            /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadOpportunity(self.assetId);
            },
            currentPage: function () {
                self.loadOpportunity(self.assetId);
            }*/
        },
        created: function () {
            self = this;
            //self.getLoginIdPermissions();
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            let headersNames = [];
            headersNames['filters'] = self.filters;
            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.assetId = self.$route.params.id;
                self.listExecutionState = false;
                self.loadOpportunity(self.assetId);
            }
            if (!self.permissionNameArray.includes('can_delete_opportunity_master') && !self.permissionNameArray.includes('can_add_opportunity_master')) {
                setTimeout(function () {
                    self.headers.pop();
                    self.actionDisplay = false;
                }, 50);
            }
        },
        methods: {
            resetSorting: function () {
                self.columnName = filterColumn;
                self.sortType = filterType;
                self.changepage();
            },
            changePerPage : function(){
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadOpportunity(self.assetId);
            },
            changepage : function(){
                if(!self.listExecutionState){
                    self.loadOpportunity(self.assetId);
                }
            },
            clearSelection: function () {
                self.selectAll = false;
                self.selectedRows = [];
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            keyMonitor: function () {
                var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
                self.currentPage = self.jumpToPage = parseInt(tempNumber);
            },
            clickHeaderCheckBox: function () {
                setTimeout(function () {
                    self.selectedRows = [];
                    if (self.selectAll) {
                        for (let i in self.opportunityArr) {
                            self.selectedRows.push(self.opportunityArr[i].id);
                        }
                    }
                }, 100);
            },
            inputCheckbox: function (e) {
                setTimeout(function () {
                    self.selectAll = false;
                    if (self.selectedRows.length == self.opportunityArr.length) {
                        self.selectAll = true;
                    }
                }, 100);
            },
            editOpportunity: function (editId) {
                this.$parent.chagneComponent(editId);
            },
            loadOpportunity: function (assetId) {
                self.listExecutionState = true;
                if(self.perPage == 'All' && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA){
                    self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
                    return false;
                }
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    filters: {"asset_id":assetId},
                    per_page: self.perPage,
                    page: self.currentPage,
                    sortColumnName: self.columnName,
                    sortType: self.sortType,
                    asset_id: assetId
                };
                HTTP.post("/opportunity/list", fields, config)
                        .then(function (data) {
                            self.listExecutionState = false;
                            if (data.status == 200) {
                                self.clearSelection();
                                self.headers = [];
                                self.totalRecords = data.data.content.result;
                                self.opportunityArr = data.data.content.data;
                                self.headers = data.data.content.headers;
                                
                                self.addColumnClass(self.headers);
                                
                                let testArray = [
                                    {
                                        'text': 1,
                                        'value': '2020-02-01'
                                    },
                                    {
                                        'text': 2,
                                        'value': '2020-02-02'
                                    },
                                    {
                                        'text': 3,
                                        'value': '2020-02-03'
                                    }
                                ]
                                for (let i = 0; i < self.headers.length; i++) {
                                    if (self.headers[i]['type'] == 'drop_down') {
                                        self.headers[i]['options_array'] = testArray;
                                    }
                                }
                                if (data.data.content.per_page !== self.totalRecords) {
                                    self.perPage = parseInt(data.data.content.per_page);
                                    self.jumpToPage = parseInt(data.data.content.current_page);
                                } else {
                                    if(self.perPage == 'All'){
                                        self.jumpToPage = 1;
                                    }
                                    self.perPageValue = self.totalRecords;
                                    self.currentPage = 1;
                                }
                                // self.setAlreadyAppliedFilters();
                                self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                                self.sortFieldDisplay(self.columnName, self.sortType);
                            }
                        })
                        .catch(function (error) {
                            self.listExecutionState = false;
                            self.catchHandler(error, function () {});
                        })
            },
            deleteOpportunity : function (id) {
                let deleteArray = [];
                id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
                if(id != 0) {
                    deleteArray.push(id);
                } else {
                    deleteArray = self.selectedRows;
                }
                self.$swal({
                    title: '',
                    text: 'Are you sure that you want to delete this Opportunity!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    // showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        var config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                            data : {
                                deleteArr: deleteArray,
                                asset_id: self.assetId
                            }
                        };

                        HTTP.delete("/opportunity/delete", config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.loadOpportunity(self.assetId);
                                }
                            })
                            .catch(function (error) {
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
        }
    }
</script>