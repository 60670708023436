<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_batch_master')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Batch</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
          </div>

          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="headers"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>

          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListBatch"
              ref="commonLoaderListBatch"
            ></common-loader>
            <!--Pagination Start-->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!--Pagination End-->

            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="batchDataArr && batchDataArr.length > 0">
                    <tr v-for="(batch, index) in batchDataArr" :key="index">
                      <td :class="rowClass.batch">{{ batch.batch }}</td>
                      <td :class="rowClass.module">{{ batch.module }}</td>
                      <td :class="rowClass.status">{{ batch.batch_status }}</td>
                      <td :class="rowClass.created_at">
                        {{ batch.created_at }}
                      </td>
                      <td :class="rowClass.updated_at">
                        {{ batch.updated_at }}
                      </td>
                      <td>
                        <a
                          v-if="
                            batch.batch_status === 'Completed' &&
                            batch.file_path
                          "
                          :href="getDownloadUrl(batch.file_path)"
                          title="Download"
                          class="download-icon"
                        >
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>

            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  ></v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
let self;
let filterColumn = "id";
let filterType = "desc";
import Vue from "vue";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import FilterComponent from "../FilterComponent/FilterComponent";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import Footer from "@/components/partials/Footer";
import { HTTP } from "@/http-common";

export default {
  name: "ListBatch",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "common-loader": CommonLoader,
    "no-access": NoAccessPage,
    "app-footer": Footer,
  },
  data() {
    return {
      permissionNameArray: ["can_list_batch_master"],
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      startEndRecord: "",
      jumpToPage: 1,
      headers: [],
      filters: {},
      listExecutionState: false,
      batchDataArr: [],
      selectAll: false,
      columnName: filterColumn,
      sortType: filterType,
      appliedHeaders: [],
      filterCount: 0,
    };
  },
  beforeMount() {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    self = this;
    /* Redirect to login page logic start */
    const userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    self.setActiveMenu("batch-list");
    EventBus.$emit("menu-selection-changed", "batch-list");

    const listHistoryData = self.loadFilters("listBatch", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadBatch("1");
  },
  methods: {
    getDownloadUrl(filePath) {
      if (!filePath) return "#";
      return `${filePath}`;
    },
    resetSorting() {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadBatch();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadBatch();
      }
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadBatch();
    },
    keyMonitor: function () {
      let tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    applyFilterParentComponent(data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadBatch();
    },
    setAlreadyAppliedFilters() {
      if (self.appliedHeaders.length > 0) {
        self.headers = self.appliedHeaders;
      }
    },

    loadBatch(first) {
      self.listExecutionState = true;
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
          "Content-Type": "multipart/form-data",
        },
      };

      const fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
      };
      this.storeFilters("listBatch", fields, false);
      $("#commonLoaderListBatch").find(".loading-container.lodingTable").show();
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/batch/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          self.headers = [];
          self.totalRecords = data.data.content.result;
          $("#commonLoaderListBatch")
            .find(".loading-container.lodingTable")
            .hide();
          if (
            self.perPage == "All" &&
            self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
          ) {
            let seconds = 5000;
            self.$swal({
              type: "error",
              html: self.allStaticMessages
                .too_many_data_to_display_browser_can_not_load,
              showConfirmButton: false,
              timer: seconds,
            });
            setTimeout(() => {
              self.perPage = 500;
              self.loadBatch();
            }, seconds);
          }
          self.batchDataArr = data.data.content.data;
          self.headers = data.data.content.headers;
          self.loadFilterInputs(data.data.content.headers, self.filters);
          self.addColumnClass(self.headers);
          self.setAlreadyAppliedFilters();
          self.sortFieldDisplay(self.columnName, self.sortType);
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#commonLoaderListBatch")
            .find(".loading-container.lodingTable")
            .hide();
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadBatch();
      }
    },
  },
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>