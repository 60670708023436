<template>
    <div class="add-opportunity-popup-form admin-content-area">
        <div class="modal fade bd-example-modal-lg" id="addUpdateOpportunityFormPopup" tabindex="-1" role="dialog"
                aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <add-update-opportunity ref="addUpdateOpportunityRef" @call-modal-close="closeModalPopup" openAt="popupView"></add-update-opportunity>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
var self;

import AddUpdateOpportunity from "../OpportunityMaster/AddUpdateOpportunity.vue";


export default {
    name: "AddUpdateOpportunityPopup",
    components: {
        AddUpdateOpportunity
    },
    data() {
        return {
            production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
        }
    },
    beforeMount() {
        self = this;
    },
    mounted: function () {

    },
    methods: {
        openAddOpportunityPopup(contactData) {
            $("#addUpdateOpportunityFormPopup").modal("show");
            this.$refs.addUpdateOpportunityRef.openAddOpportunityPopup(contactData);
        },
        closeModalPopup() {
            $("#addUpdateOpportunityFormPopup").modal("hide");
            this.$emit('call-close-add-opportunity-popup');
        }
    }

}
</script>
