<template>
    <div
        class="modal"
        id="tradeDetailModal"
        tabindex="-1"
        role="dialog"
      >
        <div
          v-if="isClickAddTrade"
          class="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div class="modal-content trd-dtl-modal-content">
            <div class="modal-header trd-dtl-modal-row">
              <div id="trd-dtl-modal-header-title">
                Trade Detail
              </div>
              <div>
                <button v-if="isEditTradeDetail" id="delete-btn" @click="deleteTradeDetailModal()">
                  Delete
                </button>
                <button id="trd-dtl-close-btn" @click="closeTradeDetailModal()">
                  Close
                </button>
                <button class="trade-btn" @click="saveTradeDetail()">
                  {{ saveUpdate }}
                </button>
              </div>
            </div>
            <div class="modal-body">
              <div>
                <div class="modal-row">
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="year">Year</label>
                      <input type="text" class="po-input" v-model="year" />
                    </div>
                  </div>
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="odometer">Odometer</label>
                      <input type="text" class="po-input" v-model="odometer" />
                    </div>
                  </div>
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="trade-in-value">Trade-in Value</label>
                      <money v-bind="moneyMask" type="text" class="po-input" v-model="tradeInValue" @keyup.native="(event) => {incrementDecrementNumber(event, 'tradeInValue');}" />
                    </div>
                  </div>
                </div>

                <div class="modal-row">
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="make">Make <span class="trade-required" title="REQUIRED FIELD">*</span></label>
                      <v-select
                          v-model="make"
                          name="make"
                          label="text"
                          :filterable="false"
                          :options="makeArr"
                          @input="searchModel"
                          :clearable="false"
                          class="custom-ci-auto-search vhc-select"
                        />
                        <span class="trade-required-alert" v-if="makeError !== ''">{{ makeError }}</span>
                    </div>
                  </div>
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="trade-acv">Trade ACV</label>
                      <money v-bind="moneyMask" type="text" class="po-input" v-model="tradeAcv" @keyup.native="(event) => {incrementDecrementNumber(event, 'tradeAcv');}" @blur.native="addTradeValue()" />
                    </div>
                  </div>
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="payoff">Payoff</label>
                      <money v-bind="moneyMask" type="text" class="po-input" v-model="payoff" @keyup.native="(event) => {incrementDecrementNumber(event, 'payoff');}" />
                    </div>
                  </div>
                </div>

                <div class="modal-row">
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="model">Model <span class="trade-required" title="REQUIRED FIELD">*</span></label>
                      <v-select
                          v-model="model"
                          name="model"
                          label="text"
                          :filterable="false"
                          :options="modelArr"
                          :clearable="false"
                          class="custom-ci-auto-search vhc-select"
                          @input="modelError = ''"
                        />
                      <span class="trade-required-alert" v-if="modelError !== ''">{{ modelError }}</span>
                    </div>
                  </div>
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="overallowance">Over Allowance</label>
                      <money v-bind="moneyMask" type="text" class="po-input" v-model="overAllowance" @keyup.native="(event) => {incrementDecrementNumber(event, 'overAllowance');}" @blur.native="addTradeValue()" />
                    </div>
                  </div>
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="trade-gst-credit">Trade GST Credit</label>
                      <money v-bind="moneyMask" type="text" class="po-input" v-model="tradeGstCredit" @keyup.native="(event) => {incrementDecrementNumber(event, 'tradeGstCredit');}" disabled />
                    </div>
                  </div>
                </div>

                <div class="modal-row">
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="vin">Vin</label>
                      <input type="text" class="po-input" v-model="vinNo" />
                    </div>
                  </div>
                  <div id="vin-decode">
                    <button type="button" class="trade-btn"  id="vin-decode-btn" @click="searchVin()">VIN Decode</button>
                  </div>
                  <div class="modal-column">
                    <div class="fm-group">
                      <label for="quantity">Quantity</label>
                      <input type="text" class="po-input" v-model="quantity" @keyup="(event) => {incrementDecrementNumber(event, 'tradeGstCredit');}" />
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <input type="checkbox" class="trade-checkbox" v-model="safetyInspection" />
                    <span for="safety-inspection">Safety inspection</span>
                  </div>
                  <div>
                    <input type="checkbox" class="trade-checkbox" v-model="tiresMinimumTread" />
                    <span for="tires-minimum">Tires-Minimum tread 50%</span>
                  </div>
                  <div>
                    <input type="checkbox" class="trade-checkbox" v-model="noBrokenGlass" />
                    <span for="no-broken-glass">No Broken Glass</span>
                  </div>
                  <div>
                    <input type="checkbox" class="trade-checkbox" v-model="noBodyDamage" />
                    <span for="no-body-damge">No Body Damage</span>
                  </div>
                </div>

                <div>
                  <div class="fm-group">
                    <label for="trade-note">Trade Note</label>
                    <textarea id="trade-note" v-model="tradeNote"></textarea>
                  </div>
                </div>
              </div>
              <div>
                <div class="trade-fm-group">
                  <label for="type">Type</label>
                  <v-select
                    v-model="tradeType"
                    name="type"
                    label="text"
                    :filterable="false"
                    :options="tradeTypeArr"
                    :clearable="false"
                    class="custom-ci-auto-search vhc-select"
                  />
                </div>
                <div class="trade-fm-group">
                  <label for="type">Stock #</label>
                  <v-select
                    v-model="stockId"
                    name="stockId"
                    label="unit"
                    :filterable="false"
                    :options="stockArr"
                    @search="e => onSearchStockId(e)"
                    @input="selectVin"
                    :clearable="false"
                    class="custom-ci-auto-search vhc-select"
                  />
                </div>
                <div class="trade-fm-group">
                  <label for="type">Eng No.</label>
                  <input type="text" class="po-input" v-model="engNo" />
                </div>
                <div class="trade-fm-group">
                  <label for="type">License No.</label>
                  <input type="text" class="po-input" v-model="licenseNo" />
                </div>
                <div class="trade-fm-group">
                  <label for="type">License Name</label>
                  <input type="text" class="po-input" v-model="licenseName" />
                </div>
                <div class="trade-fm-group">
                  <label for="type">Lien Address</label>
                  <input type="text" class="po-input" v-model="lienAddress" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
  
  <script>
  import EventBus from "../../../event-bus";
  import { HTTP } from "../../../../http-common";
  import { commonMixins } from "../../../../mixins/commonMixins";
  import { Money } from "v-money";
  
  let thisTrade;
  export default {
    name: "TradeDetail",
    props: [
      "isClickAddTrade",
      "currentVehicleWorksheetId",
      "isEditTradeDetail",
      "tradeDetailsData"
    ],
    mixins: [commonMixins],
    components: {
      Money,
    },
    data() {
      return {
        saveUpdate: "Save",
        makeArr: [],
        make: '',
        modelArr: [],
        model: '',
        vinNo: '',
        stockId: '',
        stockArr: [],
        year: '',
        odometer: '',
        tradeType: '',
        tradeTypeArr: [],
        tradeAcv: 0,
        overAllowance: 0,
        tradeInValue: 0,
        payoff: 0,
        tradeGstCredit: 0,
        engNo: '',
        licenseNo: '',
        licenseName: '',
        lienAddress: '',
        safetyInspection: false,
        tiresMinimumTread: false,
        noBrokenGlass: false,
        noBodyDamage: false,
        tradeNote: '',
        makeError: '',
        modelError: '',
        quantity: 1,
        moneyMask: {
          thousands: ",",
          prefix: "$ ",
          precision: 2,
        },
      };
    },
    beforeMount() {
      thisTrade = this;
    },
    methods: {
      closeTradeDetailModal() {
        this.clearData();
        EventBus.$emit("close-trade-detail", false);
      },
      
      clearData() {
        this.saveUpdate = "Save";
        this.makeArr = [];
        this.make = '';
        this.modelArr = [];
        this.model = '';
        this.vinNo = '';
        this.stockId = '';
        this.stockArr = [];
        this.year = '';
        this.odometer = '';
        this.tradeType = '';
        this.tradeTypeArr = [];
        this.tradeAcv = 0;
        this.overAllowance = 0;
        this.tradeInValue = 0;
        this.payoff = 0;
        this.tradeGstCredit = 0;
        this.engNo = '';
        this.licenseNo = '';
        this.licenseName = '';
        this.lienAddress = '';
        this.safetyInspection = false;
        this.tiresMinimumTread = false;
        this.noBrokenGlass = false;
        this.noBodyDamage = false;
        this.tradeNote = '';
        this.makeError = '';
        this.modelError = '';
        return true;
      },

      saveTradeDetail() {
        if (this.isValidForm()) {
          let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
          };
          
          let data = {
            deal_inventory_mapper_id: this.currentVehicleWorksheetId,
            make_id: Object.keys(this.make).includes('value') ? this.make.value : null,
            model_id: Object.keys(this.model).includes('value') ? this.model.value : null,
            vin: this.vinNo,
            stock_id: Object.keys(this.stockId).includes('unit') ? this.stockId.unit : null,
            type: this.tradeType,
            year: this.year,
            odometer: this.odometer,
            trade_acv: this.tradeAcv,
            over_allowance: this.overAllowance,
            trade_in_value: this.tradeInValue,
            payoff: this.payoff,
            trade_gst_credit: this.tradeGstCredit,
            eng_no: this.engNo,
            license_no: this.licenseNo,
            license_name: this.licenseName,
            lien_address: this.lienAddress,
            safety_inspection: this.safetyInspection,
            tires_minimum_tread: this.tiresMinimumTread,
            no_broken_glass: this.noBrokenGlass,
            no_body_damage: this.noBodyDamage,
            trade_note: this.tradeNote,
            quantity: this.quantity,
          };

          if(this.currentVehicleWorksheetId !== null) {
            if(!this.isEditTradeDetail) {
              HTTP.post("trade/details", data, config).then((response) => {
                let content = response.data.content;
                EventBus.$emit(
                  "trade-details-data",
                  content,
                  "Save",
                );
              })
              .catch(function (err) {
                console.log("error=>", err);
                thisTrade.catchHandler(err, function () {});
              });
            } else {
              const id = this.tradeDetailsData.id;
              HTTP.put("trade/details/"+id, data, config).then((response) => {
                let content = response.data.content;
                EventBus.$emit(
                  "trade-details-data",
                  content,
                  "Save",
                );
              })
              .catch(function (err) {
                console.log("error=>", err);
                thisTrade.catchHandler(err, function () {});
              });
            }
          } else {
            data['make'] = this.make;
            data['model'] = this.model;
            EventBus.$emit(
              "trade-details-data",
              data,
              "Fresh",
            );
          }
          return true;
        }
      },
      isValidForm() {
        let valid = true;

        if (this.make == "" || this.make == null || this.make == undefined) {
            this.makeError = "This field is required!";
            if (valid) {
                valid = false;
            }
        } else {
            this.makeError = '';
        }

        if (this.model == "" || this.model == null || this.model == undefined) {
            this.modelError = "This field is required!";
            if (valid) {
                valid = false;
            }
        } else {
            this.modelError = '';
        }
        
        return valid;
      },

      loadTradeDetailFieldsData() {
        this.loadAllData(
          ["Make"],
          false,
          this.loadMake,
          "undefined",
          ""
        );
      },

      loadMake(callbackData) {
        this.makeArr = callbackData.Make;
        return true;
      },

      searchModel() {
        this.model = "";
        this.makeError = "";
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
          params: {
            make_id: this.make.value,
          },
        };
        HTTP.get("/all/model/get", config)
          .then((response) => {
            this.modelArr = response.data.content;
          })
          .catch(function (err) {
            console.log("Error on fetching static data", err);
            thisTrade.catchHandler(err, function () {});
          });
      },

      searchVin() {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };

        let data = new FormData();
        data.append("vinNo", this.vinNo);
        HTTP.post("/get-asset-data-with-vinNo", data, config)
          .then((response) => {
            if (response.data.status === "success") {
              let assetData = response.data.content.asset;

              this.year = assetData.year;
              this.odometer = assetData.odometer;

              this.stockId = {
                id: assetData.id,
                vin_no: assetData.vin_no,
                unit: assetData.unit
              }; 
              
              if (assetData.make !== null) {
                this.make = {
                  text: assetData.make.name,
                  value: assetData.make.id,
                };
              }

              if (assetData.model_type !== null) {
                this.model = {
                  text: assetData.model_type.name,
                  value: assetData.model_type.id,
                };
              }
            }
          })
          .catch(function (err) {
            console.log("ERROR!!! 2060", err);
          });
      },

      onSearchStockId(event) {
        let unit = event;
        if(unit.length>3) {
          let config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
          };
          HTTP.get("/search-unit-data/"+unit, config)
              .then(response => {
                  if (response.data.status === "success") {
                      this.stockArr = response.data.content;
                  }
              })
              .catch(function (err) {
                  console.log("ERROR!!!", err);
              });
        }
      },

      selectVin() {
        this.year = '';
        this.make = '';
        this.model = '';
        this.vinNo = this.stockId.vin_no;
      },

      incrementDecrementNumber(event, dataInstance) {
        switch (event.key) {
          case "ArrowUp":
            this[dataInstance] += 1;
            break;
          case "ArrowDown":
            this[dataInstance] -= 1;
            break;
        }
      },

      addTradeValue() {
        this.tradeInValue = this.tradeAcv + this.overAllowance;
      },

      editTradeDetail() {
        this.saveUpdate = "Update";
        let data = this.tradeDetailsData;
        this.make = data.make;
        this.model = data.model;
        this.vinNo = data.vin;
        this.stockId = data.stock_id !== null ? {unit: data.stock_id} : '';
        this.year = data.year;
        this.odometer = data.odometer;
        this.tradeAcv = data.trade_acv;
        this.overAllowance = data.over_allowance;
        this.tradeInValue = data.trade_in_value;
        this.payoff = data.payoff;
        this.tradeGstCredit = data.trade_gst_credit;
        this.engNo = data.eng_no;
        this.licenseNo = data.license_no;
        this.licenseName = data.license_name;
        this.lienAddress = data.lien_address;
        this.safetyInspection = data.safety_inspection;
        this.tiresMinimumTread = data.tires_minimum_tread;
        this.noBrokenGlass = data.no_broken_glass;
        this.noBodyDamage = data.no_body_damage;
        this.tradeNote = data.trade_note;
        this.quantity = data.quantity;
      },

      deleteTradeDetailModal() {
        this.$swal({
          title: '',
          text: 'Are you sure that you want to delete Trade Details!',
          type: 'info',
          confirmButtonText: 'OK',
          showCancelButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
            if (result.value) {
              if(this.currentVehicleWorksheetId !== null) {
                const id = this.tradeDetailsData.id;
                let config = {
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                };
                HTTP.delete("trade/details/"+id, config).then((response) => {
                  if(response.data.status === "success") {
                    EventBus.$emit(
                      "trade-details-data",
                      null,
                      "Delete",
                    );
                  }
                })
                .catch((err) => {
                  console.log("error=>", err);
                  this.catchHandler(err, function () {});
                });
              } else {
                EventBus.$emit(
                  "trade-details-data",
                  null,
                  "Delete",
                );
              }
            }
        });
      }
    },
    watch: {
      isClickAddTrade(status) {
        if (status === true) {
          this.clearData();
          this.loadTradeDetailFieldsData();
          if(this.isEditTradeDetail) {
            this.editTradeDetail();
          }
        }
      },
    },
  };
  
  </script>
  
  <style scoped>
  .trd-dtl-modal-content {
    padding: 0 10px;
  }
  
  .trd-dtl-modal-row {
    align-items: center;
  }
  .trd-dtl-modal-row > div:first-child {
    display: flex;
    align-items: center;
  }
  
  #trd-dtl-modal-header-title {
    color: #506b71;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }
  
  .trd-dtl-modal-row > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
  
  #trd-dtl-close-btn {
    width: 80px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #dbe0e7;
  }
  
  .trade-btn {
    width: 80px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 6px;
    border: unset;
    background: #076eb0;
  
    /* text */
    color: #fff;
    text-align: center;
    font-family: SF UI Display, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;
  }
  
  .modal-row {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 4fr 4fr 4fr;
    row-gap: 12px;
    column-gap: 40px;
  }
  
  .fm-group {
    display: flex;
    flex-direction: column;
    column-gap: 5px;
  }
  
  .po-input {
    height: 32px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid #cecece;
    text-align: right;
  }
  
  .trade-checkbox {
    width: 16.457px;
    height: 18px;
  }

  #add-item-btn {
    width: 116px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 4px;
    border: unset;
    background: #076eb0;
  
    color: #fff;
    text-align: center;
    font-family: SF UI Display, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
  }
  
  .modal-row:nth-child(4) > .modal-column > div:nth-child(3) {
    grid-column: span 2;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .trade-required {
    color: #ff0000;
    font-weight: bolder;
  }
  
  .trade-required-alert {
    color: #ff0000;
    font-weight: lighter;
  }

  #vin-decode-btn {
    width: 116px;
  }

  .modal-body {
    display: grid;
    grid-template-columns: 6fr 2fr;
    column-gap: 40px;
  }

  .modal-body > div:last-child {
    display: grid;
  }

  .modal-body > div:first-child > div:nth-child(5) {
    display: flex;
    justify-content: space-between;
  }

  .modal-body > div:first-child > div:nth-child(5) > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    row-gap: 12px;
  }

  #trade-note {
    height: 88px;
    border-radius: 2px;
    border: 1px solid #CECECE;
  }

  #vin-decode {
    display: flex;
    align-items: end;
  }

  .trade-fm-group {
    display: flex;
    flex-direction: column;
  }

  #delete-btn {
    width: 80px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #dbe0e7;
    background-color: #dc3545;
    color: #fff;
  }

  /* .trd-dtl-modal-content .v-money {
    text-align: right
  } */
  </style>
  