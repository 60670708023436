<template>
    <div
        class="modal"
        id="moveOpportunityModal"
        tabindex="-1"
        role="dialog"
      >
        <div
           v-if="isShowModal"
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content" v-if="!isResponseSuccess">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Move Opportunity</h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click.prevent="closeMoveOpportunityModal('close')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mv-opp-deal-modal-body">
              <div class="row mv-op-deal-select">
                <div class="col-md-3">
                    <label class="form-label pt-2 float-right">Move To:</label>
                </div>
                <div class="col-md-9 pl-0">
                  <div>
                    <v-select
                      v-model="customer"
                      name="customerId"
                      label="text"
                      :filterable="false"
                      :options="customerList"
                      :clearable="false"
                      @search="onSearchCustomer"
                      @input="customerError=''"
                      :placeholder="mvPlaceholder"
                      class="custom-ci-auto-search mv-opp-deal-select"
                      id="mv-op-de-cus"
                    >
                    </v-select>
                    <div class="text-danger font-weight-bold">*</div>
                  </div>
                  <span class="help is-danger pl-1">{{customerError}}</span>
                </div>
              </div>
              <div class="row mv-op-deal-select">
                <div class="col-md-3">
                    <label class="form-label pt-2 float-right">Description:</label>
                </div>
                <div class="col-md-9 pl-0">
                  <div>
                    <textarea v-model="description" class="mv-opp-deal-textarea"></textarea>
                  </div>
                </div>
              </div>
              <div class="pt-2 table-responsive">
                <table class="table custom-table jsmartable">
                  <caption class="d-none">Move Opportunity Deal</caption>
                  <thead>
                    <th></th>
                    <th>Opportunity Ref-No</th>
                    <th>Total Deal</th>
                    <th>Customer</th>
                    <th>Subject</th>
                    <th>Decision Date</th>
                  </thead>
                  <tbody v-if="loader">
                    <tr>
                      <td colspan="6">
                        <loader />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="opportunityList.length > 0">
                    <tr v-for="(data, index) in opportunityList" :key="index">
                        <td><input type="checkbox" :checked="isCheckboxChecked" :disabled="moveIndividual" @change="listSelectedOpportunity($event,data.id,index)"></td>
                        <td style="vertical-align: middle;">{{ data.reference_no }}</td>
                        <td style="vertical-align: middle;">{{ data.total_deals }}</td>
                        <td style="vertical-align: middle;">{{ data.customer_name }}</td>
                        <td style="vertical-align: middle;">{{ data.subject }}</td>
                        <td style="vertical-align: middle;">{{ data.decision_date ? data.decision_date.split(" ")[0] : '' }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <div class="d-flex justify-content-center">
                          <h6>No Data</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary waves-effect" @click="moveOpportuity()">Move Opportunity</button>
              <button type="button" class="btn btn-secondary waves-effect" @click="closeMoveOpportunityModal('close')">Close</button>
            </div>
          </div>
          <div class="modal-content" v-else>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Moved Opportunity Successfully</h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click.prevent="closeMoveOpportunityModal('api')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mv-opp-deal-modal-body table-responsive">
              <table class="table custom-table jsmartable">
                  <caption class="d-none">Moved Opportunity Successfully</caption>
                  <thead>
                    <th>Opportunity</th>
                    <th>Total Deals</th>
                    <th>Current Customer</th>
                    <th>Previous Customer</th>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in responseDataList" :key="index">
                      <td style="vertical-align: middle;">{{ data.reference_no }}</td>
                      <td style="vertical-align: middle;">{{ data.total_deals}}</td>
                      <td style="vertical-align: middle;">{{ data.customer }}</td>
                      <td style="vertical-align: middle;">{{ data.previous_customer }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary waves-effect" @click="closeMoveOpportunityModal('api')">Close</button>
            </div>
          </div>
          <div class="page-loader-wrapper loading-container" v-if="showMovOpdlLoader">
            <div class="loading">
              <img src="/static/images/loading.gif" alt="loader" />
            </div>
          </div>
        </div>
    </div>
</template>
  
  <script>
  import EventBus from "../.././event-bus";
  import {HTTP} from '../../../http-common';
  import { commonMixins } from "../../../mixins/commonMixins";
  import Loader from "../../AssetMaster/NewTransactionSheet/Modal/Loader.vue";

  export default {
    name: "ChangeCustomer",
    components: {'loader': Loader},
    props: ["isClickMoveOpportunity","selectedOpportunityData","selectedRows","moveIndividual"],
    mixins: [commonMixins],
    data() {
      return {
        isShowModal: false,
        opportunityList: [],
        customerList: [],
        customer: "",
        mvPlaceholder: 'Search and Select Customer',
        selectedOpportunity: [],
        customerError: '',
        isResponseSuccess: false,
        responseDataList: [],
        isCheckboxChecked: true,
        loader: true,
        showMovOpdlLoader: false,
        description: ""
      };
    },
    methods: {
      closeMoveOpportunityModal(params) {
        this.customerError = '';
        this.isResponseSuccess = false;
        this.selectedOpportunity = [];
        this.customerList = [];
        this.customer = "";
        this.isCheckboxChecked = true;
        this.isShowModal = false;
        this.opportunityList = [];
        EventBus.$emit("close-move-oppportunity-modal", false, params);
      },
      listSelectedOpportunity(event,oppId,oppIndex) {
        const filteredData = this.selectedOpportunity.find((list) => {
          return list.id == oppId;
        });

        if(event.target.checked && filteredData == undefined) {
          const selectedData = this.opportunityList[oppIndex];
          this.selectedOpportunity.push(selectedData);
        } else {
          this.selectedOpportunity= this.selectedOpportunity.filter(data => data.id !== oppId);
        }
      },
      moveOpportuity() {
        if(this.isValid()) {
          this.$swal({
            title: "",
            text: "Are you sure that you want to move the selected opportunity to the customer '"+this.customer.text+"'",
            type: "info",
            confirmButtonText: "OK",
            showCancelButton: true,
            showLoaderOnConfirm: true,
          })
          .then((result) => {
            if (result.value) {
              this.showMovOpdlLoader = true;
              let config = {
                headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
              };
            
              let data = new FormData();
              data.append("selectedOpportunityList", JSON.stringify(this.selectedOpportunity));
              data.append('selectedCustomerId',this.customer.value );
              data.append('description', this.description);

              HTTP.post("/move/opportunity-deal", data, config)
                .then((response) => {
                  this.showMovOpdlLoader = false;
                  if (response.data.code == '200') {
                    this.showSAlert(
                      response.data.message,
                      response.data.status,
                      false,
                      2
                    );
                    this.isResponseSuccess = true;
                    this.responseDataList = response.data.content;
                  } else if (response.data.code == '201') {
                      this.$swal({
                      title: "",
                      text: response.data.message,
                      type: "info",
                      confirmButtonText: "OK",
                      showCancelButton: false,
                      showLoaderOnConfirm: false
                    });
                  }
                })
                .catch((error) => {
                  this.showMovOpdlLoader = false;
                  this.catchHandler(error, function () {
                  });
                });
            }
          });
        }
      },
      onSearchCustomer(search, loading) {
        if (search.length > 2) {
            loading(true);
            this.loadAllData(['Contact'], true, this.loadCustomerCallback, loading, search);
        }
      },
      loadCustomerCallback: function(callbackData) {
          this.customerList = callbackData.Contact;
      },
      isValid() {
        let valid = true;
        if (Object.keys(this.customer).length == 0 || this.customer == null || this.customer == undefined) {
          this.customerError = "Please Select The Customer !!";
          if (valid) {
              valid = false;
          }
        } else {
            this.customerError = '';
        }

        if (valid && this.selectedOpportunity.length == 0) {
          this.showSAlert(
            "Please Select At Least One Opportunity !!",
            "info",
            false,
            2
          );
          valid = false;
        }
        return valid;
      }
    },
    watch: {
        isClickMoveOpportunity(status) {
        if(status) {
          this.isShowModal = true;
          this.$nextTick(() => {
            this.opportunityList = this.selectedOpportunityData;
            this.selectedOpportunity = this.selectedOpportunityData;
          });
          this.loader = false;
        }
      }
    },
  };
  
  </script>
  
  <style>
    .mv-opp-deal-modal-body {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }

    #moveOpportunityModal .mv-opp-deal-select {
      width: 100%;
    }

    .mv-op-deal-select > div:nth-child(2) > div {
      display: flex;
      column-gap: 5px;
    }

    .mv-op-deal-select > div:first-child {
      max-width: 15.666667% !important;
    }

    .mv-op-deal-select > :nth-child(4) {
      max-width: 38.333333%;
    }

    .mv-opp-deal-modal-body > div:last-child > table > tbody > tr:last-child > td {
      border-bottom: 2px solid black !important;
    }

    #mv-op-de-cus > .dropdown-menu { 
        max-height: 120px !important;
    }

    .mv-opp-deal-textarea {
      width: 100%;
      height: 90px;
    }

    @media (max-width: 991px) {
        .mv-opp-deal-modal-body > .mv-op-deal-select > div:first-child {
          max-width: 22.666667% !important;
        }

        .mv-opp-deal-textarea {
          width: 100%;
          height: 70px;
        }
    }

    @media (max-width: 767px) {
      .mv-op-deal-select > div:nth-child(2) > div {
        margin-left: 18px;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 18.666667% !important;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:nth-child(2) > div:first-child {
        max-width: 23.666667% !important;
      }

      .mv-op-deal-select > div:last-child > .is-danger {
          margin-left: 15px !important;
      }
    }

    @media (max-width: 500px) {
      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 19.666667% !important;
      }
    }

    @media (max-width: 476px) {
      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 20.666667% !important;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:nth-child(2) > div:first-child {
        max-width: 25.666667% !important;
      }
    }

    @media (max-width: 454px) {
      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 22.666667% !important;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:nth-child(2) > div:first-child {
        max-width: 28.666667% !important;
      }
    }

    @media (max-width: 415px) {
      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 23.666667% !important;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:nth-child(2) > div:first-child {
        max-width: 29.666667% !important;
      }
    }

    @media (max-width: 399px) {
      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 26.666667% !important;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:nth-child(2) > div:first-child {
        max-width: 31.666667% !important;
      }
    }

    @media (max-width: 356px) {
      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 28.666667% !important;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:nth-child(2) > div:first-child {
        max-width: 33.666667% !important;
      }
    }

    @media (max-width: 332px) {
      .mv-opp-deal-modal-body > .mv-op-deal-select:first-child > div:first-child {
        max-width: 31.666667% !important;
      }

      .mv-opp-deal-modal-body > .mv-op-deal-select:nth-child(2) > div:first-child {
        max-width: 36.666667% !important;
      }
    }
  </style>
  