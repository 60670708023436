<template>
    <div>
        <div class="main-internal-section width-full float-l">
            <div class="container">
                <MailDetail v-bind:url="url"></MailDetail>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {HTTP} from '../../http-common';
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from '../event-bus';
    import '@alpesh.savitriya/pseudomailer/dist/pseudomailer.css';
    import { Components } from '@alpesh.savitriya/pseudomailer';
    var self;

    export default {
        components: {
        },
        name: 'ListMail',
        mixins: [commonMixins],
        data: () => ({
            url: process.env.VUE_APP_ROOT_API,
        }),
        beforeMount: function () {
            self = this;
        },
        mounted: function() {
            self.setActiveMenu('mail-list');
            EventBus.$emit("menu-selection-changed", "mail-list");
        },
        created: function () {
            self = this;
            self.getLoginIdPermissions();
        },
    }
</script>
