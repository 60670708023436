<template>
  <div>
    <!-- The modal -->
    <div class="modal fade" id="defaultModalToMoveAssetsToOtherCompany" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" >Assets Move to CRLEASE</h4>
                </div>

                <div class="col-md-12 modal-body">
                    <small>Duplicate Inventory Found, Send Move Request.</small>
                    <div class="col-12 paddingnone p-0">
                        <div style="max-height: 50vh;" class="table-listing white-bg border-blue border-radius-top-right">
                            <!-- Table to display the records -->
                            <table class="table custom-table jsmartable">
                                <thead>
                                    <tr>
                                        <th class="">
                                            <div class="custom-control custom-checkbox">
                                                <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBoxMoveAsset" name="example1" @change="clickHeaderCheckBox()">
                                                <label class="custom-control-label" for="selectAllCheckBoxMoveAsset"></label>
                                            </div>
                                        </th>
                                        <th class="column-header">Vin No</th>
                                        <th class="column-header">Stock</th>
                                        <th class="column-header">Condition</th>
                                        <th class="column-header">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="records && records.length > 0">
                                        <template v-for="(record, index) in records">
                                            <tr class="mainDetails"  :key="'row_asset_move_to_other_company' + index">

                                                <td>
                                                    <!-- Checkbox to select the record -->
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" v-model="selectedRows" :value="index" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheckAssetMove' + index" name="case[]">
                                                            <label class="custom-control-label" :for="'customCheckAssetMove' + index"></label>
                                                    </div>
                                                </td>
                                                <td>{{ record.vinno }}</td>
                                                <td>{{ record.stock }}</td>
                                                <td>{{ record.condition }}</td>
                                                <td>{{ record.status }}</td>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 paddingnone p-0">

                         <!-- Message -->
                        <div class="col-12 forms-block">
                            <div class="col-12 paddingleft0">
                                <div class="form-label">Request Message</div>
                            </div>
                            <div class="col-12 paddingleft0">
                                <textarea id="req_message" v-model="req_message" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-md-12 modal-footer">
                    <!-- Button to perform the action on the selected records -->
                    <button @click="performAction" class="btn general-button btn-success waves-effect">Submit</button>

                    <!-- Button to close the modal -->
                    <button type="button" data-dismiss="modal" class="btn general-button btn-danger waves-effect">Close</button>
                </div>
                
            </div>
            </div>
        </div>       
  </div>
</template>

<script>
var self;
import {HTTP} from '../../http-common';
import { commonMixins } from "../../mixins/commonMixins";

export default {
  mixins: [commonMixins],
  data() {
    return {
      showModal: false, // Whether to show the modal
      selectedRows: [], // The IDs of the selected records
      selectAll: false, // Selected all is check or not
      req_message: ''
    };
  },
  props: {
    records: [], // The records to display in the table
  },
  beforeMount() {
        self = this;
  },
  methods: {
    openModal() {
      // TODO: Fetch the records from the server and set them in this.records
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    },
    restForm() {
        self.selectedRows = [];
        self.req_message = '';
        self.selectAll = false;
    },
    performAction() {
        var config = {
            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
        };
        console.info('self.records===>>',self.records);
        var selectedRecords = [];

        self.selectedRows.forEach((item, index) => {
            selectedRecords.push(self.records[item]);
        });

        console.info('selectedRecords===>>>');
        console.info(selectedRecords);

        var fields = {
            data: {'records': selectedRecords, 'action': 'move-inventory-approval-request', 'req_message': self.req_message},
            req_message: self.req_message
        };
        
        self.showLoader();
        if(selectedRecords.length) {
            HTTP.post("/move-inventory/send-approval-request", fields, config)
                .then(function (data) {
                    self.hideLoader();
                    if (data.status == 200) {
                        self.restForm();
                        let seconds = 5000;
                        self.$swal({
                            type: 'success',
                            html: 'Inventory move request send!',
                            showConfirmButton: false,
                            timer: seconds,
                        });
                        $('#defaultModalToMoveAssetsToOtherCompany').modal('hide');    
                    }
                })
                .catch(function (error) {
                    self.hideLoader();
                    self.catchHandler(error, function () {});
                })
        }else{
            let seconds = 5000;
            self.$swal({
                type: 'error',
                html: 'Please select assets first!',
                showConfirmButton: false,
                timer: seconds,
            });
        }
    },
    clickHeaderCheckBox: function () {
        setTimeout(function () {
            self.selectedRows = [];
            if (self.selectAll) {
                for (let i in self.records) {
                    self.selectedRows.push(i);
                }
            }
        }, 100);
    },
    inputCheckbox: function (e) {
        setTimeout(function () {
            self.selectAll = false;
            let selectedRecords = self.selectedRows.length;
            let totalRecords = self.records.length;

            console.info('this.selectedRows.length => '.selectedRecords);
            console.info('this.records.length => '.totalRecords);
            
            if (self.selectedRows.length == self.records.length) {
                self.selectAll = true;
            }
        }, 100);
    },
  },
};
</script>
