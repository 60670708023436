<template>
    <div id="page-content-wrapper" class="user-location-manager admin-content-area">
        <template>
            <div class="container-fluid xyz">
                <div class="spaced-container">
                    <div class="col-12 page_title paddingnone">
                        <button v-if="permissionNameArray.includes('can_add_location_contact_location_master')" class="btn btn-primary waves-effect pull-right ml-2" @click="showForm()">Add Location Contact</button>
                    </div>     
                    
                    <div class="col-12 paddingnone">
                        <div class="row no-margin border">
                            <div class="table-listing white-bg border-blue border-radius-top-right">
                                <table class="table custom-table jsmartable">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Contact Type</th>
                                            <th>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="permissionNameArray.includes('can_list_location_contact_location_master') && contactManagerList.length > 0">
                                            <tr v-for="(data,index) in contactManagerList" :key="index">
                                                <td>
                                                    <template v-if="permissionNameArray.includes('can_add_location_contact_location_master')">
                                                        <a v-on:click="editContactManager(data)" title='Edit Contact Manager' href="javascript:void(0)" class="edit-icon">
                                                            <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                                        </a>
                                                    </template>
                                                    <template v-if="permissionNameArray.includes('can_delete_location_contact_location_master')">
                                                        <a v-on:click="deleteContactManager(data.loc_cont_ids)" title='Delete Contact Manager' href="javascript:void(0)" class="trash-icon">
                                                            <i  class="exceptEdit fa fa-trash-o pr-1" aria-hidden="true"></i>
                                                        </a>
                                                    </template>
                                                </td>
                                                <td>{{data.contact_type}}</td>
                                                <td>
                                                    <template v-for="(loc,iLoc) in data.location">
                                                        <template v-if="permissionNameArray.includes('can_delete_location_contact_location_master')">
                                                            <span class="locations_name">{{loc}} <i class="fa fa-times delete-icon" v-on:click="deleteContactManager([data.loc_cont_ids[iLoc]])"></i></span>
                                                        </template>
                                                        <template v-else>
                                                            <span class="locations_name">{{loc}}</span>
                                                        </template>
                                                    </template>
                                                    <template v-if="permissionNameArray.includes('can_add_location_contact_location_master')">
                                                        <i class="fa fa-plus-circle add-icon" v-on:click="editContactManager(data)"></i>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <th colspan="7" class="text-center">No Data</th>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="isShowForm">
                    <add-user-roles-for-location :userData="userData"></add-user-roles-for-location>
                </div>
            </div>
        </template>
        <app-footer></app-footer>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import AddUserRolesForLocationModal from "@/components/UserManagement/UserMaster/AddUserRolesForLocationModal.vue";
    import { HTTP } from '../../../http-common';

    Vue.component("app-footer", Footer);

    export default {
        name: 'UserRolesForLocation',
        mixins: [commonMixins],
        components: {
            'add-user-roles-for-location': AddUserRolesForLocationModal,
        },
        data () {
            return {
                permissionNameArray: [],
                isShowForm: false,
                userData: {},
                contactManagerList: [],
            }
        },
        created: function () {
            self = this;
            self.getLoginIdPermissions();
        },
        mounted() {
            self = this;
            self.getList();
        },
        methods: {
            showForm() {
                self.userData = null;
                $("#user-contact-manager-form").modal({ backdrop: "static", keyboard: false });
                self.isShowForm = true;
            },
            editContactManager(data) {
                $("#user-contact-manager-form").modal({ backdrop: "static", keyboard: false });
                self.userData = {};
                self.userData.contact_type = { 'text' : data.contact_type , 'value' : data.id};
                let location = [];
                $.each(data.location, function (index,value) {
                    let location_object = {};
                    location_object = {'text' : data.location[index],
                                        'value' : data.location_ids[index],
                                      };
                    location.push(location_object);
                });
                self.userData.location = location;
                self.userData.loc_cont_ids = data.loc_cont_ids;
                self.isShowForm = true;
            },
            getList() {
                const config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                    },
                    params: {
                        user_id: self.$route.params.id,
                    }
                };
                HTTP.get("/user/location/contacts", config)
                .then(function (response) {
                    self.contactManagerList = response.data.content;
                })
                .catch(function (err) {
                    self.catchHandler(err, function () {});
                });
            },
            deleteContactManager(id) {
                let id_string = id.join(',');
                self.$swal({
                    title: '',
                    text: 'Are you sure you want to delete this record!',
                    type: 'info',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        const config = {
                            headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                        };
                        // HTTP.delete("/user/location/contacts/"+id, config)
                        HTTP.delete("/user/location/contacts/"+id_string, config)
                            .then(function (response) {
                                if (response.data.code == '200') {
                                    self.getList();
                                }
                            })
                            .catch(function (error) {
                                console.log('error=>',error);
                                self.catchHandler(error, function () {});
                            });
                    }
                });
            },
        }
    }

    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });

    EventBus.$on("close-user-contact-manager-default", function (status,method) {
        if (typeof self != "undefined") {
            self.isShowForm = status;
            self.userData = null;
            if(method !== null) {
                self.getList();
            }
            $("#user-contact-manager-form").modal("hide");
        }
    });
    
</script>
<style>
.user-location-manager .table.custom-table td{
    white-space: normal;
}
.user-location-manager .locations_name{
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 5px;
    margin-right:5px;
    display:inline-block;
}
.user-location-manager .delete-icon{
    font-size: 11px;
    float: right;
    padding: 4px 0 0 5px;
    color: #999;
    cursor: pointer;
}
.user-location-manager .add-icon{
    padding-left: 15px;
    font-size: 18px;
    color: #aaaaaa;
    cursor: pointer;
}
.user-location-manager .edit-icon{
    float: left;
    margin-right: 5px !important;
    color: #aaaaaa;
    padding: 0px 2px;
}
.user-location-manager .edit-icon:hover,
.user-location-manager .add-icon:hover,
.user-location-manager .trash-icon:hover{
    color:#0056b3;
}
.user-location-manager .trash-icon{
    float: left;
    margin-right: 5px !important;
    color: #aaaaaa;
    padding: 0px 2px;
    margin-top: 3px;
}
</style>