<template>
    <div>
        <template v-if="permissionNameArray.includes('can_add_opportunity_master')">
            <div class="row no-margin">
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.salesman}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="salesmanId"
                                  @change="changedValue"
                                  @input="salesmanError = ''"
                                  @search="onSearchSalesman"
                                  @clear="onClearSalesman"
                                  name="salesman"
                                  id="salesman"
                                  label="text"
                                  :clearable="true"
                                  :searchable="true"
                                  :filterable="false"
                                  :options="salesmanArr"
                                  :placeholder="staticMessages.acquire_by_place_holder">
                            <span slot="no-options" @click="$refs.select.open = false">
                                <template v-if="!isSalesmanRecordNotFound">
                                    {{staticMessages.acquire_by_search}}
                                </template>
                                <template v-else>
                                    {{staticMessages.search_record_not_found}}
                                </template>
                            </span>
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{salesmanError}}</span>
                    </div>
                </div>
                <!-- Asset -->
                <!--<div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.asset_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="assetId" name="state"
                                  label="text" :filterable="false" :options="assetArr"
                                  @search="onSearchAsset" :clearable="false" @input="assetError = ''"
                                  :placeholder="staticMessages.asset_place_holder" class="custom-ci-auto-search">
                            <span slot="no-options" @click="$refs.select.open = false">
                                <template v-if="!isAssetRecordNotFound">
                                    {{staticMessages.asset_search}}
                                </template>
                                <template v-else>
                                    {{staticMessages.search_record_not_found}}
                                </template>
                            </span>
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{assetError}}</span>
                    </div>
                </div>-->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.lead}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="leadId"
                                @change="changedValue"
                                @input="leadIdError = ''"
                                @search="onSearchLead"
                                @clear="onClearLead"
                                name="leadId"
                                id="leadId"
                                label="text"
                                :clearable="true"
                                :searchable="true"
                                :filterable="false"
                                :options="leadIdArr"
                                :placeholder="staticMessages.select_lead">
                              <span slot="no-options" @click="$refs.select.open = false">
                                  <template v-if="!isLeadRecordNotFound">
                                      {{staticMessages.search_lead}}
                                  </template>
                                  <template v-else>
                                      {{staticMessages.search_record_not_found}}
                                  </template>
                              </span>
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{leadIdError}}</span>
                    </div>
                </div>
                <!-- Lead Quality -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.lead_quality_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="leadQuality"
                                @change="changedValue"
                                @input="leadQualityError = ''"
                                name="leadQuality"
                                id="leadQuality"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="leadQualityArr"
                                :placeholder="staticMessages.select_lead_quality">
                        </v-select>
                        <!--<input @input="leadQualityError = ''" id="leadQuality" v-model="leadQuality" class="form-control" type="text"/>-->
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{leadQualityError}}</span>
                    </div>
                </div>

                <!-- Sale Type -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.sale_type_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="saleType"
                                @change="changedValue"
                                @input="saleTypeError = ''"
                                name="saleType"
                                id="saleType"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="saleTypeArr"
                                :placeholder="staticMessages.select_sale_type">
                        </v-select>
                        <!--<input @input="saleTypeError = ''" id="saleType" v-model="saleType" class="form-control" type="text"/>-->
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{saleTypeError}}</span>
                    </div>
                </div>

                <!-- Funding Source -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.funding_source_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="fundingSource"
                                @change="changedValue"
                                @input="fundingSourceError = ''"
                                name="fundingSource"
                                id="fundingSource"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="fundingSourceArr"
                                :placeholder="staticMessages.select_funding_source">
                        </v-select>
                        <!--<input @input="fundingSourceError = ''" id="fundingSource" v-model="fundingSource" class="form-control" type="text"/>-->
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{fundingSourceError}}</span>
                    </div>
                </div>
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.lender_internal}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="lenderId"
                                @change="changedValue"
                                @input="lenderIdError = ''"
                                @search="onSearchLender"
                                @clear="onClearLender"
                                name="lenderId"
                                id="lenderId"
                                label="text"
                                :clearable="true"
                                :searchable="true"
                                :filterable="false"
                                :options="lenderIdArr"
                                :placeholder="staticMessages.acquire_from_place_holder">
                              <span slot="no-options" @click="$refs.select.open = false">
                                  <template v-if="!isLenderRecordNotFound">
                                      {{staticMessages.acquire_from_search}}
                                  </template>
                                  <template v-else>
                                      {{staticMessages.search_record_not_found}}
                                  </template>
                              </span>
                        </v-select>
                        <!--<div class="requiredstar">*</div>
                        <span class="help is-danger">{{lenderIdError}}</span>-->
                    </div>
                </div>
                <!-- Funding Method -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.funding_method_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="fundingMethod"
                                @change="changedValue"
                                @input="fundingMethodError = ''"
                                name="fundingMethod"
                                id="fundingMethod"
                                label="text"
                                :clearable="false"
                                :searchable="false"
                                :filterable="false"
                                :options="fundingMethodArr"
                                :placeholder="staticMessages.select_funding_method">
                        </v-select>
                        <!--<input @input="fundingMethodError = ''" id="fundingMethod" v-model="fundingMethod" class="form-control" type="text"/>-->
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{fundingMethodError}}</span>
                    </div>
                </div>
                <!-- Funding Status -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.funding_status_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="fundingStatus"
                                @change="changedValue"
                                @input="fundingStatusError = ''"
                                name="fundingStatus"
                                id="fundingStatus"
                                label="text"
                                :clearable="true"
                                :searchable="false"
                                :filterable="false"
                                :options="fundingStatusArr"
                                :placeholder="staticMessages.select_funding_status">
                        </v-select>
                        <!--<input @input="fundingStatusError = ''" id="fundingStatus" v-model="fundingStatus" class="form-control" type="text"/>-->
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{fundingStatusError}}</span>
                    </div>
                </div>

                <!-- Down Payment -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.down_payment_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="downPaymentError = ''" id="downPayment" v-model="downPayment" class="form-control" type="text"/>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{downPaymentError}}</span>
                    </div>
                </div>

                <!-- deposit Received -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.deposit_received_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="depositReceivedError = ''" id="depositReceived" v-model="depositReceived" class="form-control" type="text"/>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{depositReceivedError}}</span>
                    </div>
                </div>

                <!-- deposit Date -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.deposit_date_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <date-picker
                                :placeholder="staticMessages.deposit_date_place_holder"
                                class="form-control"
                                autocomplete="off"
                                v-model="depositDate"
                                :config="bootstrapOptions"
                                @input="depositDateError = ''"
                        >
                        </date-picker>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{depositDateError}}</span>
                    </div>
                </div>

                <!-- Funding Rate -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.funding_rate_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="fundingRateError = ''" id="fundingRate" v-model="fundingRate" class="form-control" type="text"/>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{fundingRateError}}</span>
                    </div>
                </div>

                <!-- Trade Detail -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.trade_detail_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="tradeDetailError = ''" id="tradeDetail" v-model="tradeDetail" class="form-control" type="text"/>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{tradeDetailError}}</span>
                    </div>
                </div>

                <!-- Approx Trade Value -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.approx_trade_value_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <input @input="approxTradeValueError = ''" id="approxTradeValue" v-model="approxTradeValue" class="form-control" type="text"/>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{approxTradeValueError}}</span>
                    </div>
                </div>

                <!-- Warranty Type -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.warranty_type_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select 
                            v-model="warrantyTypeId" 
                            name="state"
                            label="text" :filterable="false" 
                            :options="warrantyTypeArr"
                            @search="onSearchWarrantyType" 
                            :clearable="false" 
                            @input="warrantyTypeError = ''"
                            :placeholder="staticMessages.warranty_place_holder" 
                            class="custom-ci-auto-search">
                            <span slot="no-options" @click="$refs.select.open = false">
                                <template v-if="!isWarrantyRecordNotFound">
                                    {{staticMessages.warranty_search}}
                                </template>
                                <template v-else>
                                    {{staticMessages.search_record_not_found}}
                                </template>
                            </span>
                        </v-select>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{warrantyTypeError}}</span>
                    </div>
                </div>

                <!-- Source -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.source_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select 
                        v-model="source"
                        @change="changedValue"
                        @input="sourceError = ''"
                        name="source"
                        id="source"
                        label="text"
                        :clearable="false"
                        :searchable="false"
                        :filterable="false"
                        :options="sourceArr"
                        :placeholder="staticMessages.select_source">
                    </v-select>
                        <!--<input @input="sourceError = ''" id="source" v-model="source" class="form-control" type="text"/>-->
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{sourceError}}</span>
                    </div>
                </div>

                <!-- Internal Notes -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.internal_notes_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <textarea @input="internalNoteError = ''" id="internalNote" v-model="internalNote" class="form-control"></textarea>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{internalNoteError}}</span>
                    </div>
                </div>

                <!-- Invoice Date -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.invoice_date_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <date-picker
                                :placeholder="staticMessages.invoice_date_place_holder"
                                class="form-control"
                                autocomplete="off"
                                v-model="invoiceDate"
                                :config="bootstrapOptions"
                                @input="invoiceDateError = ''"
                        >
                        </date-picker>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{invoiceDateError}}</span>
                    </div>
                </div>

                <!-- Invoice Notes -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.invoice_notes_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <textarea @input="invoiceNoteError = ''" id="invoiceNote" v-model="invoiceNote" class="form-control"></textarea>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{invoiceNoteError}}</span>
                    </div>
                </div>

                <!-- Location -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="locationId" 
                                  name="state"
                                  label="text" 
                                  :filterable="false" :options="locationArr"
                                  :search="false" :clearable="false" @input="locationError = ''"
                                  :placeholder="staticMessages.location_place_holder" class="custom-ci-auto-search">
                        </v-select>
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{locationError}}</span>
                    </div>
                </div>

                <!-- Customer Display -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.customer_display_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="customerDisplayId" name="state"
                                  label="text" :filterable="false" :options="customerDisplayArr"
                                  :search="false" :clearable="false" @input="customerDisplayError = ''"
                                  :placeholder="staticMessages.customer_display_place_holder" class="custom-ci-auto-search">
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{customerDisplayError}}</span>
                    </div>
                </div>

                <!-- Include Salesman -->
                <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.include_salesman_label}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                        <v-select v-model="includeSalesmanId" name="state"
                                  label="text" :filterable="false" :options="includeSalesmanArr"
                                  :search="false" :clearable="false" @input="includeSalesmanError = ''"
                                  :placeholder="staticMessages.include_salesman_place_holder" class="custom-ci-auto-search">
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{includeSalesmanError}}</span>
                    </div>
                </div>
            </div>
            <!--Error Alert-->
            <div class="row clearfix" >
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="alert alert-danger" id="alertError">
                        <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                        <strong id="errorMsg" class="error"></strong>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
                <input id="addOpportunityBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addOpportunity()">
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
</template>

<script>
    var self;
    import Vue from "vue";
    import vSelect from 'vue-select';
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../.././event-bus";
    import {HTTP} from '../../../http-common';
    import FilterComponent from "../../FilterComponent/FilterComponent";
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    Vue.component("app-footer", Footer);
    Vue.component('v-select', vSelect);

    export default {
        props: ['isEdit', 'permissionNameArray'],
        name: 'AddUpdateAssetExpenseTab',
        mixins: [commonMixins],
        components: {
            'filter-component' : FilterComponent,
            'no-access' : NoAccessPage,
            datePicker,
        },
        data () {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                name: "",
                abbrevation: "",
                websiteName: "",
                address: "",
                city: "",
                cityArr: [],

                /* This form */
                assetId: "",
                assetArr: [],
                isAssetRecordNotFound: false,
                assetError : "",
                warrantyTypeId : "",
                warrantyTypeArr : [],
                isWarrantyRecordNotFound: false,
                warrantyTypeError : "",
                locationId : "",
                locationArr : [],
                locationError : "",
                leadQuality : "",
                leadQualityArr : [],
                leadQualityError : "",
                saleType : "",
                saleTypeArr : [],
                saleTypeError : "",
                fundingSource : '',
                fundingSourceArr : [],
                fundingSourceError : "",
                fundingMethod : "",
                fundingMethodArr : [],
                fundingMethodError : "",
                fundingStatus : "",
                fundingStatusArr : [],
                fundingStatusError : "",
                downPayment : "",
                downPaymentError : "",
                depositReceived : "",
                depositReceivedError : "",
                fundingRate : "",
                fundingRateError : "",
                tradeDetail : "",
                tradeDetailError : "",
                approxTradeValue : "",
                approxTradeValueError : "",
                source : "",
                sourceArr: [],
                sourceError : "",
                internalNote : "",
                internalNoteError : "",
                invoiceNote : "",
                invoiceNoteError : "",
                customerDisplayId : { text : "No", value : "no"},
                customerDisplayArr : [{ text : 'Yes', value : 'yes'}, { text : "No", value : "no"}],
                customerDisplayError : "",
                includeSalesmanId : { text : "No", value : "no"},
                includeSalesmanArr : [{ text : 'Yes', value : 'yes'}, { text : "No", value : "no"}],
                includeSalesmanError : "",

                depositDate : "",
                depositDateError : "",
                invoiceDate : "",
                invoiceDateError : "",
                bootstrapOptions: {
                    /*format: 'MM/DD/YYYY',
                    useCurrent: false,*/
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    showClear: true,
                    showClose: true,
                    allowInputToggle: true,
                },
                timePicker: {
                    allow : false,
                    format: 'LT',
                    useCurrent: false
                },
                /* End This Form*/
                zipCode: "",
                phone: "",
                sms: "",
                fax: "",
                email: "",
                statusId: {text: 'Active', value: 'active'},
                statusArr: [
                    {
                        text: 'Active',
                        value: 'active'
                    },
                    {
                        text: 'Inactive',
                        value: 'inactive'
                    }
                ],
                locationNote: "",
                cotactName: "",
                salesTax: "",
                laborTax: "",
                partsEmail: "",
                shopEmail: "",
                partStatusId: {text: 'Active', value: 'active'},
                shopStatusId: {text: 'Active', value: 'active'},

                nameError: "",
                abbrevationError: "",
                websiteNameError: "",
                addressError: "",
                stateError: "",
                cityError: "",
                zipCodeError: "",
                phoneError: "",
                smsError: "",
                faxError: "",
                emailError: "",
                locationNoteError: "",
                cotactNameError: "",
                salesTaxError: "",
                laborTaxError: "",
                partsEmailError: "",
                shopEmailError: "",

                buttonNames : {
                    save : 'Save',
                    saveAndContinue : 'Save And Continue',
                    cancel : 'Cancel'
                },
                disableButtons : {
                    save : false,
                    saveAndContinue : false,
                    cancel : false
                },
                alertClass : '',
                alertMessage : '',
                pageTitle : "Add Opportunity",
                salesmanId : "",
                salesmanArr : [],
                salesmanError : "",
                isSalesmanRecordNotFound: false,
                leadId: "",
                leadIdArr : [],
                leadIdError: "",
                isLeadRecordNotFound: false,
                lenderId: "",
                lenderIdArr : [],
                lenderIdError: "",
                isLenderRecordNotFound: false,
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {

        },
        created : function () {
            self = this;
            //self.getLoginIdPermissions();
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                /*self.salesmanId = {"text": userJson.first_name + " " + userJson.last_name, "value": userJson.id};*/
                self.salesmanId = {text: userJson.first_name + " " + userJson.last_name, value: userJson.id};
            }
            $("#alertError").hide();
            if (typeof(self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.assetId = {text: "", value: self.$route.params.id};
                if (self.isEdit) {
                    self.getOpportunityDetailsById(self.isEdit);
                }
            }
            self.loadAllData(['Location', 'ReferralSource', 'LeadQuality', 'SaleType', 'FundingSource', 'FundingMethod', 'FundingStatus'], true, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('Location', true, self.loadLocationCallback, "undefined", '');
            self.loadAllData('ReferralSource', true, self.loadSourceCallback, "undefined", '');
            self.loadAllData('LeadQuality', true, self.loadLeadQualityCallback, "undefined", '');
            self.loadAllData('SaleType', true, self.loadSaleTypeCallback, "undefined", '');
            self.loadAllData('FundingSource', true, self.loadFundingSourceCallback, "undefined", '');
            self.loadAllData('FundingMethod', true, self.loadFundingMethodCallback, "undefined", '');
            self.loadAllData('FundingStatus', true, self.loadFundingStatusCallback, "undefined", ''); */
        },
        methods: {
            loadMasterDataCallback: function (callbackData) {
                self.locationArr = callbackData.Location;
                self.sourceArr = callbackData.ReferralSource;
                self.leadQualityArr = callbackData.LeadQuality;
                self.saleTypeArr = callbackData.SaleType;
                self.fundingSourceArr = callbackData.FundingSource;
                self.fundingMethodArr = callbackData.FundingMethod;
                self.fundingStatusArr = callbackData.FundingStatus;
            },
            /* loadFundingStatusCallback: function (callbackData) {
                self.fundingStatusArr = callbackData.FundingStatus;
            },
            loadFundingMethodCallback: function (callbackData) {
                self.fundingMethodArr = callbackData.FundingMethod;
            },
            loadFundingSourceCallback: function (callbackData) {
                self.fundingSourceArr = callbackData.FundingSource;
            },
            loadSaleTypeCallback: function (callbackData) {
                self.saleTypeArr = callbackData.SaleType;
            },
            loadLeadQualityCallback: function (callbackData) {
                self.leadQualityArr = callbackData.LeadQuality;
            },
            loadLocationCallback : function (callbackData) {
                self.locationArr = callbackData.Location;
            },
            loadSourceCallback: function (callbackData) {
                self.sourceArr = callbackData.ReferralSource;
            }, */
            onSearchSalesman(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['User'], true, self.loadSalesmanCallback, loading, search);
                }
            },
            loadSalesmanCallback: function (callbackData) {
                self.isSalesmanRecordNotFound = false;
                self.salesmanArr = callbackData.User;
                if(self.salesmanArr.length == 0) {
                    self.isSalesmanRecordNotFound = true;
                }
            },
            onClearSalesman : function() {
                self.salesmanError= "";
            },
            onSearchAsset(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Asset'], true, self.loadAssetDataCallback, loading, search);
                }
            },
            loadAssetDataCallback: function(callbackData) {
                self.isAssetRecordNotFound = false;
                self.assetArr = callbackData.Asset;
                if(self.assetArr.length == 0) {
                    self.isAssetRecordNotFound = true;
                }
            },
            onSearchLead(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadLeadCallback, loading, search);
                }
            },
            loadLeadCallback: function (callbackData) {
                self.isLeadRecordNotFound = false;
                self.leadIdArr = callbackData.Contact;
                if(self.leadIdArr.length == 0) {
                    self.isLeadRecordNotFound = true;
                }
            },
            onClearLead : function() {
                self.leadIdError = "";
            },
            onSearchLender(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadLenderCallback, loading, search);
                }
            },
            loadLenderCallback: function (callbackData) {
                self.isLenderRecordNotFound = false;
                self.lenderIdArr = callbackData.Contact;
                if(self.lenderIdArr.length == 0) {
                    self.isLenderRecordNotFound = true;
                }
            },
            onClearLender : function() {
                self.lenderIdError = "";
            },
            onSearchWarrantyType : function(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['WarrantyType'], true, self.loadWarrantyTypeDataCallbcak, loading, search);
                }
            },
            loadWarrantyTypeDataCallbcak : function(callbackData) {
                self.isWarrantyRecordNotFound = false;
                self.warrantyTypeArr = callbackData.WarrantyType;
                if(self.warrantyTypeArr.length == 0) {
                    self.isWarrantyRecordNotFound = true; 
                }
            },
            toggleAssetListBlock: function () {
                self.isOpenListBlock = !self.isOpenListBlock;
            },
            changeDatePickerDate : function () {
                self.paidDateError = "";
                self.rebillPaidDateError = "";
            },
            changedValue: function () {
            },
            /* onSearchVendor(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Vendor'], true, self.loadAcquireFromCallback, loading, search);
                }
            },
            loadAcquireFromCallback: function (callbackData) {
                self.isVendorRecordNotFound = false;
                self.vendorArr = callbackData.Vendor;
                if(self.vendorArr.length == 0) {
                    self.isVendorRecordNotFound = true;
                }
            },
            onClearVendor : function() {
                self.vendorError = "";
            }, */
            onSearchRebillContact(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Contact'], true, self.loadRebillContactFromCallback, loading, search);
                }
            },
            loadRebillContactFromCallback: function (callbackData) {
                self.isRebillContactRecordNotFound = false;
                self.rebillContactrArr = callbackData.Contact;
                if(self.rebillContactrArr.length == 0) {
                    self.isRebillContactRecordNotFound = true;
                }
            },
            onClearRebillContact : function() {
                self.rebillContact = {text: 'Select', value: null};
            },
            loadExpenseCategoryCallback: function (callbackData) {
                self.expenseCategoryArr = callbackData;
            },
            getOpportunityDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : id
                    }
                };
                HTTP.get('/opportunity/get', config)
                    .then(response => {
                        if(response.data.content.salesman_id != null){
                            /*self.salesmanId = {text: self.formatString(response.data.content.firstName + " " + response.data.content.lastName), value: response.data.content.salesman_id};*/
                            self.salesmanId = {text: self.formatString(response.data.content.firstName + " " + response.data.content.lastName), value: response.data.content.salesman_id};
                        }
                        if(response.data.content.lead_id != null){
                            /*self.leadId = {text: self.formatString(response.data.content.lead_first_name + " " + response.data.content.lead_last_name), value: response.data.content.lead_id};*/
                            self.leadId = {text: self.formatString(response.data.content.lead_first_name), value: response.data.content.lead_id};
                        }
                        if(response.data.content.lender_id != null){
                            /*self.lenderId = {text: self.formatString(response.data.content.lender_first_name + " " + response.data.content.lender_last_name), value: response.data.content.lender_id};*/
                            self.lenderId = {text: self.formatString(response.data.content.lender_first_name), value: response.data.content.lender_id};
                        }
                        if(response.data.content.location_id != null){
                            self.locationId = {text: response.data.content.locName, value: response.data.content.location_id};
                        }
                        if(response.data.content.warranty_type_id != null){
                            self.warrantyTypeId = {text: response.data.content.warrantyName, value: response.data.content.warranty_type_id};
                        }
                        if(response.data.content.asset_id != null){
                            self.assetId = {text: response.data.content.vinNo, value: response.data.content.asset_id};
                        }
                        if(response.data.content.customer_display != null){
                            self.customerDisplayId = {text: response.data.content.customer_display.charAt(0).toUpperCase() + response.data.content.customer_display.slice(1), value: response.data.content.customer_display};
                        }
                        if(response.data.content.include_salesman != null){
                            self.includeSalesmanId = {text: response.data.content.include_salesman.charAt(0).toUpperCase() + response.data.content.include_salesman.slice(1), value: response.data.content.include_salesman};
                        }
                        self.leadQuality = {text: response.data.content.lead_quality_name, value: response.data.content.lead_quality};
                        self.saleType = {text: response.data.content.status_type_name, value: response.data.content.sale_type};
                        self.fundingSource = {text: response.data.content.funding_source_name, value: response.data.content.funding_source};
                        self.fundingMethod = {text: response.data.content.funding_method_name, value: response.data.content.funding_method};
                        if(response.data.content.funding_status != null){
                            self.fundingStatus = {text: response.data.content.funding_status_name, value: response.data.content.funding_status};
                        }
                        self.downPayment = response.data.content.down_payment;
                        self.depositReceived = response.data.content.deposite_received;
                        self.fundingRate = response.data.content.funding_rate;
                        self.tradeDetail = response.data.content.trade_detail;
                        self.approxTradeValue = response.data.content.approx_trade_value;
                        if(response.data.content.include_salesman != null){
                            self.source = response.data.content.source;
                            self.source = {text: response.data.content.source_name, value: response.data.content.source};
                        }
                        self.internalNote = response.data.content.internal_notes;
                        self.invoiceNote = response.data.content.invoice_note;
                        self.depositDate = response.data.content.deposite_date;
                        self.invoiceDate = response.data.content.invoice_date;
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
            },
            checkForm: function () {
                var checked = true;
                
                if (self.assetId.length == 0) {
                    self.assetError = "Please select Inventory";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.customerDisplayId == null || !self.customerDisplayId.value) {
                    self.customerDisplayError = "Please select customer display";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.includeSalesmanId == null || !self.includeSalesmanId.value) {
                    self.includeSalesmanError = "Please select include salesman";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.leadQuality == null || !self.leadQuality.value) {
                    self.leadQualityError = "Please enter lead quality";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.invoiceDate) {
                    self.invoiceDateError = "Please enter invoice date";
                    if (checked) {
                        checked = false;
                    }
                }
                /*if (!self.depositDate) {
                    self.depositDateError = "Please enter deposit date";
                    if (checked) {
                        checked = false;
                    }
                }*/
                if (self.saleType == null || !self.saleType.value) {
                    self.saleTypeError = "Please enter sale type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.fundingSource == null || !self.fundingSource.value) {
                    self.fundingSourceError = "Please enter funding source";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.fundingMethod == null || !self.fundingMethod.value) {
                    self.fundingMethodError = "Please enter funding method";
                    if (checked) {
                        checked = false;
                    }
                }
                /*if (!self.downPayment) {
                    self.downPaymentError = "Please enter down payment";
                    if (checked) {
                        checked = false;
                    }
                }*/

                /*if (!self.depositReceived) {
                    self.depositReceivedError = "Please enter deposit received";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (!self.fundingRate) {
                    self.fundingRateError = "Please enter funding rate";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (!self.tradeDetail) {
                    self.tradeDetailError = "Please enter trade details";
                    if (checked) {
                        checked = false;
                    }
                }*/
                /*if (!self.approxTradeValue) {
                    self.approxTradeValueError = "Please enter approx trade value";
                    if (checked) {
                        checked = false;
                    }
                }*/
                if (self.source == null || !self.source.value) {
                    self.sourceError = "Please enter source";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.salesmanId == null || !self.salesmanId.value) {
                    self.salesmanError = "Please select salesman";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.leadId == null || !self.leadId.value) {
                    self.leadIdError = "Please select lead";
                    if (checked) {
                        checked = false;
                    }
                }
                /*if (self.lenderId == null || !self.lenderId.value) {
                    self.lenderIdError = "Please select lead";
                    if (checked) {
                        checked = false;
                    }
                }*/
                return checked;
            },
            addOpportunity(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var leadId = "";
                        if (self.leadId == null || !self.leadId.value) {
                            leadId = "";
                        }else{
                            leadId = self.leadId.value;
                        }
                        var lenderId = "";
                        if (self.lenderId == null || !self.lenderId.value) {
                            lenderId = "";
                        }else{
                            lenderId = self.lenderId.value;
                        }
                        var locationId = "";
                        if (self.locationId == null || !self.locationId.value) {
                            locationId = "";
                        }else{
                            locationId = self.locationId.value;
                        }
                        var warrantyTypeId = "";
                        if (self.warrantyTypeId == null || !self.warrantyTypeId.value) {
                            warrantyTypeId = "";
                        }else{
                            warrantyTypeId = self.warrantyTypeId.value;
                        }
                        var fundingStatus = "";
                        if (self.fundingStatus == null || !self.fundingStatus.value) {
                            fundingStatus = "";
                        }else{
                            fundingStatus = self.fundingStatus.value;
                        }
                        var data = new FormData();
                        if (self.isEdit) {
                            data.append('id',self.isEdit);
                        }
                        let assetIdArr = [];
                        assetIdArr.push(self.assetId['value']);
                        data.append('salesman_id', self.salesmanId.value);
                        data.append('lead_id', leadId);
                        data.append('internal_lender_id', lenderId);
                        data.append('asset_id', assetIdArr);
                        data.append('lead_quality', self.leadQuality.value);
                        data.append('sale_type', self.saleType.value);
                        data.append('funding_source', self.fundingSource.value);
                        data.append('funding_method', self.fundingMethod.value);
                        data.append('funding_status', fundingStatus);
                        data.append('down_payment', self.downPayment);
                        data.append('deposit_received', self.depositReceived);
                        data.append('funding_rate', self.fundingRate);
                        data.append('trade_detail', self.tradeDetail);
                        data.append('approx_trade_value', self.approxTradeValue);
                        data.append('source', self.source.value);
                        data.append('internal_note', self.internalNote);
                        data.append('invoice_note', self.invoiceNote);
                        data.append('customer_display', self.customerDisplayId.value);
                        data.append('include_salesman', self.includeSalesmanId.value);
                        data.append('deposit_date', self.depositDate);
                        data.append('invoice_date', self.invoiceDate);
                        data.append('warranty_type_id', warrantyTypeId);
                        data.append('location_id', locationId);
                        self.disableButtons.save = true;
                        HTTP.post("/invopportunity/add/update", data, config)
                            .then(function (response) {
                                self.buttonNames.save = "Submit";
                                if (response.data.status == "success") {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.message,'success',false,3);
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.$parent.chagneComponent(0);
                                    }, 3000); 
                                } else {
                                    self.disableButtons.save = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.save = "Submit";
                                self.disableButtons.save = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
        },
    }
</script>