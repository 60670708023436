<template>
    <div class="loginbg" id="login-main-hr">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>This is Test Environment !</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="loginform">
                        <div class="loginlogo">
                            <img src="static/images/login-logo.png" alt="Velocity Vehicle Group">
                        </div>
                        <form v-if="isRedirection === 'true'">
                            <label class="checkbox-inline checkbox-bootstrap checkbox-lg">
                                Logout from (<a v-bind:href="ssoDomainUrl">{{ssoDomainUrl}}</a>),If You are not able to login please clear Cookies and login again.
                                Please contact admin if you are still not able to Login.
                            </label>
                        </form>
                        <form v-else>
                            <div class="form-group">
                                <label v-if="isEmail == 'undefined'" for="exampleInputEmail1">Dayforce ID / Email</label>
                                <label v-else-if="isEmail == true" for="exampleInputEmail1">Email</label>
                                <label v-else for="exampleInputEmail1">Dayforce ID</label>
                                <input v-model="username" type="text" name="username" class="form-control" id="username_login" placeholder="Enter Username">
                                <span class="help is-danger">{{username_error_msg}}</span>
                            </div>

                            <div class="form-group">
                                <label id="login-password-label" for="exampleInputPassword1">Password</label>
                                <input v-model="password" type="password" name="password" class="form-control" id="password_login" placeholder="Enter password">
                                <span class="help is-danger">{{password_error_msg}}</span>
                            </div>

                            <div class="form-check not-registered">
                                <input type="checkbox">
                                <span class="checkbox-placeholder"></span>
                                Remember me
                                <a :href="not_registered_url" target="_blank">Not Registered?</a>
                            </div>
                            <div class="help is-danger">{{error_msg_login}}</div>
                            <div>
                                <button :disabled="disableButtons.loginButton" id="loginbtn" type="submit" class="btn btn-primary" v-on:click.prevent="login(this)">{{buttonNames.loginButton}}</button>
                                <a :href="forgot_pass_url" target="_blank">Forgot password?</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {HTTP} from '../../http-common';
    import moment from 'moment';
    var self;

    export default {
        name: 'Login',
        data() {
            return {
                isEmail: "undefined",
                username: "",
                password: "",
                not_registered_url: "",
                username_error_msg: "",
                password_error_msg: "",
                forgot_pass_url: "",
                buttonNames: {
                    loginButton: 'Login',
                },
                disableButtons: {
                    loginButton: false,
                },
                error_msg_login: "",
                isRedirection: null,
                browserTimeZone : "",
                redirect_url : "",
                currentEnvironment: false,
            }
        },
        watch: {
            username: function (username) {
                if (username == '') {
                    self.isEmail = 'undefined';
                } else {
                    self.isEmail = self.validEmail(username);
                }
            },
        },
        created: function () {
        },
        beforeMount: function () {
            self = this;
            self.ssoDomainUrl = process.env.VUE_APP_SSO_ROOT_URL;
            self.isRedirection = process.env.VUE_APP_IS_REDIRECTION;

            if (self.isRedirection === 'true') {
                if (typeof localStorage.getItem("redirect_url") !== 'undefined' && localStorage.getItem("redirect_url") != 'null' && localStorage.getItem("redirect_url") != '') {
                  self.redirect_url = localStorage.getItem("redirect_url");
                }

                if(self.$route.query.redirect == "false") {
                    // DO NOTHING
                } else {
                    if(self.$route.query.token) {
                        window.localStorage.setItem("api_token", self.$route.query.token);
                        window.localStorage.setItem("username", self.$route.query.username);
                        self.autoLoginUser(self.$route.query.username);
                    } else {
                        var api_token = localStorage.getItem("api_token");
                        if(api_token == null) {
                            $(".loginbg").show();
                            window.location.href = process.env.VUE_APP_SSO_ROOT_URL + "/login?redirect=" + process.env.VUE_APP_FRONT_ROOT_URL + '&slug=' + process.env.VUE_APP_PROJECT_SLUG;
                        } else {
                            self.$router.push(localStorage.getItem("redirect_url"));
                        }
                    }
                }
            }
        },
        mounted: function () {
            if (process.env.VUE_APP_NODE_ENV == 'production') {
                self.currentEnvironment = false;
            } else {
                self.currentEnvironment = true;
            }

            if (self.isRedirection === 'true') {
                self.forgot_pass_url = process.env.VUE_APP_HR_URL + "forgotpassword?identify=" + window.location.href;
                self.register_url = process.env.VUE_APP_HR_URL + "register?identify=" + window.location.href;
                self.username = self.getCookie('sales_username');
                self.password = self.getCookie('sales_password');
                var userObj = localStorage.getItem('user_obj');
                if (userObj != null) {
                    self.$router.push(localStorage.getItem("redirect_url"));
                } else {
                    $(".loginbg").show();
                }
                $(document).ready(function () {
                    $('#rememberMe').change(function () {
                        if (self.checked) {
                            self.remember_me = true;
                        } else {
                            self.remember_me = false;
                        }
                    });
                });
            } else {
                HTTP.get('/buildversion', {})
                    .then(response => {
                        /* Build version checking start*/
                        let current_build = window.localStorage.getItem("build_version");
                        let latest_build = response.data.content.build;
                        if(current_build == null || current_build == 'null') {
                            localStorage.setItem("build_version", response.data.content.build);
                        } else if (current_build != undefined && current_build == '') {
                            localStorage.setItem("build_version", response.data.content.build);
                        } else if(current_build != latest_build){
                            localStorage.setItem("build_version", response.data.content.build);
                            location.reload(true);
                        }
                        /* Build version checking end*/
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                    });
                    self.$nextTick(() => {
                        self.checkLocalStorage();
                    });
            }
            self.browserTimeZone = moment.tz.guess();
        },
        methods: {
            validEmail : function (email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            setCookie: function (cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            getCookie: function (cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            autoLoginUser: function (username) {
                var self = this;
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")}
                };
                var fields = {
                    'username': username,
                    'access_token': window.localStorage.getItem('api_token')
                };
                HTTP.post("autologinuser", fields)
                    .then(function (data) {
                        if (data.status == 200) {
                            // localStorage.setItem("api_token", data.data.content.result.api_token);
                            // localStorage.setItem("build_version", data.data.content.build);
                            /* Build version checking start*/
                            let current_build = window.localStorage.getItem("build_version");
                            let latest_build = data.data.content.build;
                            if (current_build != undefined && current_build == '') {
                                localStorage.setItem("build_version", data.data.content.build);
                            } else if(current_build != latest_build){
                                localStorage.setItem("build_version", data.data.content.build);
                                location.reload(true);
                            }
                            /* Build version checking end*/
                            localStorage.setItem("user_obj", JSON.stringify(data.data.content.user_obj));
                            localStorage.setItem("roleId", JSON.stringify(data.data.content.roles[0].id));
                            localStorage.setItem('browserTimeZone', self.browserTimeZone);
                            localStorage.setItem('redirect_url', data.data.content.result.redirect_url);
                            $('.navbar').show();
                            $('#sidebar-wrapper').show();
                            localStorage.setItem("menu_display", "small");

                            // self.$router.push(data.data.content.result.redirect_url);
                            if(typeof self.redirect_url !== 'undefined' && self.redirect_url != null && self.redirect_url != '') {
                              // localStorage.setItem('redirect_url', self.redirect_url);
                              self.$router.push(self.redirect_url);

                              // localStorage.setItem('redirect_url', data.data.content.result.redirect_url);
                            } else {
                              localStorage.setItem('redirect_url', data.data.content.result.redirect_url);
                            self.$router.push(data.data.content.result.redirect_url);
                            }

                            self.getLoginIdPermissions();
                        }
                    })
                    .catch(function (error) {
                        self.login_err_msg = error.response.data.message;
                    })
            },
            login: function () {
                var error = false;
                self.username_error_msg = '';
                self.password_error_msg = '';

                if (!self.username || self.username == null || self.username == "") {
                    self.username_error_msg = "The username field is required.";
                    error = true;
                }

                if (!self.password || self.password == null || self.password == "") {
                    self.password_error_msg = "The password field is required.";
                    error = true;
                }

                if (!error) {
                    self.buttonNames.loginButton = "Login...";
                    self.disableButtons.loginButton = true;
                    /*$('#loginbtn').text("Login...");
                     $("#loginbtn").attr("disabled", true);*/
                    let redirect_url = null;
                    if (self.$route.query && self.$route.query.redirect) {
                        redirect_url = self.$route.query.redirect
                    }

                    var fields = {
                        email: self.username,
                        password: self.password,
                        redirect_url: redirect_url
                    };

                    HTTP.post("login", fields)
                            .then(function (data) {
                                self.buttonNames.loginButton = "Login";
                                self.disableButtons.loginButton = false;

                                if (data.status == 200) {
                                    //localStorage.setItem("expiry", moment(new Date()).add(720, 'h').toDate());
                                    localStorage.setItem("api_token", data.data.content.result.api_token);
                                    localStorage.setItem("roleId", JSON.stringify(data.data.content.user_obj.roles[0].id));
                                    localStorage.setItem("username", JSON.stringify(data.data.content.user_obj.username));
                                    localStorage.setItem("user_obj", JSON.stringify(data.data.content.user_obj));
                                    if(typeof(data.data.content.result.export_headers) != "undefined" && data.data.content.result.export_headers != null && data.data.content.result.export_headers != '') {
                                        localStorage.setItem("export_headers", data.data.content.result.export_headers);
                                    }
                                    if(typeof(data.data.content.result.list_headers) != "undefined" && data.data.content.result.list_headers != null && data.data.content.result.list_headers != '') {
                                        localStorage.setItem("list_headers", data.data.content.result.list_headers);
                                    }
                                    localStorage.setItem("menu_display", "small");
                                    if (data.data.content.result && data.data.content.result.redirect_url) {
                                        self.$router.push(data.data.content.result.redirect_url);
                                    } else {
                                        self.$router.push('/inventory/list');
                                    }
                                    /* self.$router.push('/inventory/list/onorder'); */
                                    $('.navbar').show();
                                    $('#sidebar-wrapper').show();
                                    // window.location.href = "/inventory/list";
                                    // window.location.href = "/inventory/list/onorder";
                                    localStorage.setItem('browserTimeZone', self.browserTimeZone);
                                }
                                if (data.status == 201) {
                                    self.error_msg_login = data.data.content;
                                }
                            })
                            .catch(function (error) {
                                self.error_msg_login = "";
                                self.buttonNames.loginButton = "Login";
                                self.disableButtons.loginButton = false;
                                if (error && error.response && error.response.status == 0) {
                                    self.error_msg_login = "Remote server can not be reachable";
                                } else {
                                    if (error.response && error.response && error.response.data && error.response.data.message) {
                                        self.error_msg_login = error.response.data.message;
                                    }
                                }
                            })
                }
            },
            checkLocalStorage: function () {
                if (localStorage.getItem('user_obj')) {
                    var userObj = localStorage.getItem('user_obj');
                    if (userObj != null && userObj !== "") {
                        if (self.$route.query && self.$route.query.redirect) {
                            self.$router.push(self.$route.query.redirect);
                        } else if (localStorage.getItem("redirect_url") != null) {
                            self.$router.push(localStorage.getItem("redirect_url"));
                        } else{
                            self.$router.push('/inventory/list');
                        }
                        /* self.$router.push('/inventory/list/onorder'); */
                    } else {
                        self.$router.push('/');
                    }
                } else {
                    /* var str = process.env.VUE_APP_ROOT_API;
                    self.forgot_pass_url = str.replace("/api/v1", "") + "/forgotpassword"; */
                    self.forgot_pass_url = process.env.VUE_APP_HR_URL + "?identify=" + window.location.href;
                    /* self.forgot_pass_url = "/forgotpassword"; */
                    self.not_registered_url = process.env.VUE_APP_NOT_REGISTERED_HR_URL + "?identify=" + window.location.href;
                    /* $('#main-header').hide();
                     $('#sidebar-wrapper').hide();
                     $("#wrapper").addClass("login-div-full");
                     $(".loginbg").show(); */

                    $('.navbar').hide();
                    // $('.admin-footer').hide();
                    $('#sidebar-wrapper').hide();
                    if (window.location.pathname == "/") {
                        $(".app-sidebar-right").css("padding-left", "0px");
                        $(".app-sidebar-right").css("margin-top", "0px");
                        $(".app-sidebar-right").css({"-webkit-transition": "all 0.0s ease", "-moz-transition": "all 0.0s ease", "-o-transition": "all 0.0s ease", "transition": "all 0.0s ease"});
                        $(".loginbg").show();
                    }
                }
            },
        }
    }
</script>
