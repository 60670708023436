<template>
  <div class="modal" id="mailModal" tabindex="-1" role="dialog">
    <div
      v-if="isClickMail"
      class="modal-dialog modal-lg modal-dialog-centered"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header trns-mail-modal-row">
          <div>
            Mail
          </div>
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body trns-mail-modal-body">
          <div class="card mb-1">
            <div class="row">
              <div class="col-md-3 trns-mail-label">
                From:
              </div>
              <div class="col-md-9">
                <input type="text" class="trns-mail-form-input" v-model="salePersonName" disabled>
              </div>
            </div>
          </div>
          <div class="card mb-1 vtc-dealer-forms">
            <div class="row">
              <div class="col-md-3 trns-mail-label">
                To:
              </div>
              <div class="col-md-9">
                <v-select
                  v-model="selected"
                  multiple
                  placeholder="Search & Select"
                  label="text"
                  :options="userArr"
                  @input="toError = ''"
                  id="trns-mail-mail-to"
                  class="dropdown v-select searchable trns-mail-mail-to"
                  @search="onSearchUser"
                >
                  <template #option="{ text, email }">
                    {{ text }}
                    <br />
                    <small class="text-secondary">{{ email }}</small>
                  </template>
                  <span slot="no-options" @click="$refs.select.open = false">
                    <template v-if="!isUserRecordNotFound">
                      Search User...
                    </template>
                    <template v-else>
                      Sorry, no matching record found!
                    </template>
                  </span>
                </v-select>
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ toError }}</span>
              </div>
            </div>
          </div>
          <div class="card mb-1" v-if="isStatusList">
            <div class="row">
              <div class="col-md-3 trns-mail-label"></div>
              <div class="col-md-9">
                <span v-for="(dealStatus, index) in dealLevelList" :key="dealStatus.id">
                  <div class="deal-level-list" @mouseenter="hoverEffect(index)" @mouseleave="hoverEffect(null)">
                    <div>{{ dealStatus.name }}</div>
                    <button v-if="displayButtonIndex==index" @click="listAssignedTo(dealStatus.id)">+ Add</button>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="card mb-1">
            <div class="row">
              <div class="col-md-3 trns-mail-label">
                Subject:
              </div>
              <div class="col-md-9">
                <input type="text" class="trns-mail-form-input" v-model="subject"  @input="subjectError = ''" />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ subjectError }}</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row">
              <div class="col-md-12">
                <tinymce ref="tinymceEditor" id="deals-trns-mail-request" v-model="description" @editorInit="handleEditorInit"></tinymce>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="trns-mail-save-button" @click="sendMail()">Send</button>
          <button type="button" class="trns-mail-close-button" @click="closeModal()">Close</button>
        </div>
      </div>
      <div class="page-loader-wrapper loading-container" v-if="sendingMail">
        <div class="loading">
          <img src="/static/images/loading.gif" alt="loader" />
        </div>
      </div>
    </div>
    <div class="page-loader-wrapper loading-container" v-if="mailLoader">
      <div class="loading">
        <img src="/static/images/loading.gif" alt="loader" />
      </div>
    </div>
  </div>
</template>
  
<script>
  import eventBus from "../../../../event-bus";
  import { commonMixins } from "@/mixins/commonMixins";
  import { HTTP } from "../../../../../http-common";
  import Loader from "../Loader.vue";
  import tinymce from 'vue-tinymce-editor';
  
  export default {
    name: "MailModal",
    props: {
      isClickMail: false,
      id: "",
      mailDescription: "",
      locationId: "",
      dealStatusSlug: "",
      trnsMail: true,
      dealNo: "",
      excedePurId: null,
      vehicleCount: 0,
      toError: "",
      subjectError: ""
    },
    components: {
      'loader': Loader,
      'tinymce': tinymce,
    },
    mixins: [commonMixins],
    data() {
      return {
        sendingMail: false,
        loader: false,
        salePersonId: '',
        salePersonName: '',
        subject: 'PO Request',
        description: '',
        selected: [],
        userArr: [],
        isUserRecordNotFound: false,
        dealLevelList: [],
        displayButtonIndex: null,
        isStatusList: false,
        mailLoader: false,
        totalVehicle: 0
      };
    },
    methods: {
      closeModal(isMailSend=false) {
        this.selected = [];
        this.subject = 'PO Request';
        eventBus.$emit("close-mail-modal", isMailSend);
      },
      checkForm() {
        var isValid  = true;
        if (this.selected.length == 0) {
          this.toError = "Please select a recipient";
          if (isValid) {
            isValid  = false;
          }
        }

        if (!this.subject) {
          this.subjectError = "Please enter subject";
          if (isValid) {
            isValid  = false;
          }
        }

        return isValid ;
      },
      sendMail() {
      if (this.checkForm()) {
        this.sendingMail = true;
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        const data = {
          id: this.id,
          data: this.selected,
          subject: this.subject,
          description: this.description,
          requestType: this.dealStatusSlug,
          currentURL: window.location.href,
        };

        if(this.trnsMail) {
          HTTP.post("/deals-level", data, config)
          .then((response) => {
            this.mailLoader = false;
            this.sendingMail = false;
            this.showSAlert(
              response.data.message,
              response.data.status,
              false,
              2
            );
            this.closeModal(true);
          })
          .catch((err) => {
            this.sendingMail = false;
            this.catchHandler(err, function () {});
          });
        } else {
          data.deal_no = this.dealNo;
          data.ex_pur_id = this.excedePurId;
          HTTP.post("/excede-notification", data, config)
          .then((response) => {
            this.mailLoader = false;
            this.sendingMail = false;
            this.showSAlert(
              response.data.message,
              response.data.status,
              false,
              2
            );
            this.closeModal();
          })
          .catch((err) => {
            this.catchHandler(err, function () {});
          }); 
        }
      }
      },
      handleEditorInit(editor) {
        // Set content when the editor is initialized
        editor.setContent(this.description);
        // editor.contentStyle = 'body { font-size: 5px; }';
      },
      onSearchUser(search, loading) {
        if (search.length > 2) {
          loading(true);
          this.loadAllData(
            ["User"],
            true,
            this.loadUserTypeCallback,
            loading,
            search
          );
        }
      },
      loadUserTypeCallback: function (callbackData) {
        this.isUserRecordNotFound = false;
        this.userArr = callbackData.User;
        if (this.userArr.length == 0) {
          this.isUserRecordNotFound = true;
        }
      },
      listDealStatus() {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };

        HTTP.get("/deals-level/list", config)
        .then((response) => {
          this.dealLevelList = response.data.content;
        })
        .catch((err) => {
          this.catchHandler(err, function () {});
        });
      },
      hoverEffect(index) {
        this.displayButtonIndex = index;
      },
      listAssignedTo(dealStatusSlug) {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
          params: {
            location_id: this.locationId.value,
            deal_level_slug: dealStatusSlug
          }
        };

        HTTP.get("/location/contact/users", config)
        .then((response) => {
            this.mailLoader = false;
            const selectedList = this.selected;
            const updatedList = response.data.content;
            // if(updatedList.length == 0) {
            //   this.showSAlert("No Data", "info", false, 2);
            // } else {
              const concatSelectedList = [...selectedList,...updatedList]
              this.selected = concatSelectedList;
            // }
        })
        .catch((err) => {
          this.catchHandler(err, function () {});
        });
      }
    },
    watch: {
      isClickMail(status) {
        if(status) {
          const user = JSON.parse(localStorage.getItem("user_obj"));
          this.salePersonName = user["first_name"] + " " + user["last_name"];
          this.salePersonId = user["id"];
          this.description = this.mailDescription;
          this.mailLoader = true;
          const dealStatus = this.dealStatusSlug.replace(/(?:^|-)(\w)/g, (match) => match.toUpperCase()).replace(/-/g, ' ');
          const totalVehicle = this.vehicleCount > 1 ? (", total of "+this.vehicleCount+" vehicles") : "";
          this.subject = this.trnsMail ? dealStatus+totalVehicle : "PO Excede Created";
          this.listAssignedTo(this.dealStatusSlug);
        }
      }
    }
  };
</script>
  
<style>
  .trns-mail-modal-row > div:first-child {
    display: flex;
    align-items: center;
  }
  
  .trns-mail-modal-row > div:last-child {
    display: flex;
    justify-content: flex-end;
  }
  
  .trns-mail-modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
  
  .trns-mail-form-input {
    height: 32px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cecece;
  }

  .trns-mail-btn {
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;

    display: inline-flex;
    height: 30px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 1px;
    flex-shrink: 0;
    border-radius: 20px;
    background: linear-gradient(174deg, #076eb0 0%, rgba(7, 110, 176, 0.57) 100%);
    color: #fff;
    text-align: center;
    font-family: "SF UI Display", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1px;
    width: -webkit-fill-available;
  }

  .trns-mail-btn-2 {
    width: unset;
  }

  .trns-mail-close-button {
    width: 70px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #dbe0e7;
    background-color: #ffffff;
    margin-left: auto;

    /* text */
    color: #263238;
    text-align: center;
    font-family: "SF UI Display", Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;
  }

  .trns-mail-save-button, .deal-level-list > button {
    width: 70px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 6px;
    border: none;
    outline: none;
    background-color: #076eb0;
    margin-left: auto;

    /* text */
    color: #fff;
    text-align: center;
    font-family: "SF UI Display", Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.84px;
  }

  .trns-mail-label {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  #trns-mail-mail-to {
    width: 100%;
  }

  .deal-level-list {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .deal-level-list:hover {
    background-color: rgb(128, 128, 128);
    color: #fff;
  }
</style>
  