<template>
    <div class="container" id="printDocOptionsPopup">
        <div v-bind:style="{ zIndex: parentModalShowHide }" class="modal fade" id="printDocOptionsPopupModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="dialog">
                <div class="modal-content">
                    <div class="modal-header" style="padding-bottom: 3px;padding-top: 5px;">
                        <div class="pl-1">
                            <!-- <h4 class="modal-title" id="columnsModalLabel">Reports</h4> -->
                        </div>
                        <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close" style="background: transparent;border: 0;">
                            <i class="fa fa-close"></i>
                        </button>
                    </div>
                    <div class="modal-body pt-0">
                        <div style="padding-bottom: 15px;" class="sticky-header">
                            <div class="row">
                                <div class="col-3">
                                    <button type="button" class="btn btn-primary waves-effect" v-on:click.prevent="selectAll()" style="min-width: 110px;">Select All</button>
                                </div>
                                <div class="col-3">
                                    <button type="button" class="btn btn-primary waves-effect" v-on:click.prevent="selectNone()" style="min-width: 110px;">Select None</button>
                                </div>
                                <div class="col-3">
                                    <button id="print_button" type="button" style="min-width: 110px;" v-on:click.prevent="buttonPrint()" class="btn btn-primary waves-effect">Print</button>
                                </div>
                                <div class="col-3">
                                    <button type="button" style="min-width: 110px;" class="btn btn-primary waves-effect modal-close" data-dismiss="modal" aria-label="Close">Close</button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row" style="padding-bottom: 7px;padding-left: 15px;">
                            <span>Select vehicle(s) you want to print documents for :</span>
                        </div>
                        <div v-for="index in totalDivForVehicles" :key="index">
                            <!-- <div v-for="(e,i) in 5" :key="i" class="custom-checkbox-v" style="width:20%;display:inline-block;position: relative;">
                                <label v-if="vehicles[((index-1) * 5)+i]" :for="'vehicle' + (((index-1) * 5)+i)" class="container">
                                    <div style="display: inline-block;padding-top: 6px;">{{ vehicles[((index-1) * 5)+i]['name'] }}</div>
                                    <input value="true" :id="'vehicle' + (((index-1) * 5)+i)" type="checkbox" class="form-control"> 
                                    <span class="checkmark"></span>
                                </label>
                            </div> -->
                            <div v-for="(e,i) in 5" :key="i" class="checkbox-container-group" style="width:20%;display:inline-block;position: relative;">
                                <div v-if="vehicles[((index-1) * 5)+i]">
                                    <input value="true" :id="'vehicle' + (((index-1) * 5)+i)" type="checkbox"> 
                                    <label :for="'vehicle' + (((index-1) * 5)+i)" class="checkbox_label">{{ vehicles[((index-1) * 5)+i]['name'] }}</label>
                                </div>
                            </div>
                        </div>
                            <!-- <div v-for="(vehicle,index) in vehicles" :key="index" class="custom-checkbox-v" style="width:20%;display:inline-block;position: relative;">
                                <label :for="'vehicle' + index" class="container">
                                    <div style="display: inline-block;padding-top: 6px;">{{ vehicle }}</div>
                                    <input value="true" :id="'vehicle' + index" type="checkbox" class="form-control"> 
                                    <span class="checkmark"></span>
                                </label>
                            </div> -->

                        <div class="row" style="padding-top: 20px;padding-bottom: 20px;">
                            <div class="col-3">
                                <span style="padding-right: 6px;">Form Groups:</span>
                                <div class="paddingleft0" style="display: inline-block;width: 60%;">
                                    <v-select ref="select" title="Select Form Group"
                                                name="form_group"
                                                v-model="form_group"
                                                id="form_group"
                                                label="text"
                                                :clearable="false"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="formGroupOptions"
                                                placeholder="Select Form Group">
                                    </v-select>
                                </div>
                            </div>
                            <!-- <div class="" style="max-width: 19%;padding-left:5px;">
                                <input @input="keepSelectedForms()" type="checkbox" id="keep_selected_forms">
                                <label class="">Keep Selected Forms</label>
                            </div> -->
                            <div class="col-3 checkbox-container-group">
                                <input type="checkbox" id="show_selected_forms" @input="showSelectedForms()"/>
                                <label for="show_selected_forms" class="checkbox_label">Show Selected Forms Only</label>
                            </div>
                            <div class="col-3 checkbox-container-group">
                                <input type="checkbox" id="show_printed_forms" @input="showPrintedForms()"/>
                                <label for="show_printed_forms" class="checkbox_label">Show Printed Forms Only</label>
                            </div>
                            <div class="col-3 checkbox-container-group">
                                <input type="checkbox" id="read_only_docs" @input="readOnlyDocs()"/>
                                <label for="read_only_docs" class="checkbox_label">Generate Read Only Documents</label>
                            </div>
                            <!-- <div class="col-3 custom-checkbox-v" style="padding-left:5px;">
                                <label class="container">
                                    <div style="display: inline-block;padding-top: 6px;">Show Selected Forms Only</div>
                                    <input id="show_selected_forms" type="checkbox" class="form-control" @input="showSelectedForms()"> 
                                    <span class="checkmark"></span>
                                </label>
                            </div> -->
                            <!-- <div class="col-3 custom-checkbox-v" style="padding-left:5px;">
                                <label class="container">
                                    <div style="display: inline-block;padding-top: 6px;">Show Printed Forms Only</div>
                                    <input id="show_printed_forms" type="checkbox" class="form-control" @input="showPrintedForms()"> 
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-3 custom-checkbox-v" style="padding-left:5px;">
                                <label class="container">
                                    <div style="display: inline-block;padding-top: 6px;">Generate Read Only Documents</div>
                                    <input id="read_only_docs" type="checkbox" class="form-control" @input="readOnlyDocs()"> 
                                    <span class="checkmark"></span>
                                </label>
                            </div> -->
                        </div>

                        <!-- TEMPLATE FEATURE BASED ON AUTH USER INORDER TO GET RID OF SCROLL & SELECT EVERYTIME ON OPENING THIS MODULE FOR EVERY DEAL OR SAME DEAL (CLIENT-RECORD-22FEB2024) -->
                        <div class="row pb-2" v-if="templateOptions.length>0 || templateCheckedList.length>0">
                            <div class="col-6">
                                <span style="padding-right: 6px;">Template:</span>
                                <div class="paddingleft0" style="display: inline-block;width: 60%;">
                                    <v-select ref="select" title="Select Form Group"
                                                name="form_group"
                                                v-model="selectedTemplate"
                                                id="form_group"
                                                label="name"
                                                :clearable="true"
                                                :searchable="false"
                                                :filterable="false"
                                                :options="templateOptions"
                                                @input="listFormTemplate(selectedTemplate)"
                                                placeholder="Select Template">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-6" id="prntDocTemplateBtn">
                                <div v-if="selectedTemplate">
                                    <button type="button" style="min-width: 110px;" class="btn btn-danger waves-effect modal-close" aria-label="Close" v-on:click="deleteTemplate(selectedTemplate)" title="Click to delete selected template">Delete Template</button>
                                </div>
                                <div v-if="selectedTemplate">
                                    <button type="button" style="min-width: 110px;" class="btn btn-primary waves-effect modal-close" aria-label="Close" v-on:click="openSaveTemplate('Update')" title="Click to update selected template">Update Template</button>
                                </div>
                                <div v-if="templateCheckedList.length>0">
                                    <button type="button" style="min-width: 110px;" class="btn btn-primary waves-effect modal-close" aria-label="Close" v-on:click="openSaveTemplate('Save')" title="Click to create new template">Save Template</button>
                                </div>
                            </div>
                        </div>
                        <!-- END -->

                        <div class="print-doc-options">
                            <div class="table-responsive">
                                <div class="table-report">
                                    <div class="report-head">
                                        <div class="thead" style="width:20%">REPORT TYPE</div>
                                        <div class="thead" style="width:10%">PRINT</div>
                                        <div class="thead" style="width:20%">DOCUMENT NAME</div>
                                        <div class="thead" style="width:30%">DOCUMENT DESCRIPTION</div>
                                        <div class="thead" style="width:10%">PRINTER</div>
                                        <div class="thead" style="width:10%">ROYALTY</div>
                                    </div>
                                    <div class="report-body">
                                        <template v-if="printOptionsArr && printOptionsArr.length > 0">
                                            <template v-if="showCheckboxChecked"> <!-- if (show selected forms) and (show printed forms) checked -->
                                                <div v-for="(option,index) in displayedPrintOptions" :key="index">
                                                    <div class="accordion-body">
                                                    <div class="table-row">
                                                        <div class="tcol" style="width:20%">{{option.report_type}}</div>
                                                        <template v-if="option.is_checked">
                                                            <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(option)" type="checkbox" checked :id="'print_checkbox' + option.id"></div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(option)" type="checkbox" :id="'print_checkbox' + option.id"></div>
                                                        </template>
                                                        <div class="tcol" style="width:20%">{{option.doc_name}}</div>
                                                        <div class="tcol" style="width:30%">{{option.doc_description}}</div>
                                                        <div class="tcol" style="width:10%">{{option.printer}}</div>
                                                        <div class="tcol" style="width:10%">{{option.royalty}}</div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else> <!-- based on form group filter -->
                                                <template v-if="form_group == 'All'">
                                                    <div v-for="(option,index) in printOptionsArr" :key="index">
                                                        <div class="accordion-header">
                                                            <div class="tcol" style="width:20%"><i class="fa fa-minus-square" :id="'toggle-accordion' + index" style="cursor:pointer;" @click="toggleBlock(index)"></i>  {{option.header.report_type}}</div>
                                                            <template v-if="option.header.is_checked">
                                                                <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(option.header)" type="checkbox" checked :id="'print_checkbox' + option.header.id"></div>
                                                            </template>
                                                            <template v-else>
                                                                <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(option.header)" type="checkbox" :id="'print_checkbox' + option.header.id"></div>
                                                            </template>
                                                            <div class="tcol" style="width:20%">{{option.header.doc_name}}</div>
                                                            <div class="tcol" style="width:30%">{{option.header.doc_description}}</div>
                                                            <div class="tcol" style="width:10%">{{option.header.printer}}</div>
                                                            <div class="tcol" style="width:10%">{{option.header.royalty}}</div>
                                                        </div>
                                                        <div class="accordion-body" :id="'accordion-body' + index">
                                                            <div v-for="(body,i) in option.body" :key="i">
                                                                <div class="table-row">
                                                                    <div class="tcol" style="width:20%">&nbsp;</div>
                                                                    <template v-if="body.is_checked">
                                                                        <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(body)" type="checkbox" checked :id="'print_checkbox' + body.id"></div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(body)" type="checkbox" :id="'print_checkbox' + body.id"></div>
                                                                    </template>
                                                                    <div class="tcol" style="width:20%">{{body.doc_name}}</div>
                                                                    <div class="tcol" style="width:30%">{{body.doc_description}}</div>
                                                                    <div class="tcol" style="width:10%">{{body.printer}}</div>
                                                                    <div class="tcol" style="width:10%">{{body.royalty}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div v-for="(option,index) in printOptionsArr" :key="index">
                                                        <template v-if="form_group == option.header.report_type">
                                                            <div class="accordion-header">
                                                                <div class="tcol" style="width:20%"><i class="fa fa-minus-square" :id="'toggle-accordion' + index" style="cursor:pointer;" @click="toggleBlock(index)"></i>  {{option.header.report_type}}</div>
                                                                <template v-if="option.header.is_checked">
                                                                    <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(option.header)" type="checkbox" checked :id="'print_checkbox' + option.header.id"></div>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(option.header)" type="checkbox" :id="'print_checkbox' + option.header.id"></div>
                                                                </template>
                                                                <div class="tcol" style="width:20%">{{option.header.doc_name}}</div>
                                                                <div class="tcol" style="width:30%">{{option.header.doc_description}}</div>
                                                                <div class="tcol" style="width:10%">{{option.header.printer}}</div>
                                                                <div class="tcol" style="width:10%">{{option.header.royalty}}</div>
                                                            </div>
                                                            <div class="accordion-body" :id="'accordion-body' + index">
                                                                <div v-for="(body,i) in option.body" :key="i">
                                                                    <div class="table-row">
                                                                        <div class="tcol" style="width:20%">&nbsp;</div>
                                                                        <template v-if="body.is_checked">
                                                                            <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(body)" type="checkbox" checked :id="'print_checkbox' + body.id"></div>
                                                                        </template>
                                                                        <template v-else>
                                                                            <div class="tcol" style="width:10%"><input @input="selectDeselectPdf(body)" type="checkbox" :id="'print_checkbox' + body.id"></div>
                                                                        </template>
                                                                        <div class="tcol" style="width:20%">{{body.doc_name}}</div>
                                                                        <div class="tcol" style="width:30%">{{body.doc_description}}</div>
                                                                        <div class="tcol" style="width:10%">{{body.printer}}</div>
                                                                        <div class="tcol" style="width:10%">{{body.royalty}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer no-border width-full pull-left">
                    </div>
                </div>
            </div>
        </div>
        <save-print-doc-template-modal v-bind:templateCheckedList="templateCheckedList" v-bind:isSaveTemplate="isSaveTemplate" v-bind:currentTemplate="selectedTemplate" v-bind:templateMethod="templateMethod"  />
    </div>
</template>
  
<script>
    var self;
    import EventBus from "../../event-bus";
    import {HTTP} from "../../../http-common";
    import {commonMixins} from "../../../mixins/commonMixins";
    import SavePrintDocTemplateModal from "./Modal/SavePrintDocTemplateModal.vue";

    export default {
        name: "PrintDocOption",
        components: {
            SavePrintDocTemplateModal
        },
        mixins: [commonMixins],
        data() {
            return {
                formGroupOptions:[],
                form_group:'All',
                // formGroupOptions:['CCTT-F&I','FAZ-F&I','NC-F&I','NC-Sales','Sales','TnAlKy-AL State','TnAlKy-Dealer','TnAlKy-KY State','TnAlKy-TN State','Used-Archive','All'],
                    // 'S-F&I':'S-F&I',
                    // 'F&I NV':'F&I NV',
                    // 'F&I':'F&I',
                    // 'Used':'Used',
                printOptionsArr:[],
                selectedPrintOptions:[],
                displayedPrintOptions:[],
                printedFormsList:[],
                showCheckboxChecked:false,
                read_only_docs_flag:false,
                dealId:'',
                vehicles:[],
                totalDivForVehicles:0,
                templateCheckedList: [],
                isSaveTemplate: false,
                selectedTemplate: null,
                templateOptions: [],
                parentModalShowHide: 1042,
                templateMethod: 'Save',
            }
        },
        computed:{
        },
        methods: {
            selectAll(){
                self.vehicles.forEach(function(vehicle,index){
                    $("#vehicle" + index).prop('checked',true);
                });
            },

            selectNone(){
                self.vehicles.forEach(function(vehicle,index){
                    $("#vehicle" + index).prop('checked',false);
                });
            },
            
            // keepSelectedForms(){
            // },

            selectCommonForms(){
                let commonForm = [];
                self.selectedPrintOptions.forEach(function(option,index){
                    self.printedFormsList.forEach(function(printedForm,i){
                        if(printedForm.id == option.id){
                            commonForm.push(printedForm);
                        }
                    });
                });
                return commonForm;
            },

            showSelectedForms(){
                if($("#show_selected_forms").prop('checked') == true){
                    self.showCheckboxChecked = true;
                    if($("#show_printed_forms").prop('checked') == true){
                        self.displayedPrintOptions = self.selectCommonForms();
                    }else{
                        self.displayedPrintOptions = self.selectedPrintOptions;
                    }
                }else{
                    if($("#show_printed_forms").prop('checked') == true){
                        self.displayedPrintOptions = self.printedFormsList;
                    }else{
                        self.showCheckboxChecked = false;
                        self.displayedPrintOptions = [];
                    }
                }
            },
            
            showPrintedForms(){
                if($("#show_printed_forms").prop('checked') == true){
                    self.showCheckboxChecked = true;
                    if($("#show_selected_forms").prop('checked') == true){
                        self.displayedPrintOptions = self.selectCommonForms();
                    }else{
                        self.displayedPrintOptions = self.printedFormsList;
                    }
                }else{
                    if($("#show_selected_forms").prop('checked') == true){
                        self.displayedPrintOptions = self.selectedPrintOptions;
                    }else{
                        self.showCheckboxChecked = false;
                        self.displayedPrintOptions = [];
                    }
                }
            },
            
            readOnlyDocs(){
                if($("#read_only_docs").prop('checked') == true){
                    self.read_only_docs_flag = true;
                }else{
                    self.read_only_docs_flag = false;
                }
            },

            selectDeselectPdf(doc){
                //save in printOptionsArr to display
                self.printOptionsArr.forEach(function(option,index){
                    if(option.header.id == doc.id){
                        if($("#print_checkbox" + doc.id).prop('checked') == true){
                            option.header.is_checked = true;
                        }else{
                            option.header.is_checked = false;
                        }
                    }
                    option.body.forEach(function(body_detail,index){
                        if(body_detail.id == doc.id){
                            if($("#print_checkbox" + doc.id).prop('checked') == true){
                                body_detail.is_checked = true;
                            }else{
                                body_detail.is_checked = false;
                            }
                        }
                    });
                });
                if($("#print_checkbox" + doc.id).prop('checked') == true){
                    self.selectedPrintOptions.push(doc);
                    doc.is_checked = true;
                    //save in printedList also
                    self.printedFormsList.forEach(function(printedForm,i){
                        if(printedForm.id == doc.id){
                            printedForm.is_checked = true;
                        }
                    });
                }else{
                    doc.is_checked = false;
                    self.selectedPrintOptions = self.selectedPrintOptions.filter(function(value, index, arr){ 
                        return value.id != doc.id;
                    });
                    //save in printedList also
                    self.printedFormsList.forEach(function(printedForm,i){
                        if(printedForm.id == doc.id){
                            printedForm.is_checked = false;
                        }
                    });
                }

                if(self.templateCheckedList.includes(doc.id) && !doc.is_checked) {
                    const filterTemplateCheckedList = self.templateCheckedList.filter(checkedListId =>  {return checkedListId !== doc.id});
                    self.templateCheckedList = filterTemplateCheckedList;
                } else {
                    self.templateCheckedList.push(doc.id);
                }
            },

            getAllPrintedForms(){
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        deal_id: self.dealId,
                    }
                };
                HTTP.get('/getAllPrintedForms', config)
                    .then(response => {
                        self.printedFormsList = response.data.content;
                    })
                    .catch(function (err) {
                        /* console.log(err); */
                        self.catchHandler(err, function () {});
                    });

            },
            
            buttonPrint(){
                let selectedVehicles = [];
                self.vehicles.forEach(function(vehicle,i){
                    if($("#vehicle" + i).prop('checked') == true){
                        selectedVehicles.push(vehicle.deal_inventory_mapper_id);
                    }
                });
                if(selectedVehicles.length == 0){
                    self.showSAlert("You need to select a vehicle before printing!", "error", false);
                    return;
                }
                if(self.selectedPrintOptions.length == 0){
                    self.showSAlert("You need to select a document first before printing!", "error", false);
                    return;
                }

                // self.selectedPrintOptions.forEach(function(option,index){
                    var config = {
                        headers: {
                            Authorization:"Bearer " + localStorage.getItem("api_token")
                        },
                        params : {
                            deal_id: self.dealId,
                            // doc_type:option,
                            selectedPrintOptions:JSON.stringify(self.selectedPrintOptions),
                            read_only_docs_flag:self.read_only_docs_flag,
                            vehicles:JSON.stringify(selectedVehicles),
                        }
                    };
                    $("#print_button").attr("disabled", true);

                    HTTP.get("/export_pdf", config)
                        .then(function(response) {
                            if (response.status == "200") {
                                if(response.data.code == "200"){
                                    self.getAllPrintedForms(); //refresh printedform list
                                    // window.open(process.env.ROOT_API + '/s3/' + response.data.content);
                                    window.open(response.data.content);
                                }else{
                                    console.log('err', response.data.message);
                                }
                            }
                        })
                        .catch(function(err) {
                            console.log('err', err)
                            if (err.response.status == 0) {
                                self.error = "Remote server can not be reachable";
                            } else {
                                self.error = err.response.data.message;
                            }
                        })
                        .finally(function() {
                            $("#print_button").attr("disabled", false);
                        });
                // });
            },

            toggleBlock(index){
                $("#accordion-body" + index).toggle();
                $("#toggle-accordion" + index).toggleClass('fa-plus-square fa-minus-square');
            },

            getPrintDocOptions(data=null){
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        // id: id
                    }
                };
                HTTP.get('/getPrintDocOptions', config)
                    .then(response => {
                        self.printOptionsArr = response.data.content;
                        self.formGroupOptions = response.data.formGroupOptions;
                        /** TEMPLATE DATA POPULATE */ 
                        self.templateOptions = response.data.userDealPrintDocTemplateList;
                        if(data!==null) { //THIS CONDITION ONLY APPLIED AFTER REDIRECTING FROM THE SAVE OR UPDATE MODAL OF TEMPLATE
                            self.$nextTick(() => {
                                const filteredTemplate = self.templateOptions.filter((template) => {return template.id==data.id});
                                self.selectedTemplate = filteredTemplate[0];
                                self.listFormTemplate(data.print_doc_options_id);
                            });
                        }
                        /** END */
                    })
                    .catch(function (err) {
                        /* console.log(err); */
                        self.catchHandler(err, function () {});
                    });
            },

            /** TEMPLATE METHODS */
                openSaveTemplate(type) {
                    self.parentModalShowHide = 1040;
                    self.isSaveTemplate = true;
                    self.templateMethod = type;
                    $("#savePrintDocTemplate").modal({ backdrop: "static", keyboard: false });
                },
                //TO CHECK AND UNCHECK THE LIST BASED ON SAVED OR UPDATED TEMPLATE
                listFormTemplate(options) {
                    const printDocOptionsId = options !== null ? JSON.parse(options.print_doc_options_id) : null;
                    let temp = [];
                    temp = self.printOptionsArr.map(function(option){
                        if(Object.keys(option).includes('header')) {
                            if(printDocOptionsId !== null && printDocOptionsId.includes(option.header.id)) {
                                option.header.is_checked = true;
                                self.selectedPrintOptions.push(option.header);
                            } else {
                                option.header.is_checked = false;
                            }
                        }
                        option.body.forEach(function(body_detail){
                            if(printDocOptionsId !== null && printDocOptionsId.includes(body_detail.id)) {
                                body_detail.is_checked = true;
                                self.selectedPrintOptions.push(body_detail);
                            } else {
                                body_detail.is_checked = false;
                            }
                        });
                        return option;
                    });
                    self.printOptionsArr = [];
                    self.$nextTick(() => {
                        self.printOptionsArr = temp;
                    });

                    if(options !== null) {
                        self.templateCheckedList = JSON.parse(options.print_doc_options_id);
                    } else {
                        self.templateCheckedList = [];
                        self.selectedPrintOptions = [];
                    }
                },
                //TO DELETE SELECTED TEMPLATE
                deleteTemplate(data) {
                    self.$swal({
                        title: '',
                        html: 'Are you sure that you want to delete <b>'+data.name+'</b> ?',
                        type: 'info',
                        confirmButtonText: 'OK',
                        showCancelButton: true,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                        if (result.value) {
                            let config = {
                                headers: {
                                    Authorization: "Bearer " + localStorage.getItem("api_token"),
                                },
                            };
                            HTTP.delete("deal/printdoc/template/"+data.id, config).then((response) => {
                                if(response.data.status === "success") {
                                    self.selectedTemplate = null;
                                    self.templateCheckedList = [];
                                    self.getPrintDocOptions();
                                }
                            })
                            .catch((err) => {
                                console.log("error=>", err);
                                this.catchHandler(err, function () {});
                            });
                        }
                    });
                }
            /** END TEMPLATE METHODS */
        },

        beforeMount() {
            self = this;
        },
        mounted() {
            self.getPrintDocOptions();
        },
    }
    EventBus.$on("print-doc-options-popup-open", function (data) {
        if (typeof self != "undefined") {
                $('#printDocOptionsPopupModal').modal('show');
                self.dealId = data.deal_id;
                self.vehicles = data.vehicles;
                self.totalDivForVehicles = 1; //to loop 1st round
                if(self.vehicles.length > 5){
                    self.totalDivForVehicles = Math.floor(self.vehicles.length / 5);
                    const remainder = self.vehicles.length % 5;
                    self.totalDivForVehicles = remainder ? (self.totalDivForVehicles += 1) : self.totalDivForVehicles;
                }
                self.displayedPrintOptions = [];
                self.showCheckboxChecked = false;
                self.getAllPrintedForms();
        }
    });

    EventBus.$on("close-print-doc-save-template", function (status, data=null) {
        if (typeof self != "undefined") {
            self.parentModalShowHide = "revert-layer";
            self.isSaveTemplate = status;
            $("#savePrintDocTemplate").modal("hide");
            /** TO POPULATE THE SAVED OR UPDATED DATA IN TEMPLATE SELECT FIELD */
            if(data!==null) {
                self.getPrintDocOptions(data);
            }
            /* END */
        }
    });
    
</script>

<style>
    .sticky-header{
        padding-bottom: 15px;
        position: sticky;
        top: 0;
        background-color: #fff;
        padding-top: 15px;
        z-index: 99;
    }
    .print-doc-options .table-report{
        /* width: 900px; */
        font-size: 14px;
        border: 1px solid;
        border-bottom: 0;
    }
    .table-report .report-head,
    .table-report .report-body .accordion-header,
    .table-report .report-body .accordion-body .table-row{
        display: flex;
    }
    .table-report .thead,
    .table-report .tcol{
        padding: 10px;
        border-bottom: 1px solid;
        border-left: 1px solid;
    }
    .table-report .thead:first-child,
    .table-report .tcol:first-child{
        border-left: 0;
    }
    .table-report .thead{
        background-color: #dedede;
    }
    .table-report .accordion-body .table-row:nth-child(odd){
        background-color: #f4f4f4;
    }
    .checkbox_label{
        padding-left: 5px;
        margin-top: 5px;
    }
    #printDocOptionsPopup .modal-body {
        max-height: calc(110vh - 200px);
        overflow-y: auto;
    }
    #prntDocTemplateBtn {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: end;
    }
    @media (max-width:823px){
        .print-doc-options .table-responsive{
            overflow-x: auto;
        }
    }
</style>
