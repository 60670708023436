<template>
  <div class="spaced-container">
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
      <!-- <template v-if="permissionNameArray.includes('can_view_inventory_master')"> -->
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <ul class="nav nav-tabs">
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_view_inventory_master')
                  "
                >
                  <a
                    v-on:click="activetab = 1"
                    v-bind:class="
                      'nav-link ' + [activetab === 1 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.view }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_core_inventory_master') ||
                      permissionNameArray.includes('can_view_inventory_master')
                  "
                >
                  <a
                    v-on:click="activetab = 2"
                    v-bind:class="{ 'nav-link': true, active: activetab === 2 }"
                    >{{ staticInputLabelMessages.core }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_specification_inventory_master'
                    ) ||
                      permissionNameArray.includes('can_view_inventory_master')
                  "
                >
                  <a
                    v-on:click="activetab = 3"
                    v-bind:class="
                      'nav-link ' + [activetab === 3 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.specifications }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_email_inventory_master')
                  "
                >
                  <a
                    v-on:click="activetab = 4"
                    v-bind:class="
                      'nav-link ' + [activetab === 4 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.email }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="permissionNameArray.includes('can_list_expense_master')"
                >
                  <a
                    v-on:click="activetab = 5"
                    v-bind:class="
                      'nav-link ' + [activetab === 5 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.expense }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_close_inventory_master')
                  "
                >
                  <a
                    v-on:click="activetab = 6"
                    v-bind:class="
                      'nav-link ' + [activetab === 6 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.close }}</a
                  >
                </li>
                <!--<li class="nav-item"  v-if="permissionNameArray.includes('can_commission_inventory_master')">
                                        <a v-on:click="activetab=7" v-bind:class="'nav-link ' + [ activetab === 7 ? 'active' : '' ]">{{staticInputLabelMessages.comm}}</a>
                                    </li>-->
                <!-- <li class="nav-item"  v-if="permissionNameArray.includes('can_list_opportunity_master')">
                                        <a v-on:click="activetab=8" v-bind:class="'nav-link ' + [ activetab === 8 ? 'active' : '' ]">{{staticInputLabelMessages.opps}}</a>
                                    </li> -->
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_crm_inventory_master') && permissionNameArray.includes('can_list_activity_master')
                  "
                >
                  <a
                    v-on:click="activetab = 9"
                    v-bind:class="{ 'nav-link': true, active: activetab === 9 }"
                    >{{ staticInputLabelMessages.crm }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_image_inventory_master')
                  "
                >
                  <a
                    v-on:click="activetab = 10"
                    v-bind:class="
                      'nav-link ' + [activetab === 10 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.inventory_image_label }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_360_image_inventory_master'
                    )
                  "
                >
                  <a
                    v-on:click="activetab = 11"
                    v-bind:class="
                      'nav-link ' + [activetab === 11 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.inventory_image_360_label }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes('can_list_repair_order_master')
                  "
                >
                  <a
                    v-on:click="activetab = 12"
                    v-bind:class="
                      'nav-link ' + [activetab === 12 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.repair_orders_label }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_private_file_list_inventory_master'
                    ) ||
                      permissionNameArray.includes(
                        'can_public_file_list_inventory_master'
                      )
                  "
                >
                  <a
                    v-on:click="activetab = 13"
                    v-bind:class="
                      'nav-link ' + [activetab === 13 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.asset_files }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_qr_code_history_list_inventory_master'
                    )
                  "
                >
                  <a
                    v-on:click="activetab = 14"
                    v-bind:class="
                      'nav-link ' + [activetab === 14 ? 'active' : '']
                    "
                    >{{ staticInputLabelMessages.qr_code_history_label }}</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="
                    permissionNameArray.includes(
                      'can_list_deals'
                    )
                  "
                >
                  <a
                    v-on:click="activetab = 15"
                    v-bind:class="
                      'nav-link ' + [activetab === 15 ? 'active' : '']
                    "
                    >Deals <span class="badge badge-primary">{{ totalDeals }}</span></a
                  >
                </li>
              </ul>

              <div
                v-if="activetab === 1"
                class="tabcontent"
                id="customer-activity-logs1"
              >
                <view-asset
                  v-bind:permissionNameVewiAssetArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  :getData="getDataChild"
                ></view-asset>
              </div>
              <div
                v-if="activetab === 2"
                class="tabcontent"
                id="customer-activity-logs2"
              >
                <add-asset
                  v-bind:permissionNameCoreTabArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  @updateDisplayData="updateDisplayData"
                  :getData="getDataChild"
                ></add-asset>
              </div>
              <div
                v-if="activetab === 3"
                class="tabcontent"
                id="customer-activity-logs3"
              >
                <asset-specification
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  @updateDisplayData="updateDisplayData"
                  :getData="getDataChild"
                ></asset-specification>
              </div>
              <div
                v-if="activetab === 4"
                class="tabcontent"
                id="customer-activity-logs4"
              >
                <asset-email
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  @updateDisplayData="updateDisplayData"
                  :getData="getDataChild"
                ></asset-email>
              </div>
              <div
                v-if="activetab === 5"
                class="tabcontent"
                id="customer-activity-logs5"
              >
                <asset-expense-tab
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  @updateDisplayData="updateDisplayData"
                  :getData="getDataChild"
                ></asset-expense-tab>
              </div>
              <div
                v-if="activetab === 6"
                class="tabcontent"
                id="customer-activity-logs6"
              >
                <asset-close
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  :getData="getDataChild"
                ></asset-close>
              </div>
              <div
                v-if="activetab === 7"
                class="tabcontent"
                id="customer-activity-logs7"
              >
                <asset-commission-manager
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind::asset="assetData"
                  v-bind:key="activetab"
                ></asset-commission-manager>
              </div>
              <div
                v-if="activetab === 8"
                class="tabcontent"
                id="customer-activity-logs8"
              >
                <opportunity-tab
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  :getData="getDataChild"
                ></opportunity-tab>
              </div>
              <div
                v-if="activetab === 9"
                class="tabcontent"
                id="customer-activity-logs9"
              >
                <asset-crm
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetId"
                  v-bind:key="activetab"
                  :getData="getDataChild"
                  v-bind:inventory="assetId"
                  v-bind:redirectUrl="redirectUrl+'?tab=9'"
                  v-bind:activetab="activetab"
                ></asset-crm>
              </div>
              <div
                v-if="activetab === 10"
                class="tabcontent"
                id="customer-activity-logs10"
              >
                <draggable-image
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  @updateDisplayData="updateDisplayData"
                  :getData="getDataChild"
                ></draggable-image>
              </div>
              <div
                v-if="activetab === 11"
                class="tabcontent"
                id="customer-activity-logs10"
              >
                <image-360-view
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  @updateDisplayData="updateDisplayData"
                  :getData="getDataChild"
                ></image-360-view>
              </div>
              <div
                v-if="activetab === 12"
                class="tabcontent"
                id="customer-activity-logs12"
              >
                <asset-repair-orders
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  :getData="getDataChild"
                ></asset-repair-orders>
              </div>
              <div
                v-if="activetab === 13"
                class="tabcontent"
                id="customer-activity-logs13"
              >
                <asset-file
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  :getData="getDataChild"
                ></asset-file>
              </div>
              <div
                v-if="activetab === 14"
                class="tabcontent"
                id="customer-activity-logs12"
              >
                <asset-qr-code-history-list
                  v-bind:permissionNameArray="permissionNameArray"
                  v-bind:asset="assetData"
                  v-bind:key="activetab"
                  :getData="getDataChild"
                ></asset-qr-code-history-list>
              </div>
              <div
                v-if="activetab === 15"
                class="tabcontent"
                id="customer-activity-logs15"
              >
                <list-inventory-deals></list-inventory-deals>
              </div>
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!--page content-->
      </div>
      <!-- </template>
            <template v-else>
                <no-access></no-access>
            </template> -->
      <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
  </div>
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";

import AddAsset from "@/components/AssetMaster/AddAsset";
import ViewAsset from "@/components/AssetMaster/ViewAsset";
import AssetSpecification from "@/components/AssetMaster/AssetSpecification";
import EmailAsset from "@/components/AssetMaster/EmailAsset";
import AssetCloseTab from "@/components/AssetMaster/AssetCloseTab";
import AssetCommissionManager from "@/components/AssetMaster/AssetCommissionManagerTab";
import AssetExpenseTab from "@/components/AssetMaster/ExpenseTab/AssetExpenseTab";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import OpportunityTab from "@/components/AssetMaster/OpportunityTab/OpportunityTab";
import CRMAsset from "@/components/AssetMaster/CRMAsset";
import ListActivity from "@/components/Activity/ListActivity";
import DraggableAssetImage from "@/components/AssetMaster/DraggableAssetImage";
import Image360View from "@/components/AssetMaster/Image360View";
import RepairOrdersAsset from "@/components/AssetMaster/RepairOrders/RepairOrdersAsset";
import FileAsset from "@/components/AssetMaster/FileAsset";
import ListQRCodeHistory from "@/components/AssetMaster/ListQRCodeHistory";
import ListInventoryDeals from '@/components/DealMaster/ListDeals';

Vue.component("app-footer", Footer);

export default {
  props: ["redirectTo"],
  name: "ViewAsset",
  components: {
    "add-asset": AddAsset,
    "view-asset": ViewAsset,
    "asset-specification": AssetSpecification,
    "asset-email": EmailAsset,
    "asset-close": AssetCloseTab,
    "no-access": NoAccessPage,
    "asset-commission-manager": AssetCommissionManager,
    "asset-expense-tab": AssetExpenseTab,
    "opportunity-tab": OpportunityTab,
    // "asset-crm" : CRMAsset,
    "asset-crm": ListActivity,
    "draggable-image": DraggableAssetImage,
    "image-360-view": Image360View,
    "asset-repair-orders": RepairOrdersAsset,
    "asset-file": FileAsset,
    "asset-qr-code-history-list": ListQRCodeHistory,
    "list-inventory-deals": ListInventoryDeals
  },

  mixins: [commonMixins],
  data() {
    return {
      staticInputLabelMessages: "",
      staticMessages: "",
      activetab: 0,
      pageTitle: "Inventory",
      assetData: {
        content: {},
        media: []
      },
      permissionNameArray: [
        "can_add_inventory_master",
        "can_view_inventory_master"
      ],
      redirectUrl: "inventory/tabview/",
      redirectId: "",
      listCRMData: [],
      assetId: 0,
      totalDeals: 0,
      productionCountry: process.env.VUE_APP_PRODUCTION_COUNTRY,
    };
  },
  beforeMount() {
    self = this;
  },
  mounted: function() {
    self.redirectUrl = self.redirectUrl + self.$route.params.id;
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }

    if (
      typeof self.$route.query.tab != "undefined" &&
      self.$route.query.tab != ""
    ) {
      console.log({ tab: self.$route.query.tab });
      self.activetab = parseInt(this.$route.query.tab);
      console.log({ tabafter: self.activetab });
    }

    self.assetId = self.$route.params.id;
    /* Redirect to login page logic end */
    self.staticInputLabelMessages = self.allLabelMessages;
    self.staticMessages = self.allStaticMessages;
    self.setActiveMenu("asset-list");
    EventBus.$emit("menu-selection-changed", "asset-list");
    self.redirectToTabFunction();
    self.getLoginIdPermissions();
    if(self.productionCountry=='US') {
      self.getDealCount();
    }
  },
  methods: {
    updateDisplayData: function(actTab) {
      self.getData(actTab);
    },
    redirectToTabFunction: function() {
      if (typeof self.redirectTo !== "undefined" && self.redirectTo !== "") {
        self.redirectId = self.redirectTo;
      } else {
        self.redirectId = 1;
      }
      self.getData(self.redirectId);
    },
    buttonBack() {
      if (
        typeof self.$route.params.redirectSlug != "undefined" &&
        self.$route.params.redirectSlug != ""
      ) {
        self.$router.push({ name: "ListOnOrderAsset" });
      } else {
        self.$router.push({ name: "ListAsset" });
      }
    },
    getDataChild() {
      var self = this;
      self.getData(6);
    },
    getData: function(actTab) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },

        params: {
          id: self.$route.params.id
        }
      };
      self.showLoader();
      HTTP.get("/asset/get", config)
        .then(function(data) {
          self.hideLoader();
          if (data.status == 200) {
            self.assetData = data.data;
            self.asset = self.assetData;
            EventBus.$emit('assetDataIsUpdated',data.data);
            self.pageTitle =
              "VIN : " + self.assetData.content["asset_data"].vin_no + " | ";
            self.pageTitle += self.assetData.content["asset_data"].year + " ";
            self.pageTitle +=
              self.assetData.content["asset_data"].make_name + " ";
            self.pageTitle += self.assetData.content["asset_data"].model_name;
          }
          self.$nextTick(() => {
            if (
              typeof self.$route.query.tab != "undefined" &&
              self.$route.query.tab != ""
            ) {
              return;
            }
            self.activetab = actTab;
          });
        })
        .catch(function(error) {
          self.hideLoader();
          self.catchHandler(error, function() {});
          self.buttonBack();
        });
    },
    getDealCount() {
      var config = {
        headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
      };
      let assetId = this.$route.params.id;
      HTTP.get("/asset/"+assetId+"/deal-count", config)
        .then(function (data) {
            if (data.data.code == 200) {
                self.totalDeals = data.data.content.total_deals;
            }
        })
        .catch(function (error) {
            self.catchHandler(err, function () {});
        })
    }
  },
  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newValue) {
        if (!newValue || newValue === self.activetab) {
          return;
        }

        self.activetab = newValue;
      }
    }
  }
};
EventBus.$on("login-permissions", function(permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
