// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import { store } from './store';
import * as Sentry from "@sentry/vue";

/* IE Support start*/
// import 'core-js/es6/number';
// import 'core-js/shim';
/* IE Support End*/

import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';

/* Vue moment Vuectkdatepicker */
import VueMoment  from 'vue-moment';
import moment from 'moment-timezone'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
/* Vue moment Vuectkdatepicker */
Vue.use(BootstrapVue);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
// import 'vue-select/dist/vue-select.css';

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'en' });

Vue.use(VueMoment, {
    moment,
});

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.config.productionTip = false;

Vue.filter('capitalize', function (value) {
	if (!value) {
		return ''
	}
	value = value.replace(/_/g, ' ')
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('truncate', function (string) {
  if (string) {
    if (string.length <= 15) {
      return string
    }
    return string.slice(0, 15) + '...'
  } else {
    return string
  }
})

Vue.filter('formatPrice', function (value) {
  let val = (value/1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracing: true,
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    trackComponents: ["Header", "Navigation", "Footer"],
    hooks: ["create", "mount"],
  });


/* eslint-disable no-new */
new Vue({
  	el: '#app',
  	router,
        store,
  	// components: { App },
	  render: h => h(App),
  	template: '<App/>'
});
