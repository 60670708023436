<template>
    <div class="modal" id="tagMapperModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document" v-if="isClickAddTag">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{allLabelMessages.add_tag}}</h5>
                </div>
                <div class="modal-body">
                   <v-select
                    v-model="tags"
                    @input="tagError = ''"
                    name="tags"
                    id="tag-mapper"
                    label="text"
                    :clearable="true"
                    :searchable="true"
                    :filterable="true"
                    :options="tagList"
                    multiple
                    :placeholder="allStaticMessages.select_tags"
                   />
                    <span class="help is-danger">{{ tagError }}</span>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="store">{{allLabelMessages.save}}</button>
                    <button type="button" class="btn btn-secondary" @click="closeModal">{{allLabelMessages.close}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import {commonMixins} from '../../mixins/commonMixins';

    export default {
        name: "TagMapperModal",
        props: ["isClickAddTag","modelType","modelId","selectedTags"],
        mixins: [commonMixins],
        data() {
            return {
                tagList: [],
                tags: [],
                tagError: "",
            };
        },
        methods: {
            closeModal() {
                this.clearData();
                EventBus.$emit("close-tag-mapper", false);
            },
            getTags() {
                const tags = JSON.stringify(this.selectedTags.map(tag => tag.id));
                this.loadAllData(
                    ["Tag"],
                    true,
                    this.loadTagCallback,
                    undefined,
                    "",
                    this.modelId,
                    tags,
                    "",
                    "",
                    "",
                    "",
                    this.modelType
                );
            },
            loadTagCallback: function (callbackData) {
                this.tagList = callbackData.Tag;
            },
            store() {
                if(this.isValid()) {
                    const config = {
                    headers: {
                            Authorization: "Bearer " + localStorage.getItem("api_token")
                        }
                    };
                    const data = {
                        tag_ids: this.tags.map(tag => tag.value),
                        model_id: this.modelId,
                        model_type: this.modelType,
                    };

                    HTTP.post("/tags/mapper", data, config)
                    .then((response) => {
                        if(response.status==200) {
                            this.showSAlert(
                                response.data.message,
                                response.data.status,
                                false,
                                2
                            );
                            this.clearData();
                            EventBus.$emit("add-tag-mapper", false);
                        }
                    })
                    .catch((error) => {
                        this.catchHandler(error, function () { });
                    });
                }
            },
            clearData() {
                this.tagList = [];
                this.tags = [];
                this.tagError = "";
            },
            isValid() {
                let valid = true;
                if (this.tags.length === 0) {
                    this.tagError = this.allStaticMessages.select_tags_validation;
                    valid = false;
                } else {
                    this.modelError = "";
                }

                return valid;
            }
        },
        watch: {
            isClickAddTag(status) {
                if(status) {
                    this.getTags();
                }
            }
        }
    }
</script>