<template>
    <div class="modal" id="searchCompanyModal" tabindex="-1" role="dialog">
      <div
        v-if="isClickSearchCompanyModal"
        class="modal-dialog modal-xl modal-dialog-centered cust-src-modal-sz"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header customer-src-modal-row">
            <div>
              <div class="customer-input-search">
                <input type="text" id="customer-form-input" v-model="currentSearch" />
                <div class="customer-search-icon" @click="onSearchCustomer()"></div>
              </div>
            </div>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body cust-src-modal-body">
            <div class="card">
              <table class="table custom-table jsmartable search-cus-list">
                <caption style="display:none">Customer List</caption>
                <thead id="cus-src-modal">
                  <th>CUSTOMER ID</th>
                  <th>SALESMAN</th>
                  <th>CUSTOMER</th>
                  <th>Street Address</th>
                  <th>Action</th>
                </thead>
                <tbody v-if="loader">
                  <tr>
                    <td colspan="5">
                      <loader />
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="customerArr.length > 0">
                  <tr v-for="(customer,index) in customerArr" :key="index">
                    <td>{{ customer.customerId }}</td>
                    <td>{{ customer.salesman }}</td>
                    <td>{{ customer.text }}</td>
                    <td>{{ customer.streetAddress }}</td>
                    <td> <a href="javascript:void(0)" @click="selectCustomer(customer)">Select</a> </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5">
                      <div class="d-flex justify-content-center">
                        <h6>No Data</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import eventBus from "../event-bus";
  import { commonMixins } from "@/mixins/commonMixins";
  import Loader from "../AssetMaster/NewTransactionSheet/Modal/Loader.vue";

  let selfThis;
  export default {
    name: "SearchModal",
    props: ["isClickSearchCompanyModal", "customerName"],
    components: {
      'loader': Loader,
    },
    mixins: [commonMixins],
    data() {
      return {
        customerArr: [],
        currentSearch: "",
        loader: false,
      };
    },
    mounted() {
      selfThis = this;
    },
    methods: {
      closeModal() {
        this.customerArr = [];
        eventBus.$emit("close-search-opportunity-customer", false);
      },
  
      onSearchCustomer() {
        this.loader = true;
        let customer = selfThis.currentSearch;
        this.loadAllData(
          ["CustomerSearch"],
          true,
          selfThis.loadCustomerCallback,
          "undefined",
          customer
        );
      },
  
      loadCustomerCallback: function (callbackData) {
        if(callbackData.CustomerSearch.length > 0) {
          selfThis.loader = 'No Data';
        }
        selfThis.customerArr = callbackData.CustomerSearch;
        selfThis.loader = false;
      },
  
      selectCustomer(customer) {
        eventBus.$emit("get-search-opportunity-customer", customer);
        this.customerArr = [];
      },
    },
    watch: {
      isClickSearchCompanyModal(status) {
        if (status) {
          selfThis.currentSearch = selfThis.customerName;
          selfThis.onSearchCustomer();
        }
      },
    },
  };
  </script>
  
<style>
.cust-src-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#cus-src-modal {
  background-color: #fff;
  position: sticky;
  top: -16px;
}

.cust-src-modal-sz {
  max-width: 1250px;
}
</style>