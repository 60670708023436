<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area add-update-user-form">
        <template v-if="permissionNameArray.includes('can_add_user')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                    <button v-if="isEdit && permissionNameArray.includes('can_change_password_user')" type="submit" v-on:click.prevent="changePasswordClick()" class="btn btn-primary waves-effect pull-right">
                        Change Password
                    </button>
                </div>

                <!-- change password page content start -->
                <template v-if="isChnagePassword">
                    <div class="admin-page-content">
                        <div class="forms-container add_user_forms_container vtc-dealer-forms">
                            <div class="row no-margin">
                                <!-- New Password -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.new_password_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="newPasswordError = ''" type="password" class="form-control" id="toggle-password" v-model="newPassword">
                                        <div class="requiredstar">*</div>
                                        <span toggle="#toggle-password" class="fa fa-lg fa-eye field-icon toggle-password"></span>
                                        <span class="help is-danger">{{newPasswordError}}</span>
                                    </div>
                                </div>
                                <!-- Confirm Password -->
                                <div class="col-6 forms-block">
                                    <div class="col-3 paddingleft0">
                                        <div class="form-label">{{staticInputLabelMessages.confirm_password_label}}</div>
                                    </div>
                                    <div class="col-9 paddingleft0">
                                        <input @input="confirmPasswordError = ''" type="password" class="form-control" id="toggle-password-confirm" v-model="confirmPassword">
                                        <div class="requiredstar">*</div>
                                        <span toggle="#toggle-password-confirm" class="fa fa-lg fa-eye field-icon toggle-password-confirm"></span>
                                        <span class="help is-danger">{{confirmPasswordError}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Buttons -->
                            <div class="row clearfix"></div>
                            <div class="button-demo">
                                <input id="addChangeBtn" :disabled="disableButtons.passwordUpdate" type="submit" :value="buttonNames.passwordUpdate" class="btn btn-primary" v-on:click.prevent="updatePassword()">
                                <input id="CancelChange" :disabled="disableButtons.passwordCancel" type="submit" :value="buttonNames.passwordCancel" class="btn btn-primary" v-on:click.prevent="cancelChnagePasswordBlock">
                            </div>

                        </div>
                    </div>
                    &nbsp;
                </template>
                <!-- change password page content end -->

                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->
                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">
                            <!-- Dayforce Id -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dayforce_id_label}}</div>
                                </div>
                                <div v-if="!isEdit" class="col-9 paddingleft0">
                                    <!-- v-on:blur="checkUserInSSO('dayforace')" -->
                                    <input @input="dayforceIdError = ''" class="form-control" id="dayforceId" v-model="dayforceId">
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dayforceIdError}}</span>
                                </div>
                                <div v-else class="col-9 paddingleft0">
                                    <span class="form-control">{{dayforceId}}</span>
                                    <div class="help is-danger">{{dayforceIdError}}</div>
                                    <div class="requiredstar">*</div>
                                </div>
                            </div>
                            <!-- Username -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.username_label}}</div>
                                </div>
                                <div v-if="!isEdit" class="col-9 paddingleft0">
                                    <input @input="usernameError = ''" id="username" v-model="username" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{usernameError}}</span>
                                </div>
                                <div v-else class="col-9 paddingleft0">
                                    <template v-if="permissionNameArray.includes('can_change_username_user')">
                                        <input @input="usernameError = ''" id="username" v-model="username" class="form-control" type="text"/>
                                        <div class="requiredstar">*</div>
                                        <span class="help is-danger">{{usernameError}}</span>
                                    </template>
                                    <template v-else>
                                        <span class="form-control">{{username}}</span>
                                        <div class="help is-danger">{{usernameError}}</div>
                                        <div class="requiredstar">*</div>
                                    </template>
                                </div>
                            </div>
                            <!-- First Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.first_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="firstNameError = ''" id="firstName" v-model="firstName" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{firstNameError}}</span>
                                </div>
                            </div>
                            <!-- Last Name -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.last_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="lastNameError = ''" id="lastName" v-model="lastName" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lastNameError}}</span>
                                </div>
                            </div>
                            <!-- Contact No -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contact_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="contactNoError = ''" id="contactNo" v-model="contactNo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{contactNoError}}</span>
                                </div>
                            </div>
                            <!-- Email -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.email_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <!-- v-on:blur="checkUserInSSO('email')" -->
                                    <input @input="emailError = ''" id="email" v-model="email" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emailError}}</span>
                                </div>
                            </div>
                            <!-- Role -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.role_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Role"
                                              v-model="roleId"
                                              @change="changedValue"
                                              @input="roleIdError = ''"
                                              name="roleId"
                                              id="roleId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="roleArr"
                                              :placeholder="staticMessages.role_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{roleIdError}}</span>
                                </div>
                            </div>
                            <!-- TimeZone -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.timezone_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Timezone"
                                              v-model="timezoneId"
                                              @change="changedValue"
                                              @input="timezoneIdError = ''"
                                              name="timezoneId"
                                              id="timezoneId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="timezoneArr"
                                              :placeholder="staticMessages.time_zone_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{timezoneIdError}}</span>
                                </div>
                            </div>

                            <!-- Company -->
                            <div v-show="permissionNameArray.includes('can_update_department_user')" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="companyId"
                                        multiple
                                        name="state"
                                        label="text"
                                        :filterable="false"
                                        :options="companyArr"
                                        @search="onSearchCompany"
                                        :clearable="true"
                                        :open-on-clear="true"
                                        @input="companyError = ''"
                                        :placeholder="staticMessages.company_place_holder"
                                        :deselectFromDropdown="true"
                                        class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompanyRecordNotFound">
                                                {{staticMessages.company_place_holder}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyError}}</span>
                                </div>
                            </div>
                            <!-- Home Location -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.home_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Location"
                                              v-model="homeLocationId"
                                              @change="changedValue"
                                              @input="homeLocationError = ''"
                                              name="homeLocationId"
                                              id="homeLocationId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="homeLocationArr"
                                              :placeholder="staticMessages.home_location_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{homeLocationError}}</span>
                                </div>
                            </div>
                            <!-- Other Location -->
                            <div v-show="permissionNameArray.includes('can_update_location_user')" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.other_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <multiselect 
                                        v-model="locationId" 
                                        :options="locationArr" 
                                        :multiple="true" 
                                        :close-on-select="false" 
                                        :clear-on-select="false" 
                                        :preserve-search="true" 
                                        :placeholder="staticMessages.other_location_place_holder"
                                        label="text" 
                                        track-by="text" 
                                        :preselect-first="true"
                                        class="form-control"
                                        @input="locationError = ''"
                                        >
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedStatusFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                        </template>
                                    </multiselect>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>
                            <!-- Home Department -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.home_department_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select title="Select Department"
                                              v-model="homeDepartmentId"
                                              @change="changedValue"
                                              @input="homeDepartmentError = ''"
                                              name="homeDepartmentId"
                                              id="homeDepartmentId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="homeDepartmentArr"
                                              :placeholder="staticMessages.home_department_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{homeDepartmentError}}</span>
                                </div>
                            </div>
                            <!-- Other Departments -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.other_department_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <multiselect 
                                        v-model="departmentId" 
                                        :options="departmentArr" 
                                        :multiple="true" 
                                        :close-on-select="false" 
                                        :clear-on-select="false" 
                                        :preserve-search="true" 
                                        :placeholder="staticMessages.other_department_place_holder"
                                        label="text" 
                                        track-by="text" 
                                        :preselect-first="true"
                                        class="form-control"
                                        @input="departmentError = ''"
                                        >
                                        <template slot="option" slot-scope="props">
                                            <div v-if="appliedDepartmentFilter.includes(props.option.value)" class="multiple_select_item selected_multiple_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                            <div v-else class="multiple_select_item">
                                                <span>{{ props.option.text }}</span>
                                            </div>
                                        </template>
                                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} selected</span>
                                            <span class="multiselect__single" v-else-if="values.length > 0 && values.length <= 1">{{ values[0].text }}</span>
                                        </template>
                                    </multiselect>
                                    <!-- <div class="requiredstar">*</div> -->
                                    <span class="help is-danger">{{departmentError}}</span>
                                </div>
                            </div>
                            <!-- Extra Permissions -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.extra_permissions_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <treeselect
                                            :multiple="true"
                                            :searchable="true"
                                            :options="extraPermissionsArr"
                                            :placeholder="staticMessages.extra_permission_place_holder"
                                            v-model="extraPermissionsValue"
                                            :limit="limitCount"
                                            :limitText="() => treeSelectedNodeMsg"
                                            @select="treeSelectedValue"
                                            @deselect="treeDeselectedValue"
                                    />
                                    <!-- <treeselect-value :value="extraPermissionsValue" /> -->
                                </div>
                            </div>
                            <!-- Access Inventory -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.access_inventory_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                     <v-select
                                        v-model="accessInventory"
                                        name="accessInventory"
                                        @input="accessInventoryError = ''"
                                        label="text"
                                        :filterable="false"
                                        :options="accessInventoryArr"
                                        :clearable="false"
                                        :placeholder="staticMessages.select_access_inventory">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{accessInventoryError}}</span>
                                </div>
                            </div>
                            <!-- Notification Email For No Amount Inventory -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.notification_email_for_no_amount_inventory}}</div>
                                </div>
                                <div class="col-9 custom-checkbox-v paddingleft0">
                                    <label class="container">
                                        <input v-model="notificationEmail" class="checkbox" type="checkbox" id="notificationEmail" name="notificationEmail">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- User Manager  -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.manager_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="manageBy" @change="changeManageBy"
                                        @input="manageByError = ''" name="manageBy" :id="pre_id + 'manageBy'"
                                        label="text" :clearable="false" @search="onSearchManageBy"
                                        :filterable="false" :options="manageByArr"
                                        :placeholder="staticInputLabelMessages.manager_by_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isManageByRecordNotFound">
                                                {{ staticInputLabelMessages.manager_by_search }}
                                            </template>
                                            <template v-else>
                                                {{ staticMessages.search_record_not_found }}
                                            </template>
                                        </span>
                                    </v-select>
                                </div>
                            </div>

                        </div>
                        <!-- Error Alert -->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <!-- Buttons -->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addUserBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addUser ()">
                            <input id="addAndContinueUserBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addUser (flag='Save and Continue')">
                            <input id="CancelUser" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!-- page content -->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import NoAccessPage from "../NoAccessPage/NoAccessPage"
    // import the component
    import Treeselect from '@riophae/vue-treeselect';
    // import the styles
    import Multiselect from 'vue-multiselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';


    Vue.component("app-footer", Footer);
    Vue.component('no-access', NoAccessPage);

    export default {
        name: 'AddUpdateUser',
        mixins: [commonMixins],
        components: { 
            Treeselect,
            Multiselect
        },
        data () {
            return {
                pre_id: "add_update_user",
                staticInputLabelMessages: "",
                staticMessages: "",
                newPassword: "",
                newPasswordError: "",
                confirmPassword: "",
                confirmPasswordError: "",

                extraPermissionsValue : [],
                extraPermissionsArr : [],
                isEdit: false,
                firstName: "",
                lastName: "",
                contactNo: "",
                email: "",
                dayforceId: "",
                username: "",
                roleId: "",
                roleArr: [],
                timezoneId: "",
                timezoneArr: [],
                homeLocationId: "",
                homeLocationArr: [],
                homeDepartmentId: "",
                homeDepartmentArr: [],
                firstNameError: "",
                lastNameError: "",
                contactNoError: "",
                emailError: '',
                dayforceIdError: "",
                usernameError: "",
                roleIdError: "",
                timezoneIdError: "",
                homeLocationError: "",
                homeDepartmentError: "",
                accessInventory: {value: "used", text: "Used"},
                accessInventoryArr: [
                    {
                        value: "both",
                        text: "Both"
                    },
                    {
                        value: "new",
                        text: "New"
                    },
                    {
                        value: "used",
                        text: "Used"
                    }
                ],
                accessInventoryError: "",
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel',
                    passwordUpdate: 'Update',
                    passwordCancel: 'Cancel',
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false,
                    passwordUpdate: false,
                    passwordCancel: false,
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add User",
                companyId : "",
                companyArr : [],
                companyError : "",
                isCompanyRecordNotFound: false,
                locationId : "",
                locationArr : [],
                locationError : "",
                islocationRecordNotFound: false,
                departmentId : "",
                departmentArr : [],
                departmentError : "",
                isdepartmentRecordNotFound: false,
                permissionNameArray: ['can_add_user', 'can_change_password_user'],
                appliedStatusFilter : [],
                appliedDepartmentFilter : [],
                notificationEmail : false,
                isChnagePassword: false,
                isUpdateFormLocFlag : false,
                count: 0,
                displayCount : 0,
                limitCount: 1,
                treeSelectedNodeMsg : '',
                availableSelectedPerArr : [],
                flagType: "",
                manageBy: "",
                manageByArr: [],
                isManageByRecordNotFound: false,
                manageByError: "",

            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            dayforceId: function (dayforceId) {
                if (dayforceId.length > 3) {
                    self.checkUserInSSO('dayforace');
                }
            },
            username: function (username) {
                if (username.length > 3) {
                    //self.checkUserInSSO('email');
                }
            },
            locationId: function(){
                self.appliedStatusFilter = [];
                $.each(self.locationId, function (i) {
                    self.appliedStatusFilter.push(self.locationId[i].value);
                });
            },
            departmentId: function(){
                self.appliedDepartmentFilter = [];
                $.each(self.departmentId, function (i) {
                    self.appliedDepartmentFilter.push(self.departmentId[i].value);
                });
            },
            companyId: function (companyId) {
                var company_id = '';
                if( companyId && companyId.length > 0 ) {
                    for (var i = 0; i < companyId.length; i++) {
                        company_id += (companyId[i].value) + ',';
                    }
                    company_id = company_id.replace(/,\s*$/, "");
                }
                self.loadAllData(['Location'], false, self.loadLocationDataCallback, 'undefined', '', company_id);
                if(self.isUpdateFormLocFlag == true){
                    self.isUpdateFormLocFlag = false;
                } else {
                    // self.locationId = '';
                }
            }
        },
        mounted: function() {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('user-list');
            EventBus.$emit("menu-selection-changed", "user-list");
            self.isEdit = false;

            $("#alertError").hide();
            self.getLoginIdPermissions();
            if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                self.getUserDetailsById();
                self.buttonNames.save = "Update";
                $("#addAndContinueUserBtn").hide();
                self.pageTitle = "Edit User";
                self.isEdit = true;
                self.isUpdateFormLocFlag = true;
            }

            if(process.env.VUE_APP_PRODUCTION_COUNTRY == 'US') {
                self.flagType = "UserForm";
            }
            self.loadAllData(['Location', 'Company_CompanyData', 'Role', 'Timezone', 'Department'], false, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('Location', false, self.loadLocationDataCallback, "undefined", '');
            self.loadAllData('Location', false, self.loadHomeLocationDataCallback, "undefined", '');
            self.loadAllData('Company', false, self.loadCompanyDataCallback, "undefined", '');
            self.loadAllData('Role', false, self.loadRoleDataCallback);
            self.loadAllData('Timezone', false, self.loadTimezoneDataCallback); */
            self.getAllPermissions();
            // self.toggelPasswordConfirmPassword();
        },
        methods: {
            treeSelectedValue : function(selectedNodeObj, instanceId) {
                self.count = 0;
                if (!self.extraPermissionsArr.length) return false;
                self.extraPermissionsArr.forEach(parentNode => {
                    if(parentNode.children) {
                        parentNode.children.forEach(childNode => {
                            if(childNode.label == selectedNodeObj.label) {
                                parentNode.isSelectedCount++;
                            }
                        });
                    } 
                    if(parentNode.label == selectedNodeObj.label) {
                        parentNode.isSelectedCount = parentNode.children.length;
                    }

                    if(parentNode.isSelectedCount == parentNode.children.length) {
                        self.count += 1;
                    } else {
                        self.count += parentNode.isSelectedCount;
                    }
                });

                if(self.count != self.limitCount) {
                    self.treeSelectedNodeMsg = 'and '+ (self.count - self.limitCount) +' more';
                }
            },
            treeDeselectedValue : function(selectedNodeObj, instanceId) {
                self.count = 0;
                if (!self.extraPermissionsArr.length) return false;
                self.extraPermissionsArr.forEach(parentNode => {
                    if(parentNode.children) {
                        parentNode.children.forEach(childNode => {
                            if(childNode.label == selectedNodeObj.label) {
                                parentNode.isSelectedCount--;
                            }
                        });
                    } 
                    if(parentNode.label == selectedNodeObj.label) {
                        parentNode.isSelectedCount = 0;
                    }

                    if(parentNode.isSelectedCount == parentNode.children.length) {
                        self.count += 1;
                    } else {
                        self.count += parentNode.isSelectedCount;
                    }
                });

                if(self.count != self.limitCount) {
                    self.treeSelectedNodeMsg = 'and '+ (self.count - self.limitCount) +' more';
                }
            },
            loadMasterDataCallback: function(callbackData) {
                self.islocationRecordNotFound = self.isCompanyRecordNotFound = false;
                self.locationArr = self.homeLocationArr = callbackData.Location;
                self.departmentArr =self.homeDepartmentArr = callbackData.Department;
                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }
                if(self.departmentArr.length == 0) {
                    self.isdepartmentRecordNotFound = true;
                }
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
                self.roleArr = callbackData.Role;
                self.timezoneArr = callbackData.Timezone;
            },
            onSearchCompany(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadCompanyDataCallback, loading, search);
                }
            },
            loadCompanyDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            onSearchLocation(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Location'], true, self.loadLocationDataCallback, loading, search);
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.islocationRecordNotFound = false;
                
                self.locationArr = callbackData.Location;

                // Iterate through selectedValues
                let matchingLocations = [];
                if(self.locationId) {
                    self.locationArr.forEach((option, indexSecond) => {
                        self.locationId.forEach((selected, index) => {
                                if(option.value == selected.value) {
                                    matchingLocations.push(JSON.parse(JSON.stringify(selected)));
                                }
                            });
                    });

                }

                self.locationId = matchingLocations;

                if(self.locationArr.length == 0) {
                    self.isLocationRecordNotFound = true;
                }

            },
            /* loadHomeLocationDataCallback: function (callbackData) {
                self.homeLocationArr = callbackData.Location;
            }, */
            getAllPermissions: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },

                };

                HTTP.get('/permission/get', config).
                then(response => {
                    let res = response.data.content;
                    var keys = Object.keys(res);
                    let options = [];

                    for (let i = 0; i < keys.length; i++) {
                        let temp1 = {};
                        let module = res[keys[i]];
                        let lable = keys[i];

                        temp1['label'] = lable;
                        let children = [];
                        let tempId = "";
                        for (let j = 0; j < module.length; j++  ) {
                            let tempObj = {};
                            if (tempId == "") {
                                tempId = module[j]['id'];
                            } else {
                                tempId = tempId + "," + module[j]['id'];
                            }
                            tempObj['id'] = module[j]['id'];
                            tempObj['label'] = module[j]['display_name'];
                            children.push(tempObj);
                        }
                        temp1['id'] = tempId;
                        temp1['children'] = children;
                        temp1['isSelectedCount'] = 0;
                        options.push(temp1)
                    }
                    self.extraPermissionsArr = options;

                    if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                        if(self.availableSelectedPerArr.length != 0) {
                            self.availableSelectedPerArr.forEach(selectedPer => {
                                self.treeSelectedValue(selectedPer);
                            });
                        }   
                    }
                })
                    .catch(function (err) {
                        /* console.log(err); */
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                self.$router.push("/login");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            buttonBack() {
                self.$router.push('/user/list');
            },
            toggelPasswordConfirmPassword: function() {
                $(".toggle-password").click(function() {
                    $(this).toggleClass("fa-eye fa-eye-slash");
                    var input = $($(this).attr("toggle"));
                    if (input.attr("type") == "password") {
                        input.attr("type", "text");
                    } else {
                        input.attr("type", "password");
                    }
                });

                $(".toggle-password-confirm").click(function() {
                    $(this).toggleClass("fa-eye fa-eye-slash");
                    var input = $($(this).attr("toggle"));
                    if (input.attr("type") == "password") {
                        input.attr("type", "text");
                    } else {
                        input.attr("type", "password");
                    }
                });
            },
            clearDataOnNewPasswordInput: function () {
                self.isChnagePassword = false;
                self.newPassword = "";
                self.newPasswordError = "";
                self.confirmPassword = "";
                self.confirmPasswordError = "";
            },
            changePasswordClick: function() {
                self.isChnagePassword = !self.isChnagePassword;
                if(!self.isChnagePassword) {
                    self.clearDataOnNewPasswordInput();
                } else {
                    setTimeout(function() {
                        self.toggelPasswordConfirmPassword();
                    }, 10);
                }
            },
            cancelChnagePasswordBlock() {
                self.isChnagePassword = false;
                self.clearDataOnNewPasswordInput();
            },
            updatePassword: function() {
                var checked = true;
                if (!self.newPassword) {
                    self.newPasswordError = "Please enter new Password";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.newPassword && !self.checkPasswordLength(self.newPassword)) {
                    self.newPasswordError = "You must provide at least 6 characters for password";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.confirmPassword) {
                    self.confirmPasswordError = "Please enter Confirm Password";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.confirmPassword && !self.checkPasswordAndConfirmPassword(self.newPassword, self.confirmPassword)) {
                    self.confirmPasswordError = "Password and Confirm Password do not match";
                    if (checked) {
                        checked = false;
                    }
                }
                setTimeout(() => {
                    if (checked) {
                        var config = {
                            headers: {Authorization:"Bearer " + localStorage.getItem("api_token")},
                        };
                        var passwordData = new FormData();
                        passwordData.append('password', self.newPassword);
                        passwordData.append('editUserId', self.$route.params.id);

                        self.buttonNames.passwordUpdate = "Updating ...";
                        self.disableButtons.passwordUpdate = true;
                        self.disableButtons.passwordCancel = true;

                        HTTP.post("/change/password", passwordData, config)
                            .then(function (response) {
                                self.buttonNames.passwordUpdate = "Update";
                                self.disableButtons.passwordUpdate = false;
                                self.disableButtons.passwordCancel = false;

                                if (response.data.status == "success") {
                                    self.clearDataOnNewPasswordInput();
                                    self.showSAlert(response.data.content, 'success', false, 3);
                                } else {
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                self.buttonNames.passwordUpdate = "Update";
                                self.disableButtons.passwordUpdate = false;
                                self.disableButtons.passwordCancel = false;
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            checkPasswordLength: function(password) {
                if(password.length < 6) {
                    return false;
                }
                return true;
            },
            checkPasswordAndConfirmPassword: function (password, confirmPassword) {
                if (password != confirmPassword) {
                    return false;
                } else {
                    return true;
                }
            },
            /* loadRoleDataCallback: function(callbackData) {
                self.roleArr = callbackData.Role;
            },
            loadTimezoneDataCallback: function(callbackData) {
                self.timezoneArr = callbackData.Timezone;
            }, */
            changedValue: function (value) {
                /*console.log(value);*/
            },
            clearDataOnSaveAndContinue : function () {
                self.firstName = '';
                self.lastName = '';
                self.contactNo = '';
                self.email = '';
                self.dayforceId = '';
                self.username = '';
                self.roleId = '';
                self.timezoneId = '';
                self.homeLocationId = '';
                self.homeDepartmentId = '';
                self.companyId = "";
                self.locationId = "";
                self.departmentId = "";
                self.accessInventory = {value: "used", text: "Used"};
            },
            checkUserInSSO: function (flag) {
                let updateFlag = false;
                let userId = 0;

                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    updateFlag = true;
                    userId = self.$route.params.id;
                } else {
                    updateFlag = false;
                }
                var data = {
                    emp_id : (flag == 'dayforace') ? self.dayforceId : self.username,
                    update_flag : updateFlag,
                    user_id : userId,
                }
                var config = {
                    headers: {Authorization:"Bearer " + localStorage.getItem("api_token")},
                };

                HTTP.post('/user/checkinsso', data, config)
                    .then(function (response) {
                        /* console.log('checkinsso', response) */
                        if (response.status == 200) {
                            if(response.data.message && response.data.message != 'undefined'){
                                // self.showSAlert(response.data.message, 'success', false);
                                if(self.isEdit != true){
                                    self.firstName = response.data.content.first_name;
                                    self.lastName = response.data.content.last_name;
                                    self.username = response.data.content.username;
                                    self.email = response.data.content.username;
                                    self.dayforceId = response.data.content.emp_id;
                                }
                            }
                        }
                    })
                    .catch(function (err) {
                        // self.catchHandler(err, function () {});
                    })
            },
            getUserDetailsById: function () {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params : {
                        id : self.$route.params.id
                    }
                };
                HTTP.get('/user/get', config)
                    .then(response => {
                        self.$emit('setPageTitleForEditUser', response.data.content.full_name);
                        self.firstName = response.data.content.first_name;
                        self.lastName = response.data.content.last_name;
                        self.contactNo = response.data.content.contact_no;
                        self.email = response.data.content.email;
                        self.dayforceId = response.data.content.emp_id;
                        self.username = response.data.content.username;
                        self.notificationEmail = (response.data.content.notification_email == 1) ? true : false;
                        if (response.data.content.roles.length > 0) {
                            self.roleId = {text: response.data.content.roles[0].name, value: response.data.content.roles[0].id};
                        }
                        if (typeof(response.data.content.timezone_id)  != 'undefined' && response.data.content.timezone_id != 0 && response.data.content.timezone_id != '') {
                            self.timezoneId = {text: response.data.content.timezoneName, value: response.data.content.timezone_id};
                        }
                        if (typeof(response.data.content.home_location_id)  != 'undefined' && response.data.content.home_location_id != 0 && response.data.content.home_location_id != '') {
                            self.homeLocationId = {text: response.data.content.home_location_name, value: response.data.content.home_location_id};
                        }
                        if (typeof(response.data.content.home_department_id)  != 'undefined' && response.data.content.home_department_id != 0 && response.data.content.home_department_id != '') {
                            self.homeDepartmentId = {text: response.data.content.home_department_name, value: response.data.content.home_department_id};
                        }
                        self.companyId = [];
                        for (var i = 0; i < response.data.content.company.length; i++) {
                            self.companyId.push({text: response.data.content.company[i].name, value: response.data.content.company[i].id});
                        }
                        self.locationId = [];
                        for (var i = 0; i < response.data.content.location.length; i++) {
                            self.locationId.push({'text': response.data.content.location[i].name, 'value': response.data.content.location[i].id});
                        }
                        self.departmentId = [];
                        for (var i = 0; i < response.data.content.department.length; i++) {
                            self.departmentId.push({'text': response.data.content.department[i].name, 'value': response.data.content.department[i].id});
                        }
                        for (let i = 0; i < response.data.extra_permissions[0].length; i++) {
                            self.availableSelectedPerArr.push(response.data.extra_permissions[0][i]);
                            self.extraPermissionsValue.push(response.data.extra_permissions[0][i].permission_id);
                        }
                        self.accessInventory = {text: self.formatString(response.data.content.access_inventory), value: response.data.content.access_inventory};

                        self.manageBy = {text: "", value: ""};
                        if (typeof(response.data.content.manage_by_id)  != 'undefined' && response.data.content.manage_by_id != 0 && response.data.content.manage_by_id != '' && typeof(response.data.content.manager_full_name)  != 'undefined' && response.data.content.manager_full_name != 0 && response.data.content.manager_full_name != '') {
                            self.manageBy = {text: response.data.content.manager_full_name, value: response.data.content.manage_by_id};
                        }
                    })
                    .catch(function (err) {
                        self.catchHandler(err, function () {});
                        self.buttonBack();
                    });
            },
            checkForm: function () {
                var checked = true;
                if (!self.firstName) {
                    self.firstNameError = "Please enter first name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.lastName) {
                    self.lastNameError = "Please enter last name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.contactNo) {
                    self.contactNoError = "Please enter contact no";
                    if (checked) {
                        checked = false;
                    }
                } else if(self.contactNo && !self.validContactNumber(self.contactNo)) {
                    self.contactNoError = "Please enter valid contact no";
                    if (checked) {
                        checked = false;
                    }

                } else if (self.contactNo && !self.checkContactNoLength(self.contactNo)) {
                    self.contactNoError = "Max Length is 13";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.email && !self.validEmail(self.email)) {
                    self.emailError = "Please enter valid email";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.username) {
                    self.usernameError = "Please enter username";
                    if (checked) {
                        checked = false;
                    }
                } else if (self.username && !self.validEmail(self.username)) {
                    self.usernameError = "Please enter valid username";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.dayforceId) {
                    self.dayforceIdError = "Please enter dayforce Id";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.roleId == null || !self.roleId.value) {
                    self.roleIdError = "select role";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.timezoneId == null || !self.timezoneId.value) {
                    self.timezoneIdError = "select timezone";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.homeLocationId == null || !self.homeLocationId.value) {
                    self.homeLocationError = "select Home Location";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.homeDepartmentId == null || !self.homeDepartmentId.value) {
                    self.homeDepartmentError = "select Home Department";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.companyId == null || !self.companyId.value) {
                    self.companyError = "Please select company name";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.companyId.length == 0) {
                    self.companyError = "Please select department name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.locationId.length == 0) {
                    self.locationError = "Please select location name";
                    if (checked) {
                        checked = false;
                    }
                }
                /* if (self.departmentId.length == 0) {
                    self.departmentError = "Please select department name";
                    if (checked) {
                        checked = false;
                    }
                } */
                if (self.accessInventory == null || !self.accessInventory.value) {
                    self.accessInventoryError = "select Access Inventory";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addUser (flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var path = window.location.pathname;
                        var segments = path.split("/");
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        let companyArr = [];
                        for (let i = 0; i < self.companyId.length; i++) {
                            companyArr.push(self.companyId[i]['value']);
                        }
                        let locationArr = [];
                        for (let i = 0; i < self.locationId.length; i++) {
                            locationArr.push(self.locationId[i]['value']);
                        }
                        let departmentArr = [];
                        if (self.departmentId.length >= 1) {
                            for (let i = 0; i < self.departmentId.length; i++) {
                                departmentArr.push(self.departmentId[i]['value']);
                            }
                        }
                        let manageById = (self.manageBy && self.manageBy.value) ? self.manageBy.value : "";

                        var data = new FormData();
                        data.append('first_name', self.firstName);
                        data.append('last_name', self.lastName);
                        data.append('contact_no', self.contactNo);
                        data.append('email', self.email);
                        data.append('dayforce_id', self.dayforceId);
                        data.append('username', self.username);
                        data.append('role_id', self.roleId.value);
                        data.append('roleName', self.roleId.text);
                        data.append('timezone_id', self.timezoneId.value);
                        data.append('home_location_id', self.homeLocationId.value);
                        data.append('home_department_id', self.homeDepartmentId.value);
                        data.append('extra_permission', self.extraPermissionsValue);
                        data.append('company_id', companyArr);
                        data.append('location_id', locationArr);
                        data.append('department_id', departmentArr);
                        data.append('notification_email', self.notificationEmail);
                        data.append('access_inventory', self.accessInventory.value);
                        data.append('manage_by_id', manageById);


                        if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                            data.append('id', self.$route.params.id);
                            self.buttonNames.save = "Updating ...";
                            self.disableButtons.save = true;
                            self.disableButtons.cancel = true;
                        } else {
                            if (flag == "Save and Continue") {
                                self.buttonNames.saveAndContinue = "Saving ...";
                            } else {
                                self.buttonNames.save = "Saving ...";
                            }
                            self.disableButtons.save = true;
                            self.disableButtons.saveAndContinue = true;
                            self.disableButtons.cancel = true;
                        }

                        HTTP.post("/user/add/update", data, config)
                            .then(function (response) {
                                if (typeof(self.$route.params.id)  != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                        self.buttonNames.save = "Update";
                                    } else {
                                        if (flag == "Save and Continue") {
                                            self.buttonNames.saveAndContinue = "Save and Continue";
                                        } else {
                                            self.buttonNames.save = "Save";
                                        }
                                    }
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                                if (response.data.status == "success") {
                                    var userObj = localStorage.getItem("user_obj");
                                    //if user logged in than remove login button and registration button
                                    if (userObj != null) {
                                        var userJson = JSON.parse(userObj);
                                        if(userJson.id == self.$route.params.id){
                                            self.updateUserObjectInLocal('access_inventory',self.accessInventory.value);
                                        }
                                    }                                    
                                    self.disableButtons.save = true;
                                    self.disableButtons.saveAndContinue = true;
                                    self.disableButtons.cancel = true;
                                    self.showSAlert(response.data.user_message, 'success', false ,3);
                                    if (flag == "Save and Continue") {
                                        self.clearDataOnSaveAndContinue();
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    } else {
                                        setTimeout(function () {
                                            self.disableButtons.save = false;
                                            self.disableButtons.saveAndContinue = false;
                                            self.disableButtons.cancel = false;
                                            self.buttonBack();
                                        }, 3000);
                                    }
                                } else {
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    if (response.data.content.length > 0) {
                                        var err = "";
                                        for (var i = 0; i < response.data.content.length; i++) {
                                            err += response.data.content[i] + "<br/>";
                                        }
                                        self.showSAlert(err, 'error', false);
                                    }
                                }
                            })
                            .catch(function (err) {
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.clearDataOnSaveAndContinue();
                                    } else {
                                        self.buttonNames.save = "Save";
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                    }
                                }
                                self.catchHandler(err, function () {});
                            });
                    }
                }, 200);
            },
            onSearchManageBy(search, loading) {
                self.manageBy = "";
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(
                        ["User"],
                        true,
                        self.loadManageByCallBack,
                        loading,
                        search
                    );
                }
            },
            loadManageByCallBack: function (callbackData) {
                self.isManageByRecordNotFound = false;
                self.manageByArr = callbackData.User;
                if (self.manageByArr) {
                    self.isManageByRecordNotFound = true;
                }
            },
            changeManageBy: function (value) {
                
             },
        }   
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>
<style>
    .field-icon {
        float: right;
        margin-right: 8px;
        margin-top: -23px;
        position: relative;
        z-index: 2;
        cursor:pointer;
    }
    .vue-treeselect__limit-tip-text {
        color: #495057 !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
    }
    .add-update-user-form .v-select .selected-tag {
        display: block !important;
    }
    .add-update-user-form .v-select .vs__selected-options {
        flex-wrap: wrap !important;
    }
</style>