<template>
    <div class="modal" id="searchUnitModal" tabindex="-1" role="dialog">
      <div
        v-if="isClickSearchUnitModal"
        class="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header unit-src-modal-row">
            <div>
              <div class="unit-input-search">
                <input type="text" id="unit-form-input" v-model="unitId" />
                <div class="unit-search-icon" @click="onSearchUnit(unitId)"></div>
              </div>
            </div>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body unit-src-modal-body">
            <div class="card">
              <table class="table custom-table jsmartable">
                <caption style="display:none">Unit List</caption>
                <thead id="unit-src-modal">
                  <th>Unit</th>
                  <th>Vin No</th>
                  <th>Action</th>
                </thead>
                <tbody v-if="loader">
                  <tr>
                    <td colspan="3">
                      <loader />
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(asset, index) in unitArr" :key="index">
                    <td>{{ asset.unit }}</td>
                    <td>{{ asset.vin_no }}</td>
                    <td>
                      <a href="javascript:void(0)" @click="selectUnit(asset)"
                        >Select</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import eventBus from "../../../event-bus";
  import { commonMixins } from "@/mixins/commonMixins";
  import { HTTP } from "../../../../http-common";
  import Loader from "./Loader.vue";

  let selfThis;
  export default {
    name: "SearchUnitModal",
    props: ["isClickSearchUnitModal", "unit", "usedUnitList"],
    mixins: [commonMixins],
    components: {
      'loader': Loader,
    },
    data() {
      return {
        unitId: "",
        unitArr: [],
        loader: false,
      };
    },
    mounted() {
      selfThis = this;
    },
    methods: {
      closeModal() {
        eventBus.$emit("close-search-unit", false);
      },
  
      onSearchUnit(unit) {
        selfThis.loader = true;
        let config = {
            headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
        };
        HTTP.get("/search-unit-data/"+unit, config)
            .then(function (response) {
                selfThis.loader = false;
                if (response.data.status === "success") {
                    selfThis.unitArr = response.data.content;
                }
            })
            .catch(function (err) {
                selfThis.loader = false;
                console.log("ERROR!!!", err);
            });
      },
  
      selectUnit(unit) {
        if(selfThis.usedUnitList.includes(unit.unit)) {
          selfThis.showSAlert(
            "UNIT ID has been already used by other vehicle!",
            "info",
            false,
            3
          );
          return true;
        }
        eventBus.$emit("get-search-unit", unit);
      },
    },
    watch: {
      isClickSearchUnitModal(status) {
          if(status && selfThis.unit != "") {
              selfThis.unitId = selfThis.unit;
              selfThis.onSearchUnit(selfThis.unit);
          }
      }
    }
  };
  </script>
  
  <style>
  .unit-src-modal-row > div:first-child {
    display: flex;
    align-items: center;
  }
  
  .unit-src-modal-row > div:last-child {
    display: flex;
    justify-content: flex-end;
  }
  
  .unit-input-search {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 34px;
    border-radius: 2px;
    overflow: hidden;
    /* margin-top: 5px; */
  }
  
  .unit-search-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 100%;
    flex-shrink: 0;
    background: #ebebeb
      url("../../../../../public/static/images/transaction_sheet/search.svg")
      no-repeat center;
    cursor: pointer;
  }

  .unit-src-modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  #unit-src-modal {
    background-color: #fff;
    position: sticky;
    top: -16px;
  }

  #unit-form-input {
    height: 32px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cecece;
  }
  </style>
  