<template>
  <div class="custom-row">
    <div class="vehicle-pos-section">
      <div class="custom-accordian">
        <div class="custom-card">
          <div class="custom-card-header">
            <div class="custom-column">Vehicle PO's</div>
            <div class="custom-column">
              <input type="checkbox" v-model="hideStocked" />
              <label for="post-detail">Hide Invoiced PO’s</label>
            </div>
          </div>
          <div id="vehicle-ros">
            <div class="custom-card-body">
              <div class="custom-row">
                <table class="custom-cost-table">
                  <thead>
                    <td>Add To Cost</td>
                    <td>PO#</td>
                    <td>Creation Date</td>
                    <td>PO Type</td>
                    <td>Status</td>
                    <td>Vendor</td>
                    <td>Description</td>
                    <td>Total</td>
                  </thead>
                  <tbody v-if="hideStocked">
                    <template
                      v-if="
                        assetWiseExpensesArr && assetWiseExpensesArr.length > 0
                      "
                    >
                      <tr
                        v-for="(assetexpenses, index) in assetWiseExpensesArr"
                        :key="index"
                        v-if="assetexpenses.status == 711"
                      >
                        <td>
                          <input
                            type="checkbox"
                            id="trsCheckBox"
                            @change="
                              (e) => {
                                addSubToTotal(e, assetexpenses.actual_cost);
                              }
                            "
                            v-bind:checked="isCostSheetInputChecked"
                          />
                        </td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            @click="getDetail(assetexpenses)"
                            >{{ assetexpenses.excede_po }}</a
                          >
                        </td>
                        <td>{{ assetexpenses.entered }}</td>
                        <td>{{ assetexpenses.expense_category }}</td>
                        <!-- Start Status Condition -->
                        <td v-if="assetexpenses.status == 715">Stocked</td>
                        <td v-else-if="assetexpenses.status == 711">Open</td>
                        <td v-else></td>
                        <!-- End Status -->
                        <td>{{ assetexpenses.vendor }}</td>
                        <td>{{ assetexpenses.summary }}</td>
                        <td>
                          {{ assetexpenses.actual_cost }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-else>
                    <template
                      v-if="
                        assetWiseExpensesArr && assetWiseExpensesArr.length > 0
                      "
                    >
                      <tr
                        v-for="(assetexpenses, index) in assetWiseExpensesArr"
                        :key="index"
                      >
                        <td>
                          <input
                            type="checkbox"
                            id="trsCheckBox"
                            @change="
                              (e) => {
                                addSubToTotal(e, assetexpenses.actual_cost);
                              }
                            "
                            v-bind:checked="isCostSheetInputChecked"
                          />
                        </td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            @click="getDetail(assetexpenses)"
                            >{{ assetexpenses.excede_po }}</a
                          >
                        </td>
                        <td>{{ assetexpenses.entered }}</td>
                        <td>{{ assetexpenses.expense_category }}</td>
                        <!-- Start Status Condition -->
                        <td v-if="assetexpenses.status == 715">Stocked</td>
                        <td v-else-if="assetexpenses.status == 711">Open</td>
                        <td v-else></td>
                        <!-- End Status -->
                        <td>{{ assetexpenses.vendor }}</td>
                        <td>{{ assetexpenses.summary }}</td>
                        <td>
                          {{ assetexpenses.actual_cost }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../../event-bus";
var thisSelf;

export default {
  name: "VehiclePos",
  props: ["assetWiseExpensesArr", "allStaticMessages"],
  data() {
    return {
      hideStocked: true,
      isCostSheetInputChecked: false,
    };
  },
  beforeMount() {
    thisSelf = this;
  },
  methods: {
    addSubToTotal(e, amount) {
      eventBus.$emit("add-subtract-total", e.target.checked, amount);
    },
    getDetail(param) {
      eventBus.$emit("show-details", param, "po");
    },
  },
};

eventBus.$on("close-vhc-cost-sheet", () => {
  Object.assign(thisSelf.$data, thisSelf.$options.data());
});
</script>

<style scoped>
/* Accordion */
.custom-accordian > .custom-card > .custom-card-header {
  display: grid;
  grid-template-columns: 3fr 4fr;
  /* grid-gap: 4px; */

  width: 100%;
  height: 32px;
  flex-shrink: 0;
  border-radius: 2px 2px 0px 0px;
  border-top: 1px solid #076eb0;
  border-right: 1px solid #076eb0;
  border-left: 1px solid #076eb0;
  background: #f1f1f1;
}

.custom-accordian
  > .custom-card
  > .custom-card-header
  > .custom-column:last-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
}

.custom-accordian
  > .custom-card
  > .custom-card-header
  > .custom-column:first-child {
  display: flex;
  width: 228.355px;
  color: #1a203d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "SF UI Display", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.25px;
  align-items: center;
  padding-left: 24.38px;
}

.custom-accordian
  > .custom-card
  > .custom-card-header
  > .custom-column:last-child
  > img {
  width: 15px;
  height: 12.059px;
  flex-shrink: 0;
}

.custom-accordian > .custom-card > div:last-child {
  /* background: #076eb0; */
  width: 100%;
  height: 100%;
  /* height: 327px; */
  flex-shrink: 0;
  border-radius: 0px 0px 2px 2px;
  border-right: 1px solid #076eb0;
  border-bottom: 1px solid #076eb0;
  border-left: 1px solid #076eb0;
  background: #fff;
  padding-bottom: 16px;
}

/* Accordian Body Section */
.custom-accordian > .custom-card > div:last-child > .custom-card-body {
  display: grid;
  grid-template-rows: 1fr auto;
  /* width: 100%; */

  padding-left: 28px;
  padding-right: 28px;
  grid-gap: 10px;
}
.custom-accordian
  > .custom-card
  > div:last-child
  > .custom-card-body
  > .custom-row {
  margin-bottom: 0;
}

.fm-group {
  display: flex;
  flex-direction: column;
}

.fm-group-checkbox {
  margin-top: 9px;
}

.fm-group-checkbox > div {
  padding: 2px;
}

/* label[for="no-license"] {
  margin: 0 2px;
} */

.input-text {
  color: #242731;
  text-align: right;
  font-family: SF UI Display, Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Table */
.custom-cost-table {
  border-collapse: collapse;
  width: 100%;
}

/* .custom-cost-table th, */
.custom-cost-table td {
  border-top: 2px solid#F3F3F3;
  border-bottom: 2px solid#F3F3F3;
  padding: 8px;
  width: 1169px;
  height: 1px;
  flex-shrink: 0;
}

.custom-cost-table thead td {
  color: var(--gray-dark-3, #72767c);
  font-family: "SF UI Display", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-top-color: #fff;
}

.custom-cost-table td {
  color: var(--primary-dark-1, #242731);
  font-family: "SF UI Display", Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-cost-table td:first-child {
  border-left: none;
}

.custom-cost-table td:last-child {
  border-right: none;
}

label[for="post-detail"] {
  margin-bottom: unset;
}
</style>
