<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="permissionNameArray.includes('can_list_opportunity_master')"
    >
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>Opportunity</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              v-show="
                permissionNameArray.includes('can_add_opportunity_master')
              "
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="addOpportunity()"
            >
              <span>Add Opportunity</span>
            </a>

            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>

            <a
              id="exportOpportunityId"
              title="Export"
              v-show="
                permissionNameArray.includes('can_export_opportunity_master')
              "
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="exportOpportunityFuntion($event)"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>

            <button
              id="opportunityHeaderList"
              title="Opportunity Header List"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="opportunityHeaderList($event)"
            >
              Opportunity Header List
            </button>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} rows selected
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <span
              class="ml-2"
              title="Change Customer"
              v-if="
                permissionNameArray.includes('can_move_deal_opportunity_master')
              "
              ><a
                class="btn btn-primary rounded-pill"
                href="javascript:void(0)"
                role="button"
                v-on:click="showChangeCustomerModal()"
                >Move Deal</a
              ></span
            >
            <span
              class="ml-2"
              title="Change Customer"
              v-if="
                permissionNameArray.includes('can_move_deal_opportunity_master')
              "
              ><a
                class="btn btn-primary rounded-pill"
                href="javascript:void(0)"
                role="button"
                v-on:click="showChangeCustomerModal('opportunity')"
                >Move Opportunity</a
              ></span
            >
            <!--  <button v-show="permissionNameArray.includes('can_delete_opportunity_master')" type="button" v-on:click.prevent="deleteOpportunity()" class="btn btn-primary waves-effect pull-right ml-2">Delete {{selectedRows.length}} Record(s)</button> -->
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListOpportunity"
              ref="commonLoaderListOpportunity"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable" style="text-transform: capitalize">
                <tbody>
                  <template v-if="opportunityArr && opportunityArr.length > 0">
                    <tr
                      v-for="(opportunity, index) in opportunityArr"
                      :key="index"
                    >
                      <td class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            v-model="selectedRows"
                            :value="opportunity.id"
                            @change="inputCheckbox($event)"
                            class="custom-control-input"
                            :id="'customCheck' + opportunity.id"
                            name="case[]"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customCheck' + opportunity.id"
                          ></label>
                        </div>
                      </td>
                      <td v-show="actionDisplay">
                        <div class="actions">
                          <router-link
                            v-show="
                              permissionNameArray.includes(
                                'can_add_opportunity_master'
                              )
                            "
                            class="mar-right-0"
                            title="Edit Opportunity"
                            v-bind:to="{
                              name: 'OpportunityTab',
                              params: { id: opportunity.id },
                            }"
                          >
                            <!--####Removed On Creating Tab v-bind:to="{name: 'UpdateOpportunity', params:{id: opportunity.id}}"> ####-->
                            <i
                              aria-hidden="true"
                              class="fa fa-angle-right forward_icon"
                            ></i>
                          </router-link>

                          <!-- <a v-show="permissionNameArray.includes('can_delete_opportunity_master')" v-on:click="deleteOpportunity(opportunity.id)" title='Delete Opportunity' href="javascript:void(0)" class="mar-right-0">
                            <i  class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                        </a> -->
                          <a
                            href="javascript:void(0)"
                            class="mar-right-0"
                            @click.stop.prevent="
                              openContextMenu($event, opportunity)
                            "
                          >
                            <i
                              class="exceptEdit fa fa-cog"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </td>
                      <template
                        v-if="
                          opportunityDataArr && opportunityDataArr.length > 0
                        "
                      >
                        <template
                          v-for="(
                            userData, userDataIndex
                          ) in opportunityDataArr"
                        >
                          <template v-if="index == userDataIndex">
                            <template v-for="(data, dataIndex) in userData">
                              <template
                                v-for="(headerData, headerIndex) in headers"
                              >
                                <template
                                  v-if="headerData.column_name == dataIndex"
                                >
                                  <td
                                    v-if="headerData.display"
                                    :class="headerData.column_class"
                                    :key="dataIndex"
                                  >
                                    {{ data }}
                                  </td>
                                </template>
                              </template>
                            </template>
                          </template>
                        </template>
                      </template>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action' && data.display"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <!-- Vue Context Menu Start -->
            <vue-context
              ref="menu"
              :close-on-click="closeOnClick"
              :close-on-scroll="closeOnScroll"
              :item-selector="itemSelector"
            >
              <li>
                <router-link
                  v-show="permissionNameArray.includes('can_add_deals')"
                  class="mar-right-0"
                  title="Add Deal"
                  v-bind:to="{
                    name: 'NewTransactionSheet',
                    params: {
                      opportunityId: refOpportunity.id,
                      referenceNo: refOpportunity.reference_no,
                      permissionNameArray: permissionNameArray,
                      opportunityStatus: refOpportunity.status,
                    },
                  }"
                >
                  <i aria-hidden="true" class="fa fa-plus-square"> Add Deal</i>
                </router-link>
                <a
                  v-if="
                    permissionNameArray.includes(
                      'can_move_deal_opportunity_master'
                    )
                  "
                  href="javascript:void(0)"
                  title="Click To Move Deal"
                  class="mar-right-0"
                  @click.prevent="showChangeCustomerModal('individual')"
                >
                  <i class="fa fa-chevron-up" aria-hidden="true"></i> Move Deal
                </a>
                <a
                  v-if="
                    permissionNameArray.includes(
                      'can_move_deal_opportunity_master'
                    )
                  "
                  href="javascript:void(0)"
                  title="Click To Move Opportunity"
                  class="mar-right-0"
                  @click.prevent="showChangeCustomerModal('opp-individual')"
                >
                  <i class="fa fa-chevron-up" aria-hidden="true"></i> Move
                  Opportunity
                </a>
              </li>
            </vue-context>
            <!-- Vue Context Menu End -->
          </div>
        </div>
        <!-- Export Modal -->
        <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="exportModalLabel">
                  Export Opportunity
                </h4>
              </div>
              <form
                class="form-inline"
                id="exportModalForm"
                enctype="multipart/form-data"
              >
                <common-loader
                  refId="commonLoaderExportOpportunity"
                  ref="commonLoaderExportOpportunity"
                >
                </common-loader>
                <div class="col-md-12 modal-body" id="modal-checkbox">
                  <template v-if="exportHeaders && exportHeaders.length > 0">
                    <!-- Select All -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          type="checkbox"
                          v-model="isSelectAllExportHeader"
                          @click="selectAllExportHeaders()"
                          class="form-control"
                          id="ExportList"
                        />
                        <strong v-if="isSelectAllExportHeader"
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.deselect_all_label }}
                          </p></strong
                        >
                        <strong v-else
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.select_all_label }}
                          </p>
                        </strong>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template v-for="(exportOpp, exportIndex) in exportHeaders">
                      <div
                        :key="exportIndex"
                        class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v"
                      >
                        <label class="container">
                          <!-- <input :value="exportIndex" class="form-control" type="checkbox" @click="checkedExportHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                          <input
                            :value="exportOpp"
                            class="form-control"
                            type="checkbox"
                            v-model="exportSelectedHeaders"
                            @change="updateCheckedExportHeaders()"
                          />
                          <p class="modal-check-lbl">{{ exportOpp["name"] }}</p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button
                    type="button"
                    v-on:click="exportSelectedHeadersData($event)"
                    class="btn general-button btn-success waves-effect"
                  >
                    Export
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- Opportunity Header List Modal -->
        <div
          class="modal fade"
          id="oppertunityHeaderListModal"
          tabindex="-1"
          role="dialog"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="oppertunityHeaderListModalLabel">
                  Select Opportunity Headers
                </h4>
              </div>
              <form
                class="form-inline"
                id="oppertunityHeaderListModalForm"
                enctype="multipart/form-data"
              >
                <div
                  class="col-md-12 modal-body"
                  id="modal-checkbox-availabel-age"
                >
                  <template
                    v-if="
                      opportunityHeadersList &&
                      opportunityHeadersList.length > 0
                    "
                  >
                    <!-- Select All -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          type="checkbox"
                          v-model="isSelectAllOppertunityHeaderList"
                          @click="selectAllOppertunityHeaders()"
                          class="form-control"
                          id="OppertunityHeadersList"
                        />
                        <strong v-if="isSelectAllOppertunityHeaderList"
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.deselect_all_label }}
                          </p></strong
                        >
                        <strong v-else
                          ><p class="modal-check-lbl">
                            {{ staticInputLabelMessages.select_all_label }}
                          </p>
                        </strong>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template
                      v-for="(
                        opportunityOpp, userIndex
                      ) in opportunityHeadersList"
                    >
                      <div
                        :key="userIndex"
                        class="col-md-6 col-sm-12 paddingleft0 custom-checkbox-v"
                      >
                        <label class="container">
                          <!-- <input :value="userIndex" class="form-control" type="checkbox" @click="checkedExportAvailableAgeHeaders($event, exportIndex, exportOpp)" /> {{exportOpp['name']}} -->
                          <input
                            :value="opportunityOpp"
                            class="form-control"
                            type="checkbox"
                            v-model="selectedOpportunityHeadersList"
                            autocomplete="off"
                            @change="updateCheckedUserHeaders()"
                          />
                          <p class="modal-check-lbl">
                            {{ opportunityOpp["name"] }}
                          </p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button
                    type="button"
                    v-on:click="viewSelectedOpportunityHeadersData($event)"
                    class="btn general-button btn-success waves-effect"
                  >
                    Show List Data
                  </button>
                  <button
                    type="button"
                    class="btn general-button btn-danger waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
    <move-opportunity-deal
      :isClickMoveDeal="isClickMoveDeal"
      :selectedRows="selectedRows"
      :moveIndividual="moveIndividual"
      :oppId="refOpportunity.id"
    ></move-opportunity-deal>
    <move-opportunity
      :isClickMoveOpportunity="isClickMoveOpportunity"
      :selectedOpportunityData="selectedOpportunityData"
      :selectedRows="selectedRows"
      :moveIndividual="moveIndividual"
    ></move-opportunity>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import MoveOpportunityDeal from "./MoveOpportunityDeal/MoveOpportunityDealModal.vue";
import MoveOpportunity from "./MoveOpportunityDeal/MoveOpportunityModal.vue";
import { VueContext } from "vue-context";
import "vue-context/dist/css/vue-context.css";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
Vue.component("v-select", vSelect);

export default {
  name: "ListOpportunity",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "move-opportunity-deal": MoveOpportunityDeal,
    "move-opportunity": MoveOpportunity,
    "common-loader": CommonLoader,
    VueContext,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      opportunityArr: [],
      headers: [],
      filterheaders: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_opportunity_master"],
      actionDisplay: true,
      exportOpportunity: false,
      jumpToHistoryPage: 1,
      filterCount: 0,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      staticMessages: "",
      staticInputLabelMessages: "",
      opportunityHeadersList: [],
      selectedOpportunityHeadersList: [],
      isSelectAllOppertunityHeaderList: false,
      opportunityDataArr: [],
      isClickMoveDeal: false,
      closeOnClick: true,
      closeOnScroll: true,
      itemSelector: [".custom-item-class"],
      refOpportunity: {},
      moveIndividual: false,
      selectedOpportunityData: [],
      isClickMoveOpportunity: false,
    };
  },
  beforeMount() {
    self = this;
  },
  watch: {
    /*selectAll: function (selectAll) {
        self.clickHeaderCheckBox();
    },*/
    /*perPage: function () {
        self.currentPage = 1;
        if (self.perPage !== "All") {
            self.perPageValue = self.perPage;
        }
        self.loadOpportunity();
    },
    currentPage: function () {
        self.loadOpportunity();
    }*/
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;

    self.setActiveMenu("opportunity-list");
    EventBus.$emit("menu-selection-changed", "opportunity-list");
    var listHistoryData = self.loadFilters("listOpportunity", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;

    if(process.env.VUE_APP_PRODUCTION_COUNTRY == 'AU'){
      const openDate = this.$route.query.openDate;
      if(openDate){
        if (!self.filters['open_date']) {
          self.filters['open_date'] = {};
        }
        self.filters['open_date']['start']=openDate;
        self.filters['open_date']['end']=null;
        self.filters['open_date']['shortcut']=null;
      }
    }else{
      const decisionDate = this.$route.query.decisionDate;
      if(decisionDate){
        if (!self.filters['decision_date']) {
          self.filters['decision_date'] = {};
        }
        self.filters['decision_date']['start']=decisionDate;
        self.filters['decision_date']['end']=null;
        self.filters['decision_date']['shortcut']=null;
      }
    }
    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listExecutionState = false;
    self.loadOpportunity(1);
  },
  methods: {
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.loadOpportunity();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.loadOpportunity();
      }
    },
    selectAllExportHeaders: function () {
      self.exportSelectedHeaders = [];
      if (!self.isSelectAllExportHeader) {
        for (let key in self.exportHeaders) {
          self.exportSelectedHeaders.push(self.exportHeaders[key]);
        }
      }
      /* console.log(JSON.stringify(self.exportSelectedHeaders)); */
    },
    updateCheckedExportHeaders: function () {
      /* console.log("Selected export header : " + self.exportSelectedHeaders.length);
      console.log("Total export header : " + self.exportHeaders.length); */
      if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
        self.isSelectAllExportHeader = true;
      } else {
        self.isSelectAllExportHeader = false;
      }
    },
    /* checkedExportHeaders: function (e, index, value) {
        if (e.target.checked) {
            // Vue.set(self.exportSelectedHeaders, index, value);
            self.exportSelectedHeaders.push(Vue.util.extend({}, value));
        } else {
            // Vue.delete(self.exportSelectedHeaders, index);
            for (var i = 0; i < self.exportSelectedHeaders.length; i++) {
                if (self.exportSelectedHeaders[i]['name'] == value['name']) {
                    self.exportSelectedHeaders.splice(i, 1);
                }
            }
        }
        // console.log(JSON.stringify(self.exportSelectedHeaders));
    }, */
    exportOpportunityFuntion: function () {
      $("#exportModal").modal("show");
      /* self.exportOpportunity = true;
      if (self.exportOpportunity == true) {
          $('#exportOpportunityId').prop("disabled", true);
          self.loadOpportunity();
      } */
    },
    exportSelectedHeadersData: function () {
      // if(Object.keys(self.exportSelectedHeaders).length == 0) {
      if (self.exportSelectedHeaders.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }
      /* $('#modal-checkbox input:checkbox').prop('checked', false); */
      self.exportOpportunity = true;
      if (self.exportOpportunity == true) {
        $("#exportOpportunityId").prop("disabled", true);
        self.loadOpportunity();
        /* self.isSelectAllExportHeader = false; */
      }
      if (
        self.exportSelectedHeaders != null &&
        self.exportSelectedHeaders != "undefined" &&
        self.exportSelectedHeaders.length > 0
      ) {
        self.storeExportHeaders(
          "exportOpportunity",
          self.exportSelectedHeaders
        );
      } else {
        self.exportSelectedHeaders = [];
      }
    },
    selectAllOppertunityHeaders: function () {
      self.selectedOpportunityHeadersList = [];
      if (!self.isSelectAllOppertunityHeaderList) {
        for (let key in self.opportunityHeadersList) {
          self.selectedOpportunityHeadersList.push(
            self.opportunityHeadersList[key]
          );
        }
      }
      /* console.log(JSON.stringify(self.selectedOpportunityHeadersList)); */
    },
    updateCheckedUserHeaders: function () {
      /* console.log("Selected export header : " + self.selectedOpportunityHeadersList.length);
      console.log("Total export header : " + self.opportunityHeadersList.length); */
      if (
        self.selectedOpportunityHeadersList.length ==
        self.opportunityHeadersList.length
      ) {
        self.isSelectAllOppertunityHeaderList = true;
      } else {
        self.isSelectAllOppertunityHeaderList = false;
      }
    },
    opportunityHeaderList: function () {
      $("#oppertunityHeaderListModal").modal("show");
    },
    viewSelectedOpportunityHeadersData: function () {
      // if(Object.keys(self.selectedOpportunityHeadersList).length == 0) {
      if (self.selectedOpportunityHeadersList.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }
      /* $('#modal-checkbox-availabel-age input:checkbox').prop('checked', false); */
      if (
        self.selectedOpportunityHeadersList != null &&
        self.selectedOpportunityHeadersList != "undefined" &&
        self.selectedOpportunityHeadersList.length > 0
      ) {
        self.storeListHeaders(
          "listOpportunity",
          self.selectedOpportunityHeadersList
        );
      } else {
        self.selectedOpportunityHeadersList = [];
      }
      self.loadOpportunity();
      $("#oppertunityHeaderListModal").modal("hide");
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadOpportunity();
    },
    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
    },
    deleteOpportunity: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      self
        .$swal({
          title: "",
          text: "Are you sure that you want to delete this Opportunity!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token"),
              },
              data: {
                deleteArr: deleteArray,
              },
            };

            HTTP.delete("/opportunity/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.loadOpportunity();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {});
              });
          }
        });
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.opportunityArr) {
            self.selectedRows.push(self.opportunityArr[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.opportunityArr.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    addOpportunity: function () {
      self.$router.push({ name: "AddOpportunity" });
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadOpportunity();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    loadOpportunity: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadOpportunity();
      }
      self.selectedOpportunityHeadersList = self.loadListHeaders(
        "listOpportunity",
        self
      );
      self.selectedOpportunityHeadersList =
        self.selectedOpportunityHeadersList === null ||
        typeof self.selectedOpportunityHeadersList === "undefined" ||
        self.selectedOpportunityHeadersList == ""
          ? []
          : self.selectedOpportunityHeadersList;
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportOpportunity,
        export_selected_headers: self.exportSelectedHeaders,
        selected_opportunity_header: self.selectedOpportunityHeadersList,
      };
      this.storeFilters("listOpportunity", fields, false);
      if (self.exportOpportunity) {
        $("#commonLoaderExportOpportunity")
          .find(".loading-container.lodingTable")
          .show(); 
      } else {
        $("#commonLoaderListOpportunity")
          .find(".loading-container.lodingTable")
          .show();
      }
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/opportunity/alllist", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          if (self.exportOpportunity) {
            $("#commonLoaderExportOpportunity")
              .find(".loading-container.lodingTable")
              .hide(); 
          } else {
            $("#commonLoaderListOpportunity")
              .find(".loading-container.lodingTable")
              .hide();
          }
          /* self.exportSelectedHeaders = []; */
          self.exportSelectedHeaders = self.loadExportHeaders(
            "exportOpportunity",
            self
          );
          self.exportSelectedHeaders =
            self.exportSelectedHeaders === null ||
            typeof self.exportSelectedHeaders === "undefined" ||
            self.exportSelectedHeaders == ""
              ? []
              : self.exportSelectedHeaders;
          $("#exportOpportunityId").prop("disabled", false);
          $("#exportModal").modal("hide");
          self.exportOpportunity = false;
          if (data.status == 200) {
            if (data.data.message == "success export") {
              self.downloadFile(data.data.content.web);
              // location.href = data.data.content.web;
            } else {
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              if (
                self.perPage == "All" &&
                self.totalRecords > process.env.MAX_SINGLEPAGE_DATA
              ) {
                // alert(self.totalRecords);
                let seconds = 5000;
                self.$swal({
                  type: "error",
                  html: self.allStaticMessages
                    .too_many_data_to_display_browser_can_not_load,
                  showConfirmButton: false,
                  timer: seconds,
                });
                setTimeout(() => {
                  self.perPage = 500;
                  self.loadOpportunity();
                }, seconds);
                /* self.$swal({
                    title: '',
                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                    type: 'error',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.value) {
                        self.logout();
                    } else {
                        self.perPage = 15;
                        self.loadOpportunity();
                    }
                }); */
              }
              self.opportunityArr = data.data.content.data;
              self.opportunityDataArr = data.data.content.opportunityListData;
              self.headers = data.data.content.headers;
              self.opportunityHeadersList =
                data.data.content.opportunityHeadersList;
              if (
                self.selectedOpportunityHeadersList.length ==
                self.opportunityHeadersList.length
              ) {
                self.isSelectAllOppertunityHeaderList = true;
              }
              self.filterheaders = data.data.content.filterheaders;
              self.exportHeaders = data.data.content.exportheaders;
              if (
                self.exportSelectedHeaders.length == self.exportHeaders.length
              ) {
                self.isSelectAllExportHeader = true;
              }
              self.loadFilterInputs(data.data.content.filterheaders, self.filters);

              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#exportOpportunityId").prop("disabled", false);
          self.exportOpportunity = false;
          if (self.exportOpportunity) {
            $("#commonLoaderExportOpportunity")
              .find(".loading-container.lodingTable")
              .hide(); 
          } else {
            $("#commonLoaderListOpportunity")
              .find(".loading-container.lodingTable")
              .hide();
          }
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadOpportunity();
      }
    },
    showChangeCustomerModal: function (type = null) {
      let modalName = "#changeCustomerModal";

      switch (type) {
        case "individual":
          this.moveIndividual = true;
          this.isClickMoveDeal = true;
          break;
        case "opp-individual":
          const oppData = self.opportunityArr.find((list) => {
            return list.id == self.refOpportunity.id;
          });
          this.selectedOpportunityData.push(oppData);
          this.isClickMoveOpportunity = true;
          this.moveIndividual = true;
          modalName = "#moveOpportunityModal";
          break;
        case "opportunity":
          this.selectedOpportunityData = self.opportunityArr.filter((list) => {
            return self.selectedRows.includes(list.id);
          });
          this.isClickMoveOpportunity = true;
          modalName = "#moveOpportunityModal";
          break;
        default:
          this.isClickMoveDeal = true;
      }

      $(modalName).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    openContextMenu: function (e, opportunity) {
      self.$refs.menu.open(e);
      self.refOpportunity = {
        id: opportunity.id,
        reference_no: opportunity.reference_no,
        status: opportunity.status,
      };
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes("can_add_opportunity_master") &&
      !self.permissionNameArray.includes("can_delete_opportunity_master")
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
EventBus.$on("close-move-oppportunity-deal-modal", function (status, params) {
  if (typeof self != "undefined") {
    self.isClickMoveDeal = status;
    self.moveIndividual = false;
    if (params == "api") {
      self.refreshList();
    }
    $("#changeCustomerModal").modal("hide");
  }
});
EventBus.$on("close-move-oppportunity-modal", function (status, params) {
  if (typeof self != "undefined") {
    self.isClickMoveOpportunity = status;
    self.selectedOpportunityData = [];
    self.moveIndividual = false;
    if (params == "api") {
      self.refreshList();
    }
    $("#moveOpportunityModal").modal("hide");
  }
});
</script>
