<template>
  <!-- <div class="spaced-container"> -->
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_location_master')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
      </div>
      <!--page content-->
      <div class="admin-page-content">
        <!--Forms-->
        <div :class="alertClass" id="success-alert">
          <strong> {{ alertMessage }} </strong>
        </div>
        <div class="forms-container add_user_forms_container vtc-dealer-forms">
          <div class="row no-margin">
            <common-loader
              refId="commonLoaderAddUpdateLocationForm"
              ref="commonLoaderAddUpdateLocationForm"
            >
            </common-loader>
            <!-- DM locId -->
            <div class="col-6 forms-block" :ref="preId + 'locId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.dm_location_id }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="locidError = ''"
                  id="locid"
                  v-model="locid"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ locidError }}</span>
                <!-- </template>
                                <template v-else>
                                    <span id="locid" class="form-control">{{locid}}</span>
                                </template> -->
              </div>
            </div>
            <!-- Name -->
            <div class="col-6 forms-block" :ref="preId + 'nameId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.name_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="nameError = ''"
                  id="name"
                  v-model="name"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ nameError }}</span>
              </div>
            </div>
             <!-- Dealer License No -->
             <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.dealer_license_no_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  id="dealerlicenseno"
                  v-model="dealerlicenseno"
                  class="form-control"
                  type="text"
                  maxlength="15"
                />
              </div>
             </div>
            <!-- imanlocationname -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.iman_location_name }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="imanlocationError = ''"
                  id="imanlocation"
                  v-model="imanlocation"
                  class="form-control"
                  type="text"
                />
                <!-- <div class="requiredstar">*</div> -->
                <span class="help is-danger">{{ imanlocationError }}</span>
              </div>
            </div>
            <!-- abbrevation -->
            <div class="col-6 forms-block" :ref="preId + 'abbrevationId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.abbreviation }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="abbrevationError = ''"
                  id="abbrevation"
                  v-model="abbrevation"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ abbrevationError }}</span>
              </div>
            </div>
            <!-- Web-Site Name -->
            <div class="col-6 forms-block" :ref="preId + 'websiteNameId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.website_name_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="websiteNameError = ''"
                  id="website_name"
                  v-model="websiteName"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ websiteNameError }}</span>
              </div>
            </div>
            <!-- Address -->
            <div class="col-6 forms-block" :ref="preId + 'addressId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.address_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="addressError = ''"
                  id="address"
                  v-model="address"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ addressError }}</span>
              </div>
            </div>
            <!-- Country -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.country_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <v-select
                  v-model="countryId"
                  @change="changeConutry"
                  @input="countryError = ''"
                  name="countryId"
                  id="countryId"
                  label="text"
                  :clearable="false"
                  :searchable="false"
                  :filterable="false"
                  :options="countryArr"
                  :placeholder="staticMessages.country_place_holder"
                >
                  <span slot="no-options" @click="$refs.select.open = false">
                    <template v-if="!isCountryRecordNotFound">
                      {{ staticMessages.country_search }}
                    </template>
                    <template v-else>
                      {{ staticMessages.search_record_not_found }}
                    </template>
                  </span>
                </v-select>
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ countryError }}</span>
              </div>
            </div>
            <!-- State -->
            <div class="col-6 forms-block" :ref="preId + 'stateId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.state_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <v-select
                  v-model="state"
                  name="state"
                  label="text"
                  :filterable="false"
                  :options="stateArr"
                  @search="onSearchState"
                  :clearable="false"
                  @input="stateError = ''"
                  :placeholder="staticMessages.state_place_holder"
                  class="custom-ci-auto-search"
                >
                  <span slot="no-options" @click="$refs.select.open = false">
                    <template v-if="!isStateRecordNotFound">
                      {{ staticMessages.state_search }}
                    </template>
                    <template v-else>
                      {{ staticMessages.search_record_not_found }}
                    </template>
                  </span>
                </v-select>
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ stateError }}</span>
              </div>
            </div>
            <!-- City -->
            <div class="col-6 forms-block" :ref="preId + 'cityId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.city_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <v-select
                  v-model="city"
                  name="city"
                  label="text"
                  :filterable="false"
                  :options="cityArr"
                  @search="onSearchCity"
                  :clearable="false"
                  @input="cityError = ''"
                  :placeholder="staticMessages.city_place_holder"
                  class="custom-ci-auto-search"
                >
                  <span slot="no-options" @click="$refs.select.open = false">
                    <template v-if="!isCityRecordNotFound">
                      {{ staticMessages.city_search }}
                    </template>
                    <template v-else>
                      {{ staticMessages.search_record_not_found }}
                    </template>
                  </span>
                </v-select>
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ cityError }}</span>
              </div>
            </div>
            <!-- zip code -->
            <div class="col-6 forms-block" :ref="preId + 'zipCodeId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.zip_code_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="zipCodeError = ''"
                  id="zipCode"
                  v-model="zipCode"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ zipCodeError }}</span>
              </div>
            </div>
            <!-- phone -->
            <div class="col-6 forms-block" :ref="preId + 'phoneId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.phone_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="phoneError = ''"
                  id="phone"
                  v-model="phone"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ phoneError }}</span>
              </div>
            </div>
            <!-- sms -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.sms_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="smsError = ''"
                  id="sms"
                  v-model="sms"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ smsError }}</span>
              </div>
            </div>
            <!-- fax -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.fax_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="faxError = ''"
                  id="fax"
                  v-model="fax"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ faxError }}</span>
              </div>
            </div>
            <!-- email -->
            <div class="col-6 forms-block" :ref="preId + 'emailId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.email_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="emailError = ''"
                  id="email"
                  v-model="email"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{ emailError }}</span>
              </div>
            </div>
            <!-- Status -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.status_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <v-select
                  title="Select Status"
                  v-model="statusId"
                  @change="changedValue"
                  name="status"
                  id="status"
                  label="text"
                  :clearable="false"
                  :searchable="false"
                  :filterable="false"
                  :options="statusArr"
                  placeholder="Select Status"
                >
                </v-select>
                <!--<div class="requiredstar">*</div>-->
              </div>
            </div>
            <!-- Location Note -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.location_note_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <textarea
                  @input="locationNoteError = ''"
                  id="locationNote"
                  v-model="locationNote"
                  class="form-control"
                  type="text"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ locationNoteError }}</span>
              </div>
            </div>
            <!-- Contact Name -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.contact_name_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="cotactNameError = ''"
                  id="cotactName"
                  v-model="cotactName"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ cotactNameError }}</span>
              </div>
            </div>
            <!-- Sales Tax -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.sales_tax_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="salesTaxError = ''"
                  id="salesTax"
                  v-model="salesTax"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ salesTaxError }}</span>
              </div>
            </div>
            <!-- labor Tax -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.labor_tax_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="laborTaxError = ''"
                  id="laborTax"
                  v-model="laborTax"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ laborTaxError }}</span>
              </div>
            </div>
            <!-- parts email -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.parts_email_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="partsEmailError = ''"
                  id="partsEmail"
                  v-model="partsEmail"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ partsEmailError }}</span>
              </div>
            </div>
            <!-- shop email -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.shop_email_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="shopEmailError = ''"
                  id="shopEmail"
                  v-model="shopEmail"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ shopEmailError }}</span>
              </div>
            </div>
            <!-- Part Status -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.part_status_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <v-select
                  title="Select Part Status"
                  v-model="partStatusId"
                  @change="changedValue"
                  name="partStatusId"
                  id="partStatusId"
                  label="text"
                  :clearable="false"
                  :searchable="false"
                  :filterable="false"
                  :options="statusArr"
                  placeholder="Select Part Status"
                >
                </v-select>
                <!--<div class="requiredstar">*</div>-->
              </div>
            </div>
            <!-- Shop Status -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.shop_status_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <v-select
                  title="Select Shop Status"
                  v-model="shopStatusId"
                  @change="changedValue"
                  name="shopStatusId"
                  id="shopStatusId"
                  label="text"
                  :clearable="false"
                  :searchable="false"
                  :filterable="false"
                  :options="statusArr"
                  placeholder="Select Shop Status"
                >
                </v-select>
                <!--<div class="requiredstar">*</div>-->
              </div>
            </div>
            <!-- Region -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.region_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <v-select
                  title="Select Region"
                  v-model="regionId"
                  @change="changedValue"
                  name="regionId"
                  id="regionId"
                  label="text"
                  :clearable="false"
                  :searchable="false"
                  :filterable="false"
                  :options="regionArr"
                  placeholder="Select Region"
                >
                </v-select>
                <!--<div class="requiredstar">*</div>-->
              </div>
            </div>
            <!-- Latitude -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.latitude }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="latitudeError = ''"
                  id="latitude"
                  v-model="latitude"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ latitudeError }}</span>
              </div>
            </div>
            <!-- Longitude -->
            <div class="col-6 forms-block">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.longitude }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <input
                  @input="longitudeError = ''"
                  id="longitude"
                  v-model="longitude"
                  class="form-control"
                  type="text"
                  maxlength="190"
                />
                <!--<div class="requiredstar">*</div>-->
                <span class="help is-danger">{{ longitudeError }}</span>
              </div>
            </div>
            <!-- Doc Fee -->
            <!-- <div class="col-6 forms-block">
                            <div class="col-3 paddingleft0">
                                <div class="form-label">{{staticInputLabelMessages.doc_fee_label}}</div>
                            </div>
                            <div class="col-9 paddingleft0">
                                <input @input="docFeeError = ''" id="docFee" v-model="docFee" class="form-control" type="text"/>
                                <div class="requiredstar">*</div>
                                <span class="help is-danger">{{docFeeError}}</span>
                            </div>
                        </div>
                        <div class="col-6 forms-block">
                        </div> -->
            <template
              v-for="(reperterLoc, index) in reperterLocationExpenseTypeArr"
            >
              <div :key="'expense_type ' + index" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.expense_type_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="reperterLoc.expenseTypeId"
                    @input="
                      changedValueReperter(reperterLoc.expenseTypeId, index)
                    "
                    name="expenseTypeId"
                    id="expenseTypeId"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="tempLocationExpenseTypeArr"
                    placeholder="Select Expense Type"
                  >
                  </v-select>
                  <div class="help is-danger">
                    {{ reperterLoc.expenseTypeIdError }}
                  </div>
                </div>
              </div>
              <div :key="'typeFees ' + index" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.type_fee_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <div class="row">
                    <div class="col-md-9">
                      <input
                        type="text"
                        @input="reperterLoc.typeFeesError = ''"
                        maxlength="190"
                        class="form-control"
                        v-model="reperterLoc.typeFees"
                        id="typeFees"
                        name="typeFees"
                      />
                      <div class="help is-danger">
                        {{ reperterLoc.typeFeesError }}
                      </div>
                    </div>
                    <div class="col-md-3">
                      <template
                        v-if="
                          reperterLocationExpenseTypeArr.length == index + 1 &&
                          plusCountReperter != 0
                        "
                      >
                        <div :key="'plus ' + index" class="col-1 forms-block">
                          <button
                            type="button"
                            v-on:click="addNewReperter(reperterLoc, index)"
                            class="btn btn-success"
                          >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </template>
                      <template v-else>
                        <div :key="'close ' + index" class="col-1 forms-block">
                          <button
                            type="button"
                            v-on:click="removeReperter(reperterLoc, index)"
                            class="btn btn-danger"
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </button>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div class="col-6 forms-block">
              <!-- isQuotationLocation -->
              <div>
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.quotation_location_label }}
                  </div>
                </div>
                <div class="col-2 paddingleft0 custom-checkbox-v">
                  <label class="container">
                    <input
                      id="isQuotationLocation"
                      v-model="isQuotationLocation"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <!-- Transaction Sheet Branch Flag -->
              <div class="row">
                <div class="col-8">
                  <div class="form-label">Is Booking Deal:</div>
                </div>
                <div class="col-1 paddingleft0 custom-checkbox-v">
                  <label class="container">
                    <input
                      id="isBookingDeal"
                      v-model="isBookingDeal"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <template v-if="showQuotationFeeColumn">
              <!-- PREP Fee -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.prep_fee_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="prepFeeError = ''"
                    id="prepFee"
                    v-model="prepFee"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ prepFeeError }}</span>
                </div>
              </div>
              <!-- Tax Rate URL -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.tax_rate_url_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="taxRateURLError = ''"
                    id="taxRateURL"
                    v-model="taxRateURL"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ taxRateURLError }}</span>
                </div>
              </div>
            </template>
            <!-- Upload Logo -->
            <div class="col-6 forms-block" :ref="preId + 'fileId'">
              <div class="col-3 paddingleft0">
                <div class="form-label">
                  {{ staticInputLabelMessages.upload_logo_label }}
                </div>
              </div>
              <div class="col-9 paddingleft0">
                <vue-dropzone
                  id="drop1"
                  :options="dropzoneOptions"
                  ref="dropzone"
                  :destroyDropzone="false"
                  @vdropzone-removed-file="removedSelectedFile"
                  @vdropzone-success="uploadDocument"
                  @vdropzone-error="fileError"
                  @vdropzone-file-added="fileAdd"
                  @vdropzone-total-upload-progress="progressOfUloadBanner"
                  name="file"
                  :useCustomSlot="true"
                >
                  <div class="dropzone-custom-content">
                    <h4 class="dropzone-custom-title">
                      {{ staticMessages.drop_file_here_drop_zone_msg }}
                    </h4>
                    <div class="subtitle">
                      ({{ staticMessages.select_files_from_computer }})
                    </div>
                  </div>
                </vue-dropzone>
                <div v-if="showQuotationFeeColumn" class="requiredstar">*</div>
                <span class="help is-danger">{{ fileUploadError }}</span>
              </div>
            </div>
          </div>

          <!--Error Alert-->
          <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="alert alert-danger" id="alertError">
                <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                <strong id="errorMsg" class="error"></strong>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <!--Buttons-->
          <div class="row clearfix"></div>
          <div class="button-demo">
            <input
              id="addLocationBtn"
              :disabled="disableButtons.save"
              type="submit"
              :value="buttonNames.save"
              class="btn btn-primary"
              v-on:click.prevent="addLocation()"
            />
            <input
              id="addAndContinueLocationBtn"
              :disabled="disableButtons.saveAndContinue"
              type="submit"
              :value="buttonNames.saveAndContinue"
              class="btn btn-primary"
              v-on:click.prevent="addLocation((flag = 'Save and Continue'))"
            />
            <input
              id="cancelLocation"
              :disabled="disableButtons.cancel"
              type="submit"
              :value="buttonNames.cancel"
              class="btn btn-primary"
              v-on:click.prevent="buttonBack"
            />
          </div>
        </div>
        <!-- Forms -->
      </div>
      <!--page content-->
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!-- </div> -->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import CommonLoader from "@/components/partials/CommonLoader.vue";

Vue.component("app-footer", Footer);
Vue.component("no-access", NoAccessPage);

export default {
  name: "AddUpdateLocation",
  mixins: [commonMixins],
  components: {
    vueDropzone: vue2Dropzone,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      preId: "add_update_location_",
      isEdit: false,
      id: this.$route.params.id,
      staticInputLabelMessages: "",
      staticMessages: "",
      name: "",
      dealerlicenseno: "",
      locid: "",
      imanlocation: "",
      abbrevation: "",
      websiteName: "",
      address: "",
      city: "",
      cityArr: [],
      isCityRecordNotFound: false,
      state: "",
      stateArr: [],
      isStateRecordNotFound: false,
      zipCode: "",
      phone: "",
      sms: "",
      fax: "",
      email: "",
      statusId: { text: "Active", value: "active" },
      statusArr: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Inactive",
          value: "inactive",
        },
      ],
      regionId: { text: "Western", value: "western" },
      regionArr: [
        {
          text: "Mid East",
          value: "mideast",
        },
        {
          text: "Western",
          value: "western",
        },
      ],
      regionError: "",
      locationNote: "",
      cotactName: "",
      salesTax: "",
      laborTax: "",
      partsEmail: "",
      shopEmail: "",
      partStatusId: { text: "Active", value: "active" },
      shopStatusId: { text: "Active", value: "active" },

      nameError: "",
      locidError: "",
      imanlocationError: "",
      abbrevationError: "",
      websiteNameError: "",
      addressError: "",
      stateError: "",
      cityError: "",
      zipCodeError: "",
      phoneError: "",
      smsError: "",
      faxError: "",
      emailError: "",
      locationNoteError: "",
      cotactNameError: "",
      salesTaxError: "",
      laborTaxError: "",
      partsEmailError: "",
      shopEmailError: "",

      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel",
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false,
      },
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Location",
      countryId: "",
      countryArr: [],
      countryError: "",
      isCountryRecordNotFound: false,
      isQuotationLocation: 0,
      showQuotationFeeColumn: false,
      docFee: "",
      docFeeError: "",
      prepFee: "",
      prepFeeError: "",
      taxRateURL: "",
      taxRateURLError: "",
      //DropZone
      dropzoneOptions: {
        timeout: 3000000,
        url: process.env.VUE_APP_ROOT_API + "/uploaddocument",
        method: "POST",
        paramName: "file",
        autoProcessQueue: true,
        maxFilesize: 10, //MB
        maxFiles: 1, //ToDo : to decide number of files allowed
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        duplicateCheck: true,
        acceptedFiles: ".jpg, .jpeg, .png",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
          // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
          // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
          //'Content-Type': 'application/x-www-form-urlencoded'
        },
        dictFileTooBig:
          "File is too big ({{filesize}}mb). Max allowed file size is {{maxFilesize}}mb",
        dictInvalidFileType: "Invalid File Type",
        dictCancelUpload: "Cancel",
        dictRemoveFile: "Remove",
        // dictMaxFilesExceeded: "Only {{maxFiles}} file is allowed",
        // dictMaxFilesExceeded: "Only 1 file is allowed",
        dictDefaultMessage: "Drop files here to upload",
      },

      imageType: "",
      imageSize: "",
      imagePath: "",
      imageWidth: 0,
      imageHeight: 0,
      isFileUploaded: false,
      fileUploadError: "",
      locationExpenseTypeArr: [],
      tempLocationExpenseTypeArr: [],
      reperterLocationExpenseTypeArr: [
        {
          expenseTypeId: "",
          expenseTypeIdError: "",
          typeFees: "",
          typeFeesError: "",
        },
      ],
      reperterLocationExpenseTypeObject: {
        expenseTypeId: "",
        expenseTypeIdError: "",
        typeFees: "",
        typeFeesError: "",
      },
      plusCountReperter: 6,
      permissionNameArray: ["can_add_location_master"],
      latitude: null,
      latitudeError: "",
      longitude: null,
      longitudeError: "",
      isBookingDeal: 0,
      elementPosition: 0,
    };
  },
  beforeMount() {
    self = this;
  },
  watch: {
    state: function (state) {
      if (
        state === null ||
        typeof state.value === "undefined" ||
        state.value === null ||
        state.value == ""
      ) {
      } else {
        self.loadAllData(
          ["Cities"],
          true,
          self.loadCityDataCallback,
          "undefined",
          "",
          state.value
        );
      }
    },
    countryId: function (countryId) {
      if (
        countryId === null ||
        typeof countryId.value === "undefined" ||
        countryId.value === null ||
        countryId.value == ""
      ) {
      } else {
        self.loadAllData(
          ["States"],
          true,
          self.loadStateDataCallback,
          "undefined",
          "",
          self.countryId.value
        );
      }
    },
    isQuotationLocation: function (isQuotationLocation) {
      self.showQuotationFeeColumn = false;
      /* self.docFee = "";
                self.docFeeError = ""; */
      self.prepFee = "";
      self.prepFeeError = "";
      self.taxRateURL = "";
      self.taxRateURLError = "";
      self.fileUploadError = "";
      if (isQuotationLocation) {
        self.showQuotationFeeColumn = true;
      }
    },
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticInputLabelMessages = self.allLabelMessages;
    self.staticMessages = self.allStaticMessages;
    self.setActiveMenu("location-list");
    EventBus.$emit("menu-selection-changed", "location-list");

    $("#alertError").hide();
    self.isEdit = false;
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.getLocationDetailsById(self.$route.params.id);
      self.buttonNames.save = "Update";
      self.isEdit = true;
      $("#addAndContinueLocationBtn").hide();
      self.pageTitle = "Edit Location";
    } else {
      self.countryId = self.prodCountryObj();
    }
    self.loadAllData(
      ["Countries", "LocationExpenseType"],
      false,
      self.loadMasterDataCallback,
      "undefined",
      ""
    );
  },
  methods: {
    buttonBack() {
      self.$router.push("/location/list");
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    changeConutry: function (value) {},
    changedValueReperter: function (reperter, reperterIndex) {
      self.reperterLocationExpenseTypeArr[reperterIndex].expenseTypeIdError =
        "";
    },
    addNewReperter: function (object, index) {
      var error = true;
      self.reperterLocationExpenseTypeArr[index].expenseTypeIdError = "";
      self.reperterLocationExpenseTypeArr[index].typeFeesError = "";

      if (
        self.reperterLocationExpenseTypeArr[index].expenseTypeId == null ||
        !self.reperterLocationExpenseTypeArr[index].expenseTypeId.value
      ) {
        self.reperterLocationExpenseTypeArr[index].expenseTypeIdError =
          "Select Expense Type";
        if (error) {
          error = false;
        }
      }
      if (!self.reperterLocationExpenseTypeArr[index].typeFees) {
        self.reperterLocationExpenseTypeArr[index].typeFeesError =
          "Enter Type Fee";
        if (error) {
          error = false;
        }
      } else if (
        self.reperterLocationExpenseTypeArr[index].typeFees &&
        !self.numberFormateValidation(
          self.reperterLocationExpenseTypeArr[index].typeFees
        )
      ) {
        self.reperterLocationExpenseTypeArr[index].typeFeesError =
          "Only Numeric Value Allow";
        if (error) {
          error = false;
        }
      }

      if (error) {
        var previousReperterVale =
          self.reperterLocationExpenseTypeArr[index].expenseTypeId.value;
        var locationIndex = self.tempLocationExpenseTypeArr.findIndex(
          (x) => x.value === previousReperterVale
        );
        self.tempLocationExpenseTypeArr.splice(locationIndex, 1);

        self.plusCountReperter = self.plusCountReperter - 1;
        self.reperterLocationExpenseTypeArr.push(
          Vue.util.extend({}, self.reperterLocationExpenseTypeObject)
        );
      }
    },
    removeReperter: function (object, index) {
      if (self.plusCountReperter <= self.tempLocationExpenseTypeArr.length) {
        var prevoiusObject = {
          value: object.expenseTypeId.value,
          text: object.expenseTypeId.text,
        };
        self.tempLocationExpenseTypeArr.push(prevoiusObject);
        self.tempLocationExpenseTypeArr = self.tempLocationExpenseTypeArr.sort(
          (a, b) => {
            return a.text.localeCompare(b.text);
          }
        );

        self.plusCountReperter = self.plusCountReperter + 1;
        self.reperterLocationExpenseTypeArr.splice(index, 1);
      }
    },
    loadMasterDataCallback: function (callbackData) {
      self.countryArr = callbackData.Countries;
      self.locationExpenseTypeArr = callbackData.LocationExpenseType;
      if (!self.isEdit) {
        self.tempLocationExpenseTypeArr = callbackData.LocationExpenseType;
        self.plusCountReperter = self.tempLocationExpenseTypeArr.length;
      }
    },
    loadCountryCallBack: function (callbackData) {
      self.isCountryRecordNotFound = false;
      self.countryArr = callbackData.Countries;
      if (self.countryArr.length == 0) {
        self.isCountryRecordNotFound = true;
      }
    },
    onSearchState(search, loading) {
      if (self.countryId == null || !self.countryId.value) {
        self.showSAlert("Please Select Country First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadStateDataCallback,
            loading,
            search,
            self.countryId.value
          );
        }
      }
    },
    onSearchCity(search, loading) {
      if (self.state == null || !self.state.value) {
        self.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length >= 0) {
          if (
            loading === null ||
            typeof loading === "undefined" ||
            loading == ""
          ) {
          } else {
            loading(true);
          }
          self.loadAllData(
            ["Cities"],
            true,
            self.loadCityDataCallback,
            loading,
            search,
            self.state.value
          );
        }
      }
    },
    loadStateDataCallback: function (callbackData) {
      self.isStateRecordNotFound = false;
      self.stateArr = callbackData.States;
      if (self.stateArr.length == 0) {
        self.isStateRecordNotFound = true;
      }
    },
    loadCityDataCallback: function (callbackData) {
      self.isCityRecordNotFound = false;
      self.cityArr = callbackData.Cities;
      if (self.cityArr.length == 0) {
        self.isCityRecordNotFound = true;
      }
    },
    clearDataOnSaveAndContinue: function () {
      self.name = "";
      self.dealerlicenseno = "";
      self.locid = "";
      self.abbrevation = "";
      self.imanlocation = "";
      self.websiteName = "";
      self.address = "";
      self.isCountryRecordNotFound = false;
      self.state = "";
      self.city = "";
      self.zipCode = "";
      self.phone = "";
      self.sms = "";
      self.fax = "";
      self.email = "";
      self.statusId = {
        text: "Active",
        value: "active",
      };
      self.regionId = {
        text: "Western",
        value: "western",
      };
      self.locationNote = "";
      self.cotactName = "";
      self.salesTax = "";
      self.laborTax = "";
      self.partsEmail = "";
      self.shopEmail = "";
      self.partStatusId = {
        text: "Active",
        value: "active",
      };
      self.shopStatusId = {
        text: "Active",
        value: "active",
      };
      self.showQuotationFeeColumn = false;
      self.isQuotationLocation = 0;
      self.docFee = "";
      self.prepFee = "";
      self.taxRateURL = "";
      self.isFileUploaded = false;
      self.imagePath = "";
      self.imageType = "";
      self.imageSize = "";
      self.reperterLocationExpenseTypeArr = [
        {
          expenseTypeId: "",
          expenseTypeIdError: "",
          typeFees: "",
          typeFeesError: "",
        },
      ];
      self.tempLocationExpenseTypeArr = self.locationExpenseTypeArr;
      self.plusCountReperter = self.tempLocationExpenseTypeArr.length - 1;
      self.removeAllFiles();
    },
    showAlertOnSave: function (alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function () {
          $("#success-alert").slideUp(500);
        });
    },
    getLocationDetailsById: function (id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: id,
        },
      };
      HTTP.get("/location/get/form", config)
        .then((response) => {
          self.name = response.data.content.name;
          self.dealerlicenseno = response.data.content.dealer_lic_no;
          self.$emit("setPageTitle", response.data.content.name); 
          if (
            typeof response.data.content.locid != "undefined" &&
            response.data.content.locid != 0 &&
            response.data.content.locid != "" &&
            response.data.content.locid != "null"
          ) {
            self.locid = response.data.content.locid;
          }
          if (
            typeof response.data.content.iman_location_name != "undefined" &&
            response.data.content.iman_location_name != 0 &&
            response.data.content.iman_location_name != "" &&
            response.data.content.iman_location_name != "null"
          ) {
            self.imanlocation = response.data.content.iman_location_name;
          }
          self.abbrevation = response.data.content.abbrevation;
          self.websiteName = response.data.content.website_name;
          self.address = response.data.content.address;
          if (response.data.content.country_id) {
            self.countryId = {
              text: response.data.content.countryName,
              value: response.data.content.country_id,
            };
          } else {
            self.countryId = self.prodCountryObj();
          }
          self.state = {
            text: response.data.content.stateName,
            value: response.data.content.state_id,
          };
          self.city = {
            text: response.data.content.cityName,
            value: response.data.content.city_id,
          };
          self.zipCode = response.data.content.zip_code;
          self.phone = response.data.content.phone;
          if (response.data.content.sms != "null") {
            self.sms = response.data.content.sms;
          }
          if (response.data.content.fax != "null") {
            self.fax = response.data.content.fax;
          }
          self.email = response.data.content.email;
          self.statusId = {
            text: self.formatString(response.data.content.status),
            value: response.data.content.status,
          };
          if (response.data.content.location_note != "null") {
            self.locationNote = response.data.content.location_note;
          }
          if (response.data.content.contact_name != "null") {
            self.cotactName = response.data.content.contact_name;
          }
          if (response.data.content.sales_tax != "null") {
            self.salesTax = response.data.content.sales_tax;
          }
          if (response.data.content.labor_tax != "null") {
            self.laborTax = response.data.content.labor_tax;
          }
          if (response.data.content.parts_email != "null") {
            self.partsEmail = response.data.content.parts_email;
          }
          if (response.data.content.shop_email != "null") {
            self.shopEmail = response.data.content.shop_email;
          }
          self.partStatusId = {
            text: self.formatString(response.data.content.is_part_active),
            value: response.data.content.is_part_active,
          };
          if (response.data.content.region == "mideast") {
            self.regionId = {
              text: "Mid East",
              value: response.data.content.region,
            };
          } else {
            self.regionId = {
              text: self.formatString(response.data.content.region),
              value: response.data.content.region,
            };
          }
          self.shopStatusId = {
            text: self.formatString(response.data.content.is_shop_active),
            value: response.data.content.is_shop_active,
          };
          if (response.data.content.docFee != null) {
            self.docFee = response.data.content.docFee.toFixed(2);
          }
          if (
            response.data.content.expense_type_fee &&
            response.data.content.expense_type_fee.length > 0
          ) {
            self.reperterLocationExpenseTypeArr = [];
            self.reperterLocationExpenseTypeArr =
              response.data.content.expense_type_fee;
          }
          self.tempLocationExpenseTypeArr = [];
          self.tempLocationExpenseTypeArr =
            response.data.content.expense_type_arr;
          self.plusCountReperter = self.tempLocationExpenseTypeArr.length - 1;
          self.imagePath = response.data.content.image_path;
          if (self.imagePath != "") {
            self.isFileUploaded = true;
            self.imageSize = response.data.content.image_size;
            self.uploadManually();
          }
          self.showQuotationFeeColumn = false;
          if (response.data.content.allowInQuote == "yes") {
            self.isQuotationLocation = 1;
            self.showQuotationFeeColumn = true;
            setTimeout(function () {
              // self.docFee = response.data.content.docFee.toFixed(2);
              self.prepFee = response.data.content.prepFee.toFixed(2);
              self.taxRateURL = response.data.content.taxRateURL;
            }, 50);
          }
          self.isBookingDeal = response.data.content.is_booking_deal;
          self.latitude =
            response.data.content.latitude !== null
              ? response.data.content.latitude
              : null;
          self.longitude =
            response.data.content.longitude !== null
              ? response.data.content.longitude
              : null;
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
        });
    },
    scrollElmPosition(elm) {
      let position = 0;
      if (elm) {
        position =
          elm.getBoundingClientRect().top + window.scrollY - elm.offsetHeight;
      }
      return position;
    },
    checkForm: function () {
      var checked = true;
      let fieldPosition = 0;
      if (!self.locid) {
        self.locidError = "Please enter Location ID";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "locId"]
          );
          checked = false;
        }
      }
      if (!self.name) {
        self.nameError = "Please enter name";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "nameId"]
          );
          checked = false;
        }
      }
      if (!self.abbrevation) {
        self.abbrevationError = "Please enter abbrevation";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "abbrevationId"]
          );
          checked = false;
        }
      }
      /* if (!self.imanlocation) {
                    self.imanlocationError = "Please enter ImanPro Location Identification string.";
                    if (checked) {
                        checked = false;
                    }
                } */
      if (!self.websiteName) {
        self.websiteNameError = "Please enter website name";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "websiteNameId"]
          );
          checked = false;
        }
      } else if (!self.websiteUrlValidation(self.websiteName)) {
        self.websiteNameError = "Please enter valid website";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "websiteNameId"]
          );
          checked = false;
        }
      }
      if (!self.address) {
        self.addressError = "Please enter address";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "addressId"]
          );
          checked = false;
        }
      }
      if (self.countryId == null || !self.countryId.value) {
        self.countryError = "Please select country";
        if (checked) {
          checked = false;
        }
      }
      if (self.state == null || !self.state.value) {
        self.stateError = "Please select state";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "stateId"]
          );
          checked = false;
        }
      }
      if (self.city == null || !self.city.value) {
        self.cityError = "Please select city";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "cityId"]
          );
          checked = false;
        }
      }
      if (!self.zipCode) {
        self.zipCodeError = "Please enter zip code";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "zipCodeId"]
          );
          checked = false;
        }
      }
      if (!self.phone) {
        self.phoneError = "Please enter phone";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "phoneId"]
          );
          checked = false;
        }
      }
      if (self.phone && !self.validContactNumber(self.phone)) {
        self.phoneError = "Please enter valid phone";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "phoneId"]
          );
          checked = false;
        }
      }
      /*if (!self.sms) {
                 self.smsError = "Please enter sms";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      if (self.sms && !self.validContactNumber(self.sms)) {
        self.smsError = "Please enter valid sms";
        if (checked) {
          checked = false;
        }
      }
      /*if (!self.fax) {
                 self.faxError = "Please enter fax";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      if (self.fax && !self.validContactNumber(self.fax)) {
        self.faxError = "Please enter valid sms";
        if (checked) {
          checked = false;
        }
      }
      if (!self.email) {
        self.emailError = "Please enter email";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "emailId"]
          );
          checked = false;
        }
      }
      if (self.email && !self.validEmail(self.email)) {
        self.emailError = "Please enter valid Email";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "emailId"]
          );
          checked = false;
        }
      }
      /*if (!self.locationNote) {
                 self.locationNoteError = "Please enter location note";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      /*if (!self.cotactName) {
                 self.cotactNameError = "Please enter contact name";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      /*if (!self.salesTax) {
                 self.salesTaxError = "Please enter sales tax";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      /*if (!self.laborTax) {
                 self.laborTaxError = "Please enter labor tax";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      /*if (!self.partsEmail) {
                 self.partsEmailError = "Please enter parts email";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      if (self.partsEmail && !self.validEmail(self.partsEmail)) {
        self.partsEmailError = "Please enter valid parts Email";
        if (checked) {
          checked = false;
        }
      }
      /*if (!self.shopEmail) {
                 self.shopEmailError = "Please enter shop email";
                 if (checked) {
                 checked = false;
                 }
                 }*/
      if (self.shopEmail && !self.validEmail(self.shopEmail)) {
        self.shopEmailError = "Please enter valid shop Email";
        if (checked) {
          checked = false;
        }
      }
      /* if(self.docFee && !self.numberFormateValidation(self.docFee)) {
                    self.docFeeError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      if (self.showQuotationFeeColumn) {
        /* if(!self.docFee) {
                        self.docFeeError = "Please enter Doc Fee";
                        if (checked) {
                                checked = false;
                        }
                    } else if(self.docFee && !self.numberFormateValidation(self.docFee)) {
                        self.docFeeError = "Only Numeric Value Allow";
                        if (checked) {
                            checked = false;
                        }
                    } */
        if (!self.prepFee) {
          self.prepFeeError = "Please enter PREP Fee";
          if (checked) {
            checked = false;
          }
        } else if (
          self.prepFee &&
          !self.numberFormateValidation(self.prepFee)
        ) {
          self.prepFeeError = "Only Numeric Value Allow";
          if (checked) {
            checked = false;
          }
        }
        if (!self.taxRateURL) {
          self.taxRateURLError = "Please enter Tax Rate URL";
          if (checked) {
            checked = false;
          }
        } else if (
          self.taxRateURL &&
          !self.websiteUrlValidation(self.taxRateURL)
        ) {
          self.taxRateURLError = "Please enter valid Tax Rate URL";
          if (checked) {
            checked = false;
          }
        }
      }

      //File Upload Validations
      if (self.imagePath == "" && self.showQuotationFeeColumn) {
        self.fileUploadError = "Please upload file";
        if (checked) {
          fieldPosition = self.scrollElmPosition(
            self.$refs[self.preId + "fileId"]
          );
          checked = false;
        }
      }

      if (
        self.reperterLocationExpenseTypeArr &&
        self.reperterLocationExpenseTypeArr.length > 0
      ) {
        for (
          var index = 0;
          index < self.reperterLocationExpenseTypeArr.length;
          index++
        ) {
          var object = self.reperterLocationExpenseTypeArr[index];
          object.expenseTypeIdError = "";
          object.typeFeesError = "";
          if (object.expenseTypeId != "") {
            if (!object.typeFees) {
              object.typeFeesError = "Enter Type Fee";
              if (checked) {
                checked = false;
              }
            } else if (
              object.typeFees &&
              !self.numberFormateValidation(object.typeFees)
            ) {
              object.typeFeesError = "Only Numeric Value Allow";
              if (checked) {
                checked = false;
              }
            }
          }
          if (object.typeFees) {
            if (!self.numberFormateValidation(object.typeFees)) {
              object.typeFeesError = "Only Numeric Value Allow";
              if (checked) {
                checked = false;
              }
            }
            if (object.expenseTypeId == null || !object.expenseTypeId.value) {
              object.expenseTypeIdError = "Select Expense Type";
              if (checked) {
                checked = false;
              }
            }
          }
        }
      }

      self.elementPosition = fieldPosition;
      if (!checked) {
        window.scrollTo({
          top: self.elementPosition,
          behavior: "smooth",
        });
      }

      return checked;
    },
    returnData: function () {
      var data = {};
      if (
        typeof self.$route.params.id != "undefined" &&
        self.$route.params.id != 0 &&
        self.$route.params.id != ""
      ) {
        data = {
          id: self.$route.params.id,
          locid: self.locid,
          name: self.name,
          dealer_lic_no: self.dealerlicenseno,
          imanlocation: self.imanlocation,
          abbrevation: self.abbrevation,
          website_name: self.websiteName,
          address: self.address,
          country_id: self.countryId.value,
          state_id: self.state.value,
          city_id: self.city.value,
          zip_code: self.zipCode,
          phone: self.phone,
          sms: self.sms,
          fax: self.fax,
          email: self.email,
          status: self.statusId.value,
          location_note: self.locationNote,
          contact_name: self.cotactName,
          sales_tax: self.salesTax,
          labor_tax: self.laborTax,
          parts_email: self.partsEmail,
          shop_email: self.shopEmail,
          is_part_active: self.partStatusId.value,
          is_shop_active: self.shopStatusId.value,
          region: self.regionId.value,
          is_quotation_location: self.isQuotationLocation ? 1 : 0,
          doc_fee: null, //self.docFee,
          expense_type_fee: self.reperterLocationExpenseTypeArr,
          prep_fee: self.prepFee,
          tax_rate_url: self.taxRateURL,
          image_path: self.imagePath,
          image_type: self.imageType,
          image_size: self.imageSize,
          is_booking_deal: self.isBookingDeal ? 1 : 0,
          latitude: self.latitude,
          longitude: self.longitude,
        };
      } else {
        data = {
          locid: self.locid,
          name: self.name,
          dealer_lic_no: self.dealerlicenseno,
          imanlocation: self.imanlocation,
          abbrevation: self.abbrevation,
          website_name: self.websiteName,
          address: self.address,
          country_id: self.countryId.value,
          state_id: self.state.value,
          city_id: self.city.value,
          zip_code: self.zipCode,
          phone: self.phone,
          sms: self.sms,
          fax: self.fax,
          email: self.email,
          status: self.statusId.value,
          location_note: self.locationNote,
          contact_name: self.cotactName,
          sales_tax: self.salesTax,
          labor_tax: self.laborTax,
          parts_email: self.partsEmail,
          shop_email: self.shopEmail,
          is_part_active: self.partStatusId.value,
          is_shop_active: self.shopStatusId.value,
          region: self.regionId.value,
          is_quotation_location: self.isQuotationLocation ? 1 : 0,
          doc_fee: null, //self.docFee,
          expense_type_fee: self.reperterLocationExpenseTypeArr,
          prep_fee: self.prepFee,
          tax_rate_url: self.taxRateURL,
          image_path: self.imagePath,
          image_type: self.imageType,
          image_size: self.imageSize,
          is_booking_deal: self.isBookingDeal ? 1 : 0,
          latitude: self.latitude,
          longitude: self.longitude,
        };
      }
      return data;
    },
    addLocation(flag) {
      setTimeout(() => {
        if (self.checkForm()) {
          $("#commonLoaderAddUpdateLocationForm")
            .find(".loading-container.lodingTable")
            .show();

          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
          };

          var data = self.returnData();
          if (self.isEdit) {
            // data.append('id', self.$route.params.id);
            self.buttonNames.save = "Updating ...";
            self.disableButtons.save = true;
            self.disableButtons.cancel = true;
          } else {
            if (flag == "Save and Continue") {
              self.buttonNames.saveAndContinue = "Saving ...";
            } else {
              self.buttonNames.save = "Saving ...";
            }
            self.disableButtons.save = true;
            self.disableButtons.saveAndContinue = true;
            self.disableButtons.cancel = true;
          }
          HTTP.post("/location/add/update", data, config)
            .then(function (response) {
              $("#commonLoaderAddUpdateLocationForm")
                .find(".loading-container.lodingTable")
                .hide();
              if (self.isEdit) {
                self.buttonNames.save = "Update";
              } else {
                self.buttonNames.save = "Save";
              }
              if (flag == "Save and Continue") {
                self.buttonNames.saveAndContinue = "Save and Continue";
              }

              if (response.data.status == "success") {
                self.showSAlert(response.data.message, "success", false, 3);
                if (flag == "Save and Continue") {
                  self.clearDataOnSaveAndContinue();
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                } else {
                  setTimeout(function () {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                    self.buttonBack();
                  }, 3000);
                }
              } else {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            })
            .catch(function (err) {
              $("#commonLoaderAddUpdateLocationForm")
                .find(".loading-container.lodingTable")
                .hide();
              if (self.isEdit) {
                self.buttonNames.save = "Update";
              } else {
                self.buttonNames.save = "Save";
              }
              self.disableButtons.save = false;
              self.disableButtons.saveAndContinue = false;
              self.disableButtons.cancel = false;
              if (flag == "Save and Continue") {
                self.clearDataOnSaveAndContinue();
              }

              self.catchHandler(err, function () {});
            });
        }
      }, 200);
    },
    removeAllFiles() {
      self.$refs.dropzone.removeAllFiles();
      self.isFileUploaded = false;
    },
    uploadDocument: function (file, response) {
      self.imagePath = response.content.file_path;
      // self.isFileUploaded = true;
      self.imageType = file.type;
      self.imageSize = file.size;
      self.imageWidth = file.width;
      self.imageHeight = file.height;
      self.fileUploadError = "";
      /* if (self.isFileUploaded == true && self.imageHeight % 225 != 0 || !(self.imageHeight >= 225) || !(self.imageHeight <= 450)
                        || self.imageWidth % 600 != 0 || !(self.imageWidth >= 600) || !(self.imageWidth <= 1200)) {
                    self.removeAllFiles();
                    self.fileUploadError = "Please Upload Image In This Dimensions Only.(Height : 450 pixel, Width 1200 pixel)";
                } */
    },
    removedSelectedFile(file, xhr, error) {
      var self = this;
      var removeFile = "";
      self.disableButtons.save = false;
      self.disableButtons.saveAndContinue = false;
      self.disableButtons.cancel = false;

      self.imagePath = "";
      self.imageHeight = 0;
      self.imageWidth = 0;
      if ("manuallyAdded" in file == false) {
        if (file && file.xhr != undefined) {
          var filePath = JSON.parse(file.xhr.response);
          removeFile = filePath.content;
          self.removeFileFromServer(removeFile);
          removeFile = removeFile.media_path;
        }
      }
    },
    removeFileFromServer: function (filePath) {
      var self = this;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        data: {
          filePath: filePath,
        },
      };
      HTTP.delete("removemedia", config)
        .then(function (response) {
          /*console.log("File removed successfully");*/
        })
        .catch(function (err) {
          //self.catchHandler(err, function () {});
          if (err.response.status == 0) {
            self.error = "Remote server can not be reachable";
          } else {
            //redirect to login page if user not authenticated
            if (err.response.status == 401) {
              //clear previous data
              localStorage.removeItem("user_obj");
              localStorage.removeItem("api_token");
              localStorage.removeItem("profile_image");
              self.$router.push("/login");
              //EventBus.$emit("logged-in", true, "", "");
            }
            self.error = err.response.data.message;
          }
        });
    },
    fileError: function (file, xhr, error) {
      if (xhr.code == 400) {
        /* console.log("File");
                    console.log(file);
                    console.log("XHR");
                    console.log(xhr.code);
                    console.log(xhr.status);
                    console.log(xhr.message); */
        self.showSAlert(xhr.message, "error", false);
        /* console.log(xhr.content);
                    console.log("Error");
                    console.log(error);
                    console.log(self.imagesPaths); */
      }
    },
    fileAdd: function (file, xhr, error) {
      self.disableButtons.save = true;
      self.disableButtons.saveAndContinue = true;
      self.disableButtons.cancel = true;
      /* if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    this.$refs.dropzone.setOption('maxFiles', null);
                } */
    },
    progressOfUloadBanner(totaluploadprogress, totalBytes, totalBytesSent) {
      if (totaluploadprogress == 100) {
        self.disableButtons.save = false;
        self.disableButtons.saveAndContinue = false;
        self.disableButtons.cancel = false;
      } else {
        self.disableButtons.save = true;
        self.disableButtons.saveAndContinue = true;
        self.disableButtons.cancel = true;
      }
    },
    uploadManually() {
      if (self.imagePath != "") {
        var file_name = self.getFileName(self.imagePath);
        let file_type = self.getFileType(file_name);
        let url = self.imagePath;
        let file_size = self.imageSize;
        /*console.log("File Name : " + file_name + " , File Type : " + file_type + " , URL : " + url + " , File Size : " + file_size);*/
        let file_data = {
          size: file_size,
          fileid: file_name,
          name: file_name,
          type: file_type,
        };
        self.$refs.dropzone.manuallyAddFile(file_data, url);
      }
      self.disableButtons.save = false;
      self.disableButtons.saveAndContinue = false;
      self.disableButtons.cancel = false;
    },
    getFileName: function (file) {
      var src = file.split("/");
      var file_name = src[src.length - 1];
      return file_name;
    },
    getFileType: function (file_name) {
      var type = file_name.split(".");
      var file_type = type[type.length - 1];
      return "image/" + file_type;
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
